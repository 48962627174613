import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import { Popover } from '@mui/material';
import clsx from 'clsx';
import Loading from 'components/shared/loading';
import { PropertyPath } from 'lodash';
import get from 'lodash/get';
import * as React from 'react';
import {
  FieldProps,
  Identifier,
  RaRecord,
  useGetOne,
  useRecordContext,
  useResourceContext
} from 'react-admin';
import styles from './imagePreview.module.scss';

export interface ImageFieldProps<
  RecordType extends RaRecord<Identifier>,
> extends Omit<FieldProps<RecordType>, 'source'> {
  source: PropertyPath;
  title?: string;
  // eslint-disable-next-line react/no-unused-prop-types
  disableFetchOne?: boolean;
}

function ImageDisplay<
  RecordType extends RaRecord<Identifier>,
>(
  props: ImageFieldProps<RecordType>,
) {
  const resource = useResourceContext();
  const record = useRecordContext(props);
  const id = record?.id;
  if (!resource) {
    throw new Error(
      `useShowController requires a non-empty resource prop or context`
    );
  }

  const {
    data,
    refetch
  } = useGetOne<RecordType>(
    resource,
    { id },
    {
      refetchOnMount: false,
    }
  )
  const titleValue = props.title ? get(record, props.title)?.toString() || props.title : "";
  const sourceValue = get(data, props.source);

  // refetch when the value is only boolean
  React.useEffect(() => {
    if (typeof sourceValue !== 'string') {
      refetch();
    }
  }, []);


  if (!sourceValue) {
    return <Loading />;
  }

  return (
    <img
      title={titleValue}
      alt={titleValue}
      src={sourceValue?.toString()}
    />
  );
}

export function ImagePreview<
  RecordType extends RaRecord<Identifier>,
>(
  props: ImageFieldProps<RecordType>,
) {
  const {
    className,
    source,
  } = props;
  const record = useRecordContext(props);
  const sourceValue = get(record, source);
  const [anchorEl, setAnchorEl] = React.useState<SVGSVGElement | null>(null);

  if (!sourceValue) {
    return null;
  }
  const titleValue = props.title ? get(record, props.title)?.toString() || props.title : "";
  const open = Boolean(anchorEl);

  return (
    <div className={clsx(styles.preview, open && 'active', className)}>
      <PhotoCameraIcon
        onMouseEnter={(event) => setAnchorEl(event.currentTarget)}
        onMouseLeave={() => setAnchorEl(null)}
        color={open ? 'error' : undefined}
      />
      <Popover
        className={styles.preview_box}
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        disableRestoreFocus
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {
          props.disableFetchOne ?
            <img
              title={titleValue}
              alt={titleValue}
              src={sourceValue?.toString()}
            /> :
            <ImageDisplay {...props} />
        }
      </Popover>
    </div>

  );
}
