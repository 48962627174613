import { ProductListSchema } from 'api';
import urls from 'app/urls';
import CategoryField from 'components/fields/categoryField';
import { ImagePreview } from 'components/fields/imagePreview';
import ListActions from 'components/layout/actions/list';
import List from 'components/list/List';
import { ActionButtons } from 'components/list/actionButtons';
import Datagrid from 'components/list/dataGrid';
import * as React from 'react';
import {
  BooleanField,
  TextField,
} from 'react-admin';
import { Source } from 'utils/source';
import filters from './filters';
import styles from './product.module.scss';
import PricesField from 'components/fields/pricesField';

export default function ProductList() {
  const s = Source<ProductListSchema>();

  return (
    <List filters={filters} actions={<ListActions exporter />}>
      <Datagrid className={styles.prdTable}>
        <BooleanField {...s('isActive')} label="Active" />
        <TextField {...s('articleNo')} label="resources.product/raw-materials.fields.articleNumber" />
        <TextField {...s('name')} label="Name" />
        <TextField {...s('size')} label="resources.product/raw-materials.fields.size" />
        <TextField {...s('color')} label="resources.product/raw-materials.fields.color" />
        <TextField {...s('option')} label="resources.product/products.fields.option" />
        <BooleanField {...s('isReadyToSell')} label="resources.product/raw-materials.fields.isReadyToSell" />
        <ImagePreview
          {...s('image')}
          label="resources.product/raw-materials.fields.image"
          sortable={false}
        />
        <CategoryField {...s('categories')} sortable={false} />
        <PricesField {...s('price')} currencySource={s('currencyId').source} sourceList='prices' />
        <ActionButtons url={urls.Products_Get.url} label="action" />
      </Datagrid>
    </List>
  );
};

// <TextField {...s('stockTracingCode')} label="StockTracing" />
// <TextField {...s('quantityTypeName')} label="Quatity Type" />
