import { Grid } from '@mui/material';
import { ProductRawMaterialDetailEntity, ProductResourcePriceEntity, StockLinesLine } from 'api';
import urls from 'app/urls';
import ShowBooleanField from 'components/commons/showBooleanField';
import ShowField from 'components/commons/showField';
import ShowLabel from 'components/commons/showLabel';
import ShowTitle from 'components/commons/showTitle';
import CategoryField from 'components/fields/categoryField';
import FileView from 'components/fields/file/FileView';
import PriceField from 'components/fields/priceField';
import ShowQuantityType from 'components/fields/QuantityTypeField';
import ShowActions from 'components/layout/actions/show';
import * as React from 'react';
import {
  ArrayField,
  Datagrid,
  DateField,
  ImageField,
  Show,
  SimpleShowLayout,
  Tab,
  TabbedShowLayout,
  TextField,
  useRecordContext,
} from 'react-admin';
import { Accept } from 'react-dropzone';
import OpenOrderLines from 'resources/purchasing/purchaseOrder/lines';
import formatString from 'utils/formatString';
import { Source } from 'utils/source';

function RawMaterialTitle() {
  const record = useRecordContext<ProductRawMaterialDetailEntity>();

  return (
    <span>
      Raw Material #
      {record?.articleNo}
    </span>
  );
}

function UploadFile() {
  const record = useRecordContext<ProductRawMaterialDetailEntity>();
  const accept: Accept = {
    'image/png': ['base64', 'binary'],
    'image/jpeg': ['base64', 'binary'],
    'image/gif': ['base64', 'binary'],
    'image/svg+xml': ['utf-8'],
  };
  return (
    <div>
      {
        record?.id && (
          <FileView
            endpoint={formatString(urls.ResourceFile_GetFiles.url, { id: record?.id?.toString() as string })}
            getUrl={urls.ResourceFile_GetFiles}
            uploadUrl={urls.ResourceFile_UploadFile}
            accept={accept}
            showPreview
          />
        )
      }
    </div>
  );
}

export default function RawMaterialShow() {
  const s = Source<ProductRawMaterialDetailEntity>();
  const sl = Source<StockLinesLine>();
  const p = Source<ProductResourcePriceEntity>();
  return (
    <Show<ProductRawMaterialDetailEntity>
      actions={<ShowActions />}
      title={<RawMaterialTitle />}
    >
      <SimpleShowLayout>
        <Grid container rowSpacing={1} spacing={1} sx={{ marginLeft: '3px', marginTop: '3px', marginBottom: '10px' }}>
          <Grid item md={12}>
            <ShowTitle />
          </Grid>

          <Grid className="show-row-odd" item md={2} sx={{ borderBottom: '1' }}>
            <ShowLabel label="resources.product/raw-materials.fields.businessPartnerName" />
          </Grid>
          <Grid item md={10}>
            <ShowField {...s('supplierName')} />
          </Grid>
          <Grid className="show-row-odd" item md={2} sx={{ borderBottom: '1' }}>
            <ShowLabel label="resources.product/raw-materials.fields.name" />
          </Grid>
          <Grid item md={10}>
            <ShowField {...s('name')} />
          </Grid>

          <Grid className="show-row-odd" item md={2} sx={{ borderBottom: '1' }}>
            <ShowLabel label="resources.product/raw-materials.fields.articleNo" />
          </Grid>
          <Grid item md={10}>
            <ShowField {...s('articleNo')} />
          </Grid>

        </Grid>
      </SimpleShowLayout>
      <TabbedShowLayout>
        <Tab label="resources.product/raw-materials.fields.detail">
          <Grid container rowSpacing={1} spacing={1} sx={{ marginLeft: '3px', marginTop: '3px', marginBottom: '10px' }}>
            <Grid item xs={12} md={12}>
              <ShowTitle />
            </Grid>

            <Grid item xs={4} md={3}>
              <ShowLabel label="resources.product/raw-materials.fields.isActive" />
            </Grid>
            <Grid item xs={8} md={9}>
              <ShowBooleanField {...s('isActive')} trueText="Active" falseText="Inactive" showIcon={false} />
            </Grid>

            <Grid className="show-row-odd" item xs={4} md={3} sx={{ borderBottom: '1' }}>
              <ShowLabel label="resources.product/raw-materials.fields.supplierName" />
            </Grid>
            <Grid item xs={4} md={3}>
              <ShowField {...s('supplierArticleName')} />
            </Grid>
            <Grid item xs={4} md={3}>
              <ShowField {...s('supplierArticleNo')} />
            </Grid>
            <Grid item xs={0} md={3} />

            <Grid className="show-row-odd" item xs={4} md={3} sx={{ borderBottom: '1' }}>
              <ShowLabel label="resources.product/raw-materials.fields.leadTime" />
            </Grid>
            <Grid item xs={8} md={9}>
              <ShowField {...s('leadTime')} />
            </Grid>

            <Grid className="show-row-odd" item xs={4} md={3} sx={{ borderBottom: '1' }}>
              <ShowLabel label="resources.product/raw-materials.fields.moq" />
            </Grid>
            <Grid className="show-row-odd" item xs={8} md={9}>
              <ShowQuantityType {...s('moq')} />
            </Grid>

            <Grid className="show-row-odd" item xs={4} md={3} sx={{ borderBottom: '1' }}>
              <ShowLabel label="resources.product/raw-materials.fields.stockConditioning" />
            </Grid>
            <Grid className="show-row-odd" item xs={8} md={9}>
              <ShowQuantityType {...s('stockConditioning')} />
            </Grid>

            <Grid className="show-row-odd" item xs={4} md={3} sx={{ borderBottom: '1' }}>
              <ShowLabel label="resources.product/raw-materials.fields.supplierConditioning" />
            </Grid>
            <Grid className="show-row-odd" item xs={8} md={9}>
              <ShowQuantityType {...s('supplierConditioning')} />
            </Grid>

            <Grid className="show-row-odd" item xs={4} md={3} sx={{ borderBottom: '1' }}>
              <ShowLabel label="resources.product/raw-materials.fields.price" />
            </Grid>
            <Grid item xs={8} md={9} style={{ display: 'flex', flexDirection: 'row' }}>
              <PriceField currencySource="currencyId" />
            </Grid>
            <Grid className="show-row-odd" item xs={4} md={3} sx={{ borderBottom: '1' }}>
              <ShowLabel label="resources.product/raw-materials.fields.categoryName" />
            </Grid>
            <Grid item xs={8} md={9}>
              <CategoryField {...s('categories')} />
            </Grid>
            <Grid className="show-row-odd" item xs={4} md={3} sx={{ borderBottom: '1' }}>
              <ShowLabel label="resources.product/raw-materials.fields.quantityTypeName" />
            </Grid>
            <Grid item xs={8} md={9}>
              <ShowField {...s('quantityType.name')} />
            </Grid>
            <Grid className="show-row-odd" item xs={4} md={3} sx={{ borderBottom: '1' }}>
              <ShowLabel label="resources.product/raw-materials.fields.stockTracingName" />
            </Grid>
            <Grid item xs={8} md={9}>
              <ShowField {...s('stockTracing')} showTime />
            </Grid>
            <Grid className="show-row-odd" item xs={4} md={3} sx={{ borderBottom: '1' }}>
              <ShowLabel label="resources.product/raw-materials.fields.image" />
            </Grid>
            <Grid item xs={8} md={9}>
              <ImageField {...s('image')} />
            </Grid>
          </Grid>
        </Tab>
        <Tab label="resources.stock/stocklines.fields.name">
          <ArrayField label={false} {...s('stockLines')}>
            <Datagrid bulkActionButtons={false}>
              <TextField {...sl('stockLocation.name')} label="Stock Location" />
              <TextField {...sl('amount')} />
              <TextField {...sl('stockLot.name')} />
              <TextField {...sl('stockLot.supplierName')} />
            </Datagrid>
          </ArrayField>
        </Tab>
        <Tab label="open orders">
          <OpenOrderLines />
        </Tab>
        <Tab label="Files">
          <UploadFile />
        </Tab>
        <Tab label="Prices">
          <ArrayField label={false} {...s('prices')}>
            <Datagrid bulkActionButtons={false}>
              <DateField {...p('startDate')} />
              <DateField {...p('endDate')} />
              <PriceField {...p('price')} currencySource={p('currencyId').source} />
            </Datagrid>
          </ArrayField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
}
