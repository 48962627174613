import React from 'react';
import { Create } from 'react-admin';
import ProductionOrderForm from './form/productionOrderForm';

export default function CreateProductionOrderContainer() {
  return (
    <Create redirect="show">
      <ProductionOrderForm />
    </Create>
  );
}
