import { ProductRawMaterialDetailEntity } from 'api/models/ProductRawMaterialDetailEntity';
import urls from 'app/urls';
import ShowQuantityType from 'components/fields/QuantityTypeField';
import CategoryField from 'components/fields/categoryField';
import EnumField from 'components/fields/enumField';
import { ImagePreview } from 'components/fields/imagePreview';
import ListActions from 'components/layout/actions/list';
import List from 'components/list/List';
import { ActionButtons } from 'components/list/actionButtons';
import Datagrid from 'components/list/dataGrid';
import * as React from 'react';
import {
  BooleanField,
  TextField,
} from 'react-admin';
import { StockTracingChoices } from 'repositories/products/product';
import { Source } from 'utils/source';
import filters from './filters';
import PriceField from 'components/fields/priceField';
import PricesField from 'components/fields/pricesField';

export default function RawMaterialList() {
  const s = Source<ProductRawMaterialDetailEntity>();
  return (
    <List
      filters={filters}
      actions={<ListActions exporter />}
      filterDefaultValues={{ isActive: true }}
      sort={{ field: 'articleNo', order: 'ASC' }}
    >
      <Datagrid>
        <TextField {...s('articleNo')} />
        <ImagePreview {...s('image')} label="resources.product/raw-materials.fields.image" sortable={false} />
        <TextField {...s('name')} />
        <TextField {...s('supplierName')} />
        <TextField {...s('supplierArticleName')} />
        <CategoryField {...s('categories')} sortable={false} />
        <TextField {...s('leadTime')} />
        <ShowQuantityType {...s('moq')} />
        <EnumField {...s('stockTracing')} choices={StockTracingChoices} />
        <PricesField {...s('price')} currencySource={s('currencyId').source} sourceList='prices' />
        <BooleanField {...s('isActive')} />
        <ActionButtons url={urls.RawMaterials_Get.url} label="action" />
      </Datagrid>
    </List>
  );
}
