import React from 'react';
import { Edit } from 'react-admin';
import EditActions from 'components/layout/actions/edit';
import ProductionOrderForm from './form/productionOrderForm';

export default function EditProductionOrderContainer() {
  return (
    <Edit redirect="show" actions={<EditActions />}>
      <ProductionOrderForm />
    </Edit>
  );
}
