/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: smn@advance.ch
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StockResource
 */
export interface StockResource {
    /**
     * 
     * @type {number}
     * @memberof StockResource
     */
    id: number;
    /**
     * 
     * @type {boolean}
     * @memberof StockResource
     */
    isActive: boolean;
    /**
     * 
     * @type {string}
     * @memberof StockResource
     */
    articleNo?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StockResource
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof StockResource
     */
    quantityTypeId: number;
    /**
     * 
     * @type {number}
     * @memberof StockResource
     */
    amount: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof StockResource
     */
    categories: Array<number>;
    /**
     * 
     * @type {Date}
     * @memberof StockResource
     */
    lastChange?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof StockResource
     */
    lastInventory?: Date | null;
}

/**
 * Check if a given object implements the StockResource interface.
 */
export function instanceOfStockResource(value: object): value is StockResource {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('isActive' in value) || value['isActive'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('quantityTypeId' in value) || value['quantityTypeId'] === undefined) return false;
    if (!('amount' in value) || value['amount'] === undefined) return false;
    if (!('categories' in value) || value['categories'] === undefined) return false;
    return true;
}

export function StockResourceFromJSON(json: any): StockResource {
    return StockResourceFromJSONTyped(json, false);
}

export function StockResourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): StockResource {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'isActive': json['isActive'],
        'articleNo': json['articleNo'] == null ? undefined : json['articleNo'],
        'name': json['name'],
        'quantityTypeId': json['quantityTypeId'],
        'amount': json['amount'],
        'categories': json['categories'],
        'lastChange': json['lastChange'] == null ? undefined : (new Date(json['lastChange'])),
        'lastInventory': json['lastInventory'] == null ? undefined : (new Date(json['lastInventory'])),
    };
}

  export function StockResourceToJSON(json: any): StockResource {
      return StockResourceToJSONTyped(json, false);
  }

  export function StockResourceToJSONTyped(value?: StockResource | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'isActive': value['isActive'],
        'articleNo': value['articleNo'],
        'name': value['name'],
        'quantityTypeId': value['quantityTypeId'],
        'amount': value['amount'],
        'categories': value['categories'],
        'lastChange': value['lastChange'] == null ? undefined : ((value['lastChange'] as any).toISOString()),
        'lastInventory': value['lastInventory'] == null ? undefined : ((value['lastInventory'] as any).toISOString()),
    };
}

