import * as React from 'react';
import {
  TextField,
} from 'react-admin';
import TranslateField from './translateField';

interface ShowFieldProps {
  className?: string;
  source: string;
  isTranslate?: boolean;
}

export default function ShowField(props: ShowFieldProps) {
  const { className, source, isTranslate } = props;
  return isTranslate
    ? (<TranslateField className={className} source={source} sx={{ verticalAlign: 'text-top' }} />)
    : (<TextField className={className} source={source} sx={{ verticalAlign: 'text-top' }} />);
};
