import * as React from 'react';
import {
  Admin,
  Authenticated,
  CustomRoutes,
  Resource,
} from 'react-admin';
import { Route } from 'react-router';

import { darkTheme, lightTheme } from 'components/shared/themes';

import LoginPage from 'components/shared/LoginPage';
import NoAccess from 'components/shared/noAccess';
import getI18nProvider from 'i18n/i18nProvider';
import groups from 'resources/auth/groups';
import ChangePassword from 'resources/auth/profile/changePasswordContainer';
import Profile from 'resources/auth/profile/profileContainer';
import users from 'resources/auth/users';
import businessPartners from 'resources/businessPartners';
import dashboard from 'resources/dashboard';
import productionItems from 'resources/manufacturing/productionItems';
import productionOrder from 'resources/manufacturing/productionOrders';
import Categories from 'resources/product/categories';
import products from 'resources/product/products';
import rawMaterials from 'resources/product/rawMaterials';
import purchaseOrder from 'resources/purchasing/purchaseOrder';
import exportShipments from 'resources/shipment/exportShipments';
import incomingShipments from 'resources/stock/incomingShipment';
import inventoryControls from 'resources/stock/inventoryControl';
import shelf from 'resources/stock/shelf';
import stockLines from 'resources/stock/stock';
import StockMoves from 'resources/stock/stockMove';
import stockSplit from 'resources/stock/stockSplit';
import resourcePrices from 'resources/product/resourcePrices';
import layout from './layout';

import authProvider from './authProvider';
import dataProvider from './dataProvider';

import 'assets/scss/style.scss';

function App() {
  const i18nProvider = React.useMemo(
    getI18nProvider,
    [],
  );

  return (
    <Admin
      layout={layout}
      dashboard={dashboard}
      loginPage={LoginPage}
      dataProvider={dataProvider}
      authProvider={authProvider}
      i18nProvider={i18nProvider}
      theme={lightTheme}
      darkTheme={darkTheme}
      disableTelemetry
    >
      <Resource {...users} />
      <Resource {...groups} />
      <Resource {...businessPartners} />
      <Resource {...products} />
      <Resource {...Categories} />
      <Resource {...rawMaterials} />
      <Resource {...resourcePrices} />
      <Resource {...stockLines} />
      <Resource {...StockMoves} />
      <Resource {...stockSplit} />
      <Resource {...incomingShipments} />
      <Resource {...inventoryControls} />
      <Resource {...purchaseOrder} />
      <Resource {...shelf} />
      <Resource {...productionItems} />
      <Resource {...exportShipments} />
      <Resource {...exportShipments} />
      <Resource {...productionOrder} />
      <CustomRoutes>
        <Route path="/profile" element={<Authenticated><Profile /></Authenticated>} />
        <Route path="/change-password" element={<Authenticated><ChangePassword /></Authenticated>} />
        <Route path="/no-access" element={<NoAccess />} />
      </CustomRoutes>
    </Admin>
  );
}

export default App;
