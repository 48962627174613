import { PictureAsPdf } from '@mui/icons-material';
import FlightLandIcon from '@mui/icons-material/FlightLand';
import GradingIcon from '@mui/icons-material/Grading';
import GridOnIcon from '@mui/icons-material/GridOn';
import { PurchaseOrderSearchRequest, PurchasingPurchaseOrderList, PurchasingPurchaseOrderListStateEnum } from 'api';
import urls, { Endpoint } from 'app/urls';
import CustomSearchInput from 'components/commons/customSearchInput';
import DefaultBulkActions from 'components/layout/actions/bulkActions';
import ListActions from 'components/layout/actions/list';
import { Badges, StatusBadge } from 'components/list/colorBadge';
import useHasAccess from 'hooks/useHasAccess';
import React from 'react';
import {
  BooleanInput,
  Button,
  Datagrid, DateField,
  List, TextField, useListContext,
  useRecordContext,
} from 'react-admin';
import { SelectBusinessPartners } from 'resources/product/rawMaterials/filters';
import { Source } from 'utils/source';
import BatchCreate from './batchCreate';
import styles from './list.module.scss';

interface CustomBulkExportProps {
  action: Endpoint;
  icon?: React.ReactElement;
  multiple?: boolean;
}

const filterQuery = Source<PurchaseOrderSearchRequest>();

const PurchaseOrderFilters = [
  <CustomSearchInput {...filterQuery('query')} alwaysOn placeholder_i18n="mai.keyword" />,
  <BooleanInput label="Active" {...filterQuery('confirmed')} />,
  <SelectBusinessPartners label="Business Partners" {...filterQuery('businessPartnerId')} />,
];

export function CustomBulkExport(props: CustomBulkExportProps) {
  const {
    action, icon, multiple,
  } = props;
  const hasAccess = useHasAccess();
  const canExport = hasAccess(action);
  const { selectedIds } = useListContext();

  const onClick = React.useCallback(() => {
    if (multiple) {
      selectedIds.forEach((value) => {
        const url = action.url.replace('{id}', value);
        window.open(`/api/1.0/${url}`);
      });
    } else {
      window.open(`/api/1.0/${action.url}?ids=${selectedIds.join(',')}`);
    }
  }, [selectedIds, multiple]);

  return (
    <Button onClick={onClick} label="ra.action.export" disabled={!canExport}>
      {icon || <PictureAsPdf />}
    </Button>
  );
}

function BulkActions() {
  return (
    <>
      <DefaultBulkActions />
      <CustomBulkExport
        action={urls.PurchaseOrder_ExportPdf}
        multiple
      />
      <CustomBulkExport
        action={urls.PurchaseOrder_ExportExcel}
        icon={<GridOnIcon />}
      />
    </>
  );
}

function ListActions2() {
  const hasAccess = useHasAccess();
  const canBatchCreate = hasAccess(urls.PurchaseOrder_BulkCreate);
  return (
    <ListActions>
      {canBatchCreate && <BatchCreate />}
    </ListActions>
  );
}

interface PurchaseOrderStatusProps {
  source: string
}

export function PurchaseOrderStatus(props: PurchaseOrderStatusProps) {
  const {
    source,
  } = props;
  console.log(source);
  const record = useRecordContext<PurchasingPurchaseOrderList>();

  if (!record) {
    return null;
  }

  return (
    <Badges className={styles.badges}>
      <StatusBadge
        green={record.confirmationState === PurchasingPurchaseOrderListStateEnum.Done}
        orange={record.confirmationState === PurchasingPurchaseOrderListStateEnum.Partial}
      >
        <GradingIcon />
      </StatusBadge>
      <StatusBadge
        hidden={record.state === PurchasingPurchaseOrderListStateEnum.Waiting}
        green={record.state === PurchasingPurchaseOrderListStateEnum.Done}
        orange={record.state === PurchasingPurchaseOrderListStateEnum.Partial}
      >
        <FlightLandIcon />
      </StatusBadge>
      <StatusBadge
        hidden={record.state === PurchasingPurchaseOrderListStateEnum.Waiting}
        green={record.state === PurchasingPurchaseOrderListStateEnum.Done}
        orange={record.state === PurchasingPurchaseOrderListStateEnum.Partial}
        fillratio={record?.doneRatio}
      >
        {record?.doneRatio}
        %
      </StatusBadge>
    </Badges>
  );
}

export default function PurchaseOrderList() {
  const s = Source<PurchasingPurchaseOrderList>();
  return (
    <List
      actions={<ListActions2 />}
      filters={PurchaseOrderFilters}
      empty={false}
      sort={{ field: 'createdAt', order: 'DESC' }}
    >
      <Datagrid rowClick="show" bulkActionButtons={<BulkActions />}>
        <PurchaseOrderStatus source="status" />
        <TextField {...s('orderSequence')} />
        <TextField {...s('businessPartner')} />
        <TextField {...s('comment')} />
        <DateField {...s('createdAt')} />
        <TextField {...s('createdBy')} />
        <DateField {...s('plannedDate')} />
        <DateField {...s('confirmationDate')} />
      </Datagrid>
    </List>
  );
}
