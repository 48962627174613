/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: smn@advance.ch
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Paging } from './Paging';
import {
    PagingFromJSON,
    PagingFromJSONTyped,
    PagingToJSON,
    PagingToJSONTyped,
} from './Paging';
import type { StockMoveList } from './StockMoveList';
import {
    StockMoveListFromJSON,
    StockMoveListFromJSONTyped,
    StockMoveListToJSON,
    StockMoveListToJSONTyped,
} from './StockMoveList';

/**
 * 
 * @export
 * @interface PaginatedListStockMove
 */
export interface PaginatedListStockMove {
    /**
     * 
     * @type {Array<StockMoveList>}
     * @memberof PaginatedListStockMove
     */
    list: Array<StockMoveList>;
    /**
     * 
     * @type {Paging}
     * @memberof PaginatedListStockMove
     */
    paging: Paging;
}

/**
 * Check if a given object implements the PaginatedListStockMove interface.
 */
export function instanceOfPaginatedListStockMove(value: object): value is PaginatedListStockMove {
    if (!('list' in value) || value['list'] === undefined) return false;
    if (!('paging' in value) || value['paging'] === undefined) return false;
    return true;
}

export function PaginatedListStockMoveFromJSON(json: any): PaginatedListStockMove {
    return PaginatedListStockMoveFromJSONTyped(json, false);
}

export function PaginatedListStockMoveFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaginatedListStockMove {
    if (json == null) {
        return json;
    }
    return {
        
        'list': ((json['list'] as Array<any>).map(StockMoveListFromJSON)),
        'paging': PagingFromJSON(json['paging']),
    };
}

  export function PaginatedListStockMoveToJSON(json: any): PaginatedListStockMove {
      return PaginatedListStockMoveToJSONTyped(json, false);
  }

  export function PaginatedListStockMoveToJSONTyped(value?: PaginatedListStockMove | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'list': ((value['list'] as Array<any>).map(StockMoveListToJSON)),
        'paging': PagingToJSON(value['paging']),
    };
}

