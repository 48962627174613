/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: smn@advance.ch
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AuthLoginResultDTO
 */
export interface AuthLoginResultDTO {
    /**
     * 
     * @type {string}
     * @memberof AuthLoginResultDTO
     */
    token?: string | null;
}

/**
 * Check if a given object implements the AuthLoginResultDTO interface.
 */
export function instanceOfAuthLoginResultDTO(value: object): value is AuthLoginResultDTO {
    return true;
}

export function AuthLoginResultDTOFromJSON(json: any): AuthLoginResultDTO {
    return AuthLoginResultDTOFromJSONTyped(json, false);
}

export function AuthLoginResultDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuthLoginResultDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'token': json['token'] == null ? undefined : json['token'],
    };
}

  export function AuthLoginResultDTOToJSON(json: any): AuthLoginResultDTO {
      return AuthLoginResultDTOToJSONTyped(json, false);
  }

  export function AuthLoginResultDTOToJSONTyped(value?: AuthLoginResultDTO | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'token': value['token'],
    };
}

