/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: smn@advance.ch
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AuthGroupEntity
 */
export interface AuthGroupEntity {
    /**
     * 
     * @type {string}
     * @memberof AuthGroupEntity
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof AuthGroupEntity
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof AuthGroupEntity
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuthGroupEntity
     */
    readonly typeName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AuthGroupEntity
     */
    typeCode: number;
}

/**
 * Check if a given object implements the AuthGroupEntity interface.
 */
export function instanceOfAuthGroupEntity(value: object): value is AuthGroupEntity {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('typeCode' in value) || value['typeCode'] === undefined) return false;
    return true;
}

export function AuthGroupEntityFromJSON(json: any): AuthGroupEntity {
    return AuthGroupEntityFromJSONTyped(json, false);
}

export function AuthGroupEntityFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuthGroupEntity {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'description': json['description'] == null ? undefined : json['description'],
        'typeName': json['typeName'] == null ? undefined : json['typeName'],
        'typeCode': json['typeCode'],
    };
}

  export function AuthGroupEntityToJSON(json: any): AuthGroupEntity {
      return AuthGroupEntityToJSONTyped(json, false);
  }

  export function AuthGroupEntityToJSONTyped(value?: Omit<AuthGroupEntity, 'typeName'> | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'name': value['name'],
        'description': value['description'],
        'typeCode': value['typeCode'],
    };
}

