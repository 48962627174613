/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: smn@advance.ch
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ProductRawMaterialEntity } from './ProductRawMaterialEntity';
import {
    ProductRawMaterialEntityFromJSON,
    ProductRawMaterialEntityFromJSONTyped,
    ProductRawMaterialEntityToJSON,
    ProductRawMaterialEntityToJSONTyped,
} from './ProductRawMaterialEntity';
import type { Paging } from './Paging';
import {
    PagingFromJSON,
    PagingFromJSONTyped,
    PagingToJSON,
    PagingToJSONTyped,
} from './Paging';

/**
 * 
 * @export
 * @interface PaginatedListRawMaterialEntity
 */
export interface PaginatedListRawMaterialEntity {
    /**
     * 
     * @type {Array<ProductRawMaterialEntity>}
     * @memberof PaginatedListRawMaterialEntity
     */
    list: Array<ProductRawMaterialEntity>;
    /**
     * 
     * @type {Paging}
     * @memberof PaginatedListRawMaterialEntity
     */
    paging: Paging;
}

/**
 * Check if a given object implements the PaginatedListRawMaterialEntity interface.
 */
export function instanceOfPaginatedListRawMaterialEntity(value: object): value is PaginatedListRawMaterialEntity {
    if (!('list' in value) || value['list'] === undefined) return false;
    if (!('paging' in value) || value['paging'] === undefined) return false;
    return true;
}

export function PaginatedListRawMaterialEntityFromJSON(json: any): PaginatedListRawMaterialEntity {
    return PaginatedListRawMaterialEntityFromJSONTyped(json, false);
}

export function PaginatedListRawMaterialEntityFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaginatedListRawMaterialEntity {
    if (json == null) {
        return json;
    }
    return {
        
        'list': ((json['list'] as Array<any>).map(ProductRawMaterialEntityFromJSON)),
        'paging': PagingFromJSON(json['paging']),
    };
}

  export function PaginatedListRawMaterialEntityToJSON(json: any): PaginatedListRawMaterialEntity {
      return PaginatedListRawMaterialEntityToJSONTyped(json, false);
  }

  export function PaginatedListRawMaterialEntityToJSONTyped(value?: PaginatedListRawMaterialEntity | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'list': ((value['list'] as Array<any>).map(ProductRawMaterialEntityToJSON)),
        'paging': PagingToJSON(value['paging']),
    };
}

