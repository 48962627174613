import React from 'react';
import { Create } from 'react-admin';
import ResourcePricesForm from './form/resourcePricesForm';

export default function CreateResourcePriceContainer() {
  return (
    <Create redirect="show">
      <ResourcePricesForm />
    </Create>
  );
}
