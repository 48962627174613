import {
  Box, Button, Dialog,
  ListItemText, Table, TableBody, TableCell, TableHead,
  TextField,
} from '@mui/material';
import { ProductDetailSchemaStockTracingEnum, ResponseInfo } from 'api';
import { ResourceQuantityInput } from 'components/input/quantity/resourceFields';
import EditActions from 'components/layout/actions/edit';
import { TableRow } from 'components/layout/table';
import React from 'react';
import {
  HttpError,
  Show,
  TextInput,
} from 'react-admin';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import api from 'repositories/api';
import { useEnumQuery } from 'repositories/app';
import { useRawMaterialQuery } from 'repositories/products/product';
import repositories, { useReceiveShipment, useShipment } from 'repositories/stock/receiveShipment';
import exportStockLineQrCode from 'resources/stock/stockLine/qrCode';
import useNotify from 'utils/notify';
import BarcodeHandler from './barcode';
import ReceiveShipmentTable from './form/receiveShipmentTable';
import FormState from './form/state';
import styles from './receiveShipment.module.scss';

export default function ReceiveShipment() {
  const { id } = useParams();
  const shipmentId = Number(id);
  const notify = useNotify();
  const { data: shipment } = useShipment({ id: shipmentId });
  const { data: stockMove, refetch } = useReceiveShipment({ id: shipmentId });
  const { data: enums } = useEnumQuery();

  const handleSave = async (data: FormState) => {
    const postData = data;
    if (postData.resourceId === undefined || postData.amount === undefined) {
      throw new Error('invalid amount');
    }
    try {
      const response = await api.receiveShipmentAddLine({
        id: shipmentId,
        stockReceiveShipmentLine: {
          rawMaterialId: postData.resourceId,
          amount: postData.amount,
          batchName: postData.stockLotName,
        },
      });
      await refetch();
      exportStockLineQrCode(
        [{
          ...response.stockLine,
          amount: response.amount,
        }],
        enums?.quantityTypes,
      );
    } catch (e) {
      if (e instanceof HttpError) {
        notify(e, { type: 'error' });
      }
    }
  };
  const onDelete = async (lineId: number) => {
    const response: ResponseInfo = await repositories.deleteReceiveLine({
      id: shipmentId,
      lineId,
    });
    if (response.code === 200) {
      await refetch();
    }
  };
  const form = useForm();
  const resourceId = form.watch('resourceId');
  const { data: resource } = useRawMaterialQuery(
    { id: resourceId },
    { enabled: !!resourceId },
  );
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const select = (newId: number) => {
    form.setValue('resourceId', newId);
    setDialogOpen(false);
  };

  return (
    <div>
      <Show actions={<EditActions />}>
        <ReceiveShipmentTable
          listMaterials={stockMove?.lines ?? []}
          isDelete={!stockMove?.appliedDate}
          onDelete={onDelete}
        />
        <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} disableRestoreFocus>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Article No</TableCell>
                <TableCell>Resource</TableCell>
                <TableCell>Planned Amount</TableCell>
                <TableCell>unit</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {shipment?.lines?.map((line) => (
                <TableRow clickable key={line.id} onClick={() => select(line.rawMaterialId)}>
                  <TableCell><ListItemText primary={line.articleNo} /></TableCell>
                  <TableCell><ListItemText primary={line.rawMaterialName} /></TableCell>
                  <TableCell><ListItemText primary={line.amount} /></TableCell>
                  <TableCell><ListItemText primary={line.quantityTypeId} /></TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Dialog>
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(handleSave)} className={styles.receiveShipmentForm}>
            <Box className="box">
              <TextField
                value={resource?.name || ''}
                onFocus={() => setDialogOpen(true)}
                label="Product"
                fullWidth
                size="small"
              />
              <ResourceQuantityInput
                className="x"
                source="amount"
                required
                label="mai.incomingShipment.receive.Amount"
                resourceId={Number(resourceId)}
              />
              <TextInput
                source="stockLotName"
                fullWidth
                disabled={resource?.stockTracing === ProductDetailSchemaStockTracingEnum.None}
                required={resource?.stockTracing !== ProductDetailSchemaStockTracingEnum.None}
                label="mai.incomingShipment.receive.LotNo"
              />
              <Button type="submit" variant="outlined">Submit</Button>
            </Box>
            <BarcodeHandler onSubmit={handleSave} />
          </form>
        </FormProvider>
      </Show>
    </div>
  );
}
