/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: smn@advance.ch
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ProductsResourcePriceBulkCreate } from './ProductsResourcePriceBulkCreate';
import {
    ProductsResourcePriceBulkCreateFromJSON,
    ProductsResourcePriceBulkCreateFromJSONTyped,
    ProductsResourcePriceBulkCreateToJSON,
    ProductsResourcePriceBulkCreateToJSONTyped,
} from './ProductsResourcePriceBulkCreate';

/**
 * 
 * @export
 * @interface ProductsResourcePriceBulkCreateEntity
 */
export interface ProductsResourcePriceBulkCreateEntity {
    /**
     * 
     * @type {Array<ProductsResourcePriceBulkCreate>}
     * @memberof ProductsResourcePriceBulkCreateEntity
     */
    resourcePrices: Array<ProductsResourcePriceBulkCreate>;
}

/**
 * Check if a given object implements the ProductsResourcePriceBulkCreateEntity interface.
 */
export function instanceOfProductsResourcePriceBulkCreateEntity(value: object): value is ProductsResourcePriceBulkCreateEntity {
    if (!('resourcePrices' in value) || value['resourcePrices'] === undefined) return false;
    return true;
}

export function ProductsResourcePriceBulkCreateEntityFromJSON(json: any): ProductsResourcePriceBulkCreateEntity {
    return ProductsResourcePriceBulkCreateEntityFromJSONTyped(json, false);
}

export function ProductsResourcePriceBulkCreateEntityFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductsResourcePriceBulkCreateEntity {
    if (json == null) {
        return json;
    }
    return {
        
        'resourcePrices': ((json['resourcePrices'] as Array<any>).map(ProductsResourcePriceBulkCreateFromJSON)),
    };
}

  export function ProductsResourcePriceBulkCreateEntityToJSON(json: any): ProductsResourcePriceBulkCreateEntity {
      return ProductsResourcePriceBulkCreateEntityToJSONTyped(json, false);
  }

  export function ProductsResourcePriceBulkCreateEntityToJSONTyped(value?: ProductsResourcePriceBulkCreateEntity | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'resourcePrices': ((value['resourcePrices'] as Array<any>).map(ProductsResourcePriceBulkCreateToJSON)),
    };
}

