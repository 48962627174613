import { RawMaterialsSearchRequest } from 'api';
import { CategoryInput } from 'components/chooser/categoryChoose';
import CustomSearchInput from 'components/commons/customSearchInput';
import EnumSelectField from 'components/input/enumSelectField';
import React from 'react';
import {
  BooleanInput,
  SearchInput,
  SelectInput,
} from 'react-admin';
import { useCurrencies, useEnumQuery } from 'repositories/app';
import { useBusinessPartners } from 'repositories/businessPartners/businessPartner';
import { StockTracingChoices } from 'repositories/products/product';
import { Source } from 'utils/source';

const s = Source<RawMaterialsSearchRequest>();

interface FilterProps {
  label: string;
  source: string;
}

export function SelectBusinessPartners(props: FilterProps) {
  const { data: businessPartners } = useBusinessPartners();
  if (!businessPartners) return null;

  return (
    <SelectInput
      placeholder="Business Partners"
      choices={businessPartners ?? []}
      variant="outlined"
      {...props}
    />
  );
}

function SelectCurrencies(props: FilterProps) {
  const { data: currencies } = useCurrencies();
  if (!currencies) return null;

  return (
    <SelectInput
      placeholder="Currencies"
      choices={currencies ?? []}
      variant="outlined"
      {...props}
    />
  );
}

function SelectQuantityTypes(props: FilterProps) {
  const { data: enums } = useEnumQuery();
  if (!enums) return null;

  return (
    <SelectInput
      placeholder="Quantity Types"
      choices={enums.quantityTypes ?? []}
      variant="outlined"
      {...props}
    />
  );
}

/* eslint-disable react/jsx-key */
export default [
  <CustomSearchInput source="query" alwaysOn placeholder_i18n="mai.keyword" />,
  <BooleanInput label="Active" {...s('isActive')} />,
  <SelectBusinessPartners label="Business Partners" {...s('businessPartnerId')} />,
  <CategoryInput label="Category" {...s('categoryIds')} />,
  <SelectCurrencies label="Currency" {...s('currencyId')} />,
  <SelectQuantityTypes label="Quantity Type" {...s('quantityTypeId')} />,
  <EnumSelectField
    label="resources.product/raw-materials.fields.stockTracingCode"
    {...s('stockTracingCode')}
    choices={StockTracingChoices}
  />,
  <SearchInput source="SupplierArticleNo" placeholder="Supplier Article No." />,
  <SearchInput source="SupplierArticleName" placeholder="Supplier Article Name" />,
];
