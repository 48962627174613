import { Path } from 'react-hook-form';

export function Source<M>(prefix?: string) {
  function sourceFunction<T extends Path<M>>(source: T) {
    if (prefix !== undefined) {
      return {
        source: `${prefix}.${source}`,
      };
    }
    return { source };
  }

  return sourceFunction;
}
