/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: smn@advance.ch
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { StockLocationSchema } from './StockLocationSchema';
import {
    StockLocationSchemaFromJSON,
    StockLocationSchemaFromJSONTyped,
    StockLocationSchemaToJSON,
    StockLocationSchemaToJSONTyped,
} from './StockLocationSchema';
import type { StockLinesLot } from './StockLinesLot';
import {
    StockLinesLotFromJSON,
    StockLinesLotFromJSONTyped,
    StockLinesLotToJSON,
    StockLinesLotToJSONTyped,
} from './StockLinesLot';

/**
 * 
 * @export
 * @interface StockLinesLine
 */
export interface StockLinesLine {
    /**
     * 
     * @type {number}
     * @memberof StockLinesLine
     */
    id: number;
    /**
     * 
     * @type {StockLinesLot}
     * @memberof StockLinesLine
     */
    stockLot: StockLinesLot;
    /**
     * 
     * @type {number}
     * @memberof StockLinesLine
     */
    labelId: number;
    /**
     * 
     * @type {number}
     * @memberof StockLinesLine
     */
    amount: number;
    /**
     * 
     * @type {number}
     * @memberof StockLinesLine
     */
    stockLocationId: number;
    /**
     * 
     * @type {StockLocationSchema}
     * @memberof StockLinesLine
     */
    stockLocation: StockLocationSchema;
    /**
     * 
     * @type {number}
     * @memberof StockLinesLine
     */
    stockShelfId: number;
}

/**
 * Check if a given object implements the StockLinesLine interface.
 */
export function instanceOfStockLinesLine(value: object): value is StockLinesLine {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('stockLot' in value) || value['stockLot'] === undefined) return false;
    if (!('labelId' in value) || value['labelId'] === undefined) return false;
    if (!('amount' in value) || value['amount'] === undefined) return false;
    if (!('stockLocationId' in value) || value['stockLocationId'] === undefined) return false;
    if (!('stockLocation' in value) || value['stockLocation'] === undefined) return false;
    if (!('stockShelfId' in value) || value['stockShelfId'] === undefined) return false;
    return true;
}

export function StockLinesLineFromJSON(json: any): StockLinesLine {
    return StockLinesLineFromJSONTyped(json, false);
}

export function StockLinesLineFromJSONTyped(json: any, ignoreDiscriminator: boolean): StockLinesLine {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'stockLot': StockLinesLotFromJSON(json['stockLot']),
        'labelId': json['labelId'],
        'amount': json['amount'],
        'stockLocationId': json['stockLocationId'],
        'stockLocation': StockLocationSchemaFromJSON(json['stockLocation']),
        'stockShelfId': json['stockShelfId'],
    };
}

  export function StockLinesLineToJSON(json: any): StockLinesLine {
      return StockLinesLineToJSONTyped(json, false);
  }

  export function StockLinesLineToJSONTyped(value?: StockLinesLine | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'stockLot': StockLinesLotToJSON(value['stockLot']),
        'labelId': value['labelId'],
        'amount': value['amount'],
        'stockLocationId': value['stockLocationId'],
        'stockLocation': StockLocationSchemaToJSON(value['stockLocation']),
        'stockShelfId': value['stockShelfId'],
    };
}

