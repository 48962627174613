/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: smn@advance.ch
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StockShelfList
 */
export interface StockShelfList {
    /**
     * 
     * @type {number}
     * @memberof StockShelfList
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof StockShelfList
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof StockShelfList
     */
    stockLocationId: number;
    /**
     * 
     * @type {string}
     * @memberof StockShelfList
     */
    stockLocationName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StockShelfList
     */
    comment?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof StockShelfList
     */
    image: boolean;
}

/**
 * Check if a given object implements the StockShelfList interface.
 */
export function instanceOfStockShelfList(value: object): value is StockShelfList {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('stockLocationId' in value) || value['stockLocationId'] === undefined) return false;
    if (!('image' in value) || value['image'] === undefined) return false;
    return true;
}

export function StockShelfListFromJSON(json: any): StockShelfList {
    return StockShelfListFromJSONTyped(json, false);
}

export function StockShelfListFromJSONTyped(json: any, ignoreDiscriminator: boolean): StockShelfList {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'stockLocationId': json['stockLocationId'],
        'stockLocationName': json['stockLocationName'] == null ? undefined : json['stockLocationName'],
        'comment': json['comment'] == null ? undefined : json['comment'],
        'image': json['image'],
    };
}

  export function StockShelfListToJSON(json: any): StockShelfList {
      return StockShelfListToJSONTyped(json, false);
  }

  export function StockShelfListToJSONTyped(value?: StockShelfList | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'name': value['name'],
        'stockLocationId': value['stockLocationId'],
        'stockLocationName': value['stockLocationName'],
        'comment': value['comment'],
        'image': value['image'],
    };
}

