/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: smn@advance.ch
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AppQuantityTypeOption } from './AppQuantityTypeOption';
import {
    AppQuantityTypeOptionFromJSON,
    AppQuantityTypeOptionFromJSONTyped,
    AppQuantityTypeOptionToJSON,
    AppQuantityTypeOptionToJSONTyped,
} from './AppQuantityTypeOption';

/**
 * 
 * @export
 * @interface AppEnumSchema
 */
export interface AppEnumSchema {
    /**
     * 
     * @type {Array<AppQuantityTypeOption>}
     * @memberof AppEnumSchema
     */
    quantityTypes: Array<AppQuantityTypeOption>;
}

/**
 * Check if a given object implements the AppEnumSchema interface.
 */
export function instanceOfAppEnumSchema(value: object): value is AppEnumSchema {
    if (!('quantityTypes' in value) || value['quantityTypes'] === undefined) return false;
    return true;
}

export function AppEnumSchemaFromJSON(json: any): AppEnumSchema {
    return AppEnumSchemaFromJSONTyped(json, false);
}

export function AppEnumSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): AppEnumSchema {
    if (json == null) {
        return json;
    }
    return {
        
        'quantityTypes': ((json['quantityTypes'] as Array<any>).map(AppQuantityTypeOptionFromJSON)),
    };
}

  export function AppEnumSchemaToJSON(json: any): AppEnumSchema {
      return AppEnumSchemaToJSONTyped(json, false);
  }

  export function AppEnumSchemaToJSONTyped(value?: AppEnumSchema | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'quantityTypes': ((value['quantityTypes'] as Array<any>).map(AppQuantityTypeOptionToJSON)),
    };
}

