import { StockIncomingShipmentList } from 'api';
import EnumField from 'components/fields/enumField';
import { Badges, StatusBadge } from 'components/list/colorBadge';
import Datagrid from 'components/list/dataGrid';
import List from 'components/list/List';
import * as React from 'react';
import {
  DateField,
  FunctionField,
  TextField,
  useListContext,
} from 'react-admin';
import { Source } from 'utils/source';
import { incomingShipmentState, incomingShipmentTransportType } from './enum';
import filters from './filters';

function ShipmentSortSwitch() {
  const sorts: [string, 'ASC' | 'DESC'][] = [
    ['estimatedTimeofArrival', 'ASC'],
    ['actualTimeofArrival', 'DESC'],
  ];

  const listContext = useListContext();
  const [lastFilterValue, setLastFilterValue] = React.useState<'unset' | true | false>();

  React.useEffect(() => {
    const newSort = listContext.filterValues?.isOpen ? sorts[0] : sorts[1];
    if (
      lastFilterValue !== undefined
      && listContext.filterValues?.isOpen !== undefined
      && lastFilterValue !== listContext.filterValues?.isOpen
      && (
        !listContext.sort
        || listContext.sort?.field === 'id'
        || sorts.find((x) => x[0] === listContext.sort?.field && x[1] === listContext.sort?.order)
      )) {
      listContext.setSort({
        field: newSort[0],
        order: newSort[1],
      });
    }
    if (lastFilterValue !== listContext.filterValues?.isOpen) {
      setLastFilterValue(listContext.filterValues?.isOpen);
    }
  }, [listContext.filterValues?.isOpen, lastFilterValue, listContext.sort]);

  return null;
}

export default function () {
  const s = Source<StockIncomingShipmentList>();
  return (
    <List
      filters={filters}
      filterDefaultValues={{ isOpen: true }}
    >
      <ShipmentSortSwitch />
      <Datagrid rowClick="show">
        <FunctionField render={(record: StockIncomingShipmentList) => {
          const isProcessing = record.state === 'during_shipment' || record.state === 'processing';
          return (
            <Badges inline>
              <StatusBadge green={record.state === 'done'} orange={isProcessing} />
            </Badges>
          );
        }}
        />
        <EnumField {...s('state')} choices={incomingShipmentState} />
        <TextField {...s('supplier')} />
        <TextField {...s('shipmentName')} label="Name" />
        <EnumField {...s('transportType')} choices={incomingShipmentTransportType} />
        <DateField {...s('estimatedTimeofArrival')} label="ETA" />
        <DateField {...s('actualTimeofArrival')} label="ATA" />
        <TextField {...s('comment')} />
        <TextField {...s('createdBy')} />
      </Datagrid>
    </List>
  );
}
