import { ProductProductionItemDetailSchema } from 'api';
import CategoryField from 'components/fields/categoryField';
import List from 'components/list/List';
import Datagrid from 'components/list/dataGrid';
import * as React from 'react';
import {
  CreateButton,
  DateField, TextField,
  TopToolbar,
} from 'react-admin';
import { Source } from 'utils/source';
import filters from './filters';
import { BatchCreateButton } from './form/ProductionItemsForm';

function PopupCreateProduction() {
  return (
    <TopToolbar>
      <BatchCreateButton />
      <CreateButton />
    </TopToolbar>
  );
}

export default function () {
  const s = Source<ProductProductionItemDetailSchema>();
  return (
    <List
      filters={filters}
      actions={<PopupCreateProduction />}
    >
      <Datagrid
        rowClick="show"
      // bulkActionButtons={<PopupCreateProduction/>}
      >
        <CategoryField {...s('product.categories')} sortable={false} />
        <TextField {...s('product.name')} sortBy="productName" />
        <TextField {...s('product.size')} sortable={false} />
        <TextField {...s('product.color')} sortable={false} />
        <TextField {...s('product.option')} sortable={false} />
        <TextField {...s('amount')} />
        <TextField {...s('serialNo')} />
        <TextField {...s('productionNo')} />
        <TextField {...s('comment')} />
        <DateField {...s('plannedEndDate')} />
        <DateField {...s('plannedExportDate')} />
      </Datagrid>
    </List>
  );
}
