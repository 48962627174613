import React from 'react';
import {
  SimpleForm,
  TextInput,
  DateInput,
  Title,
  Toolbar,
  SaveButton,
  required,
  regex,
  useNotify,
} from 'react-admin';
import { Grid } from '@mui/material';
import useTranslate from 'utils/translate';
import { useMutateProfile, useProfile } from 'repositories/auth/profile';
import EnumSelectField from 'components/input/enumSelectField';
import Genders from '../users/enums';

function ProfileContainer() {
  const { isLoading, data: profile } = useProfile();
  const mutateProfile = useMutateProfile();
  const translate = useTranslate();
  const notify = useNotify();

  if (isLoading) {
    return null;
  }

  const saveProfile = (body: any) => {
    const mutation = {
      id: `${profile?.id}`,
      body,
    };
    if (body.birthday) {
      mutation.body.birthday = new Date(body.birthday);
    }

    mutateProfile.mutateAsync(mutation)
      .then(() => notify('mai.msg.save_profile_success', {
        type: 'info',
      }));
  };

  return (
    (<div className="mai-form">
      <SimpleForm toolbar={<Toolbar><SaveButton /></Toolbar>} record={profile} onSubmit={saveProfile}>
        <Title title={translate('mai.profile')} />
        <Grid container spacing={2} rowSpacing={0.5}>
          <Grid item xs={4} md={1} />
          <Grid item xs={8} md={5}>
            <TextInput
              source="firstname"
              label={translate('mai.firstname')}
              validate={required()}
              fullWidth
              inputProps={{ maxLength: 50 }}
              variant="outlined"
              helperText={false}
            />
          </Grid>
          <Grid item xs={8} md={5}>
            <TextInput
              source="lastname"
              label={translate('mai.lastname')}
              validate={required()}
              fullWidth
              inputProps={{ maxLength: 50 }}
              variant="outlined"
              helperText={false}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} rowSpacing={0.5}>
          <Grid item xs={4} md={1} />
          <Grid item xs={8} md={5}>
            <TextInput
              source="email"
              label={translate('mai.email')}
              validate={required()}
              type="email"
              fullWidth
              inputProps={{ maxLength: 255 }}
              variant="outlined"
              helperText={false}
            />
          </Grid>
          <Grid item xs={8} md={5}>
            <TextInput
              source="phone"
              label={translate('mai.phone')}
              fullWidth
              inputProps={{ maxLength: 15 }}
              validate={[
                regex(
                  /^[(]?[0-9]{1,3}[)]? ?-?[0-9]{1,3} ?-?[0-9]{3,5} ?-?[0-9]{4}( ?-?[0-9]{3})? ?(w{1,10}s?d{1,6})?$/,
                  'mai.msg.phone_validate_error',
                ),
              ]}
              variant="outlined"
              helperText={false}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} rowSpacing={0.5}>
          <Grid item xs={4} md={1} />
          <Grid item xs={8} md={5}>
            <EnumSelectField
              source="gender"
              label="mai.gender"
              choices={Genders}
              fullWidth
            />
          </Grid>
          <Grid item xs={8} md={5}>
            <DateInput
              source="birthday"
              label={translate('mai.birthday')}
              fullWidth
              variant="outlined"
              helperText={false}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} rowSpacing={0.5}>
          <Grid item xs={4} md={1} />
          <Grid item xs={16} md={10}>
            <TextInput
              source="address"
              label={translate('mai.address')}
              fullWidth
              inputProps={{ maxLength: 255 }}
              variant="outlined"
              helperText={false}
              multiline
              rows={4}
            />
          </Grid>
        </Grid>
      </SimpleForm>
    </div>)
  );
}

export default ProfileContainer;
