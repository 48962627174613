import React from 'react';
import { Authenticated } from 'react-admin';
import { Route } from 'react-router';
import createStockMove from './createStockMoveContainer';
import editStockMove from './editStockMoveContainer';
import StockLinePlannedContainer from './lines/plannedStockLines';
import { AddStockMoveLine } from './lines/stockMoveLines';
import stockMoveList from './list/stockMoveList';
import showStockMove from './showStockMoveContainer';

export default {
  name: 'stock/stock-moves',
  list: stockMoveList,
  edit: editStockMove,
  create: createStockMove,
  show: showStockMove,
  options: {
    label: 'Stock moves',
  },
  children: [
    // eslint-disable-next-line react/jsx-key
    <Route
      path="line/:id"
      element={<Authenticated><AddStockMoveLine /></Authenticated>}
    />,
    // eslint-disable-next-line react/jsx-key
    <Route
      path="line/planned/:id"
      element={<Authenticated><StockLinePlannedContainer /></Authenticated>}
    />,
  ],
};
