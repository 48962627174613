import { Grid } from '@mui/material';
import { StockMoveDetail, StockMoveEntity } from 'api';
import ShowDate from 'components/commons/showDate';
import ShowField from 'components/commons/showField';
import ShowLabel from 'components/commons/showLabel';
import ShowTitle from 'components/commons/showTitle';
import useResourcePermissions from 'components/layout/actions/resourcePermissions';
import * as React from 'react';
import {
  CreateButton,
  DeleteButton,
  FunctionField,
  ListButton,
  Show,
  SimpleShowLayout,
  TopToolbar,
  useRecordContext,
} from 'react-admin';
import { Source } from 'utils/source';
import { ShowStockMoveLines } from '../stockMove/lines/stockMoveLines';
import { ExportPdfButton } from '../stockMove/showStockMoveActions';

function StockSplitActions() {
  const permissions = useResourcePermissions();
  const stockMove = useRecordContext<StockMoveDetail>();

  return (
    <TopToolbar>
      {permissions.create && <CreateButton />}
      {permissions.list && <ListButton />}
      {permissions.delete && (
        <DeleteButton
          label="ra.action.delete"
          mutationMode="pessimistic"
          disabled={!stockMove?.canUndo}
        />
      )}
      {stockMove && <ExportPdfButton />}
    </TopToolbar>
  );
}

export default function ShowSplitMove() {
  const s = Source<StockMoveEntity>();
  return (
    <Show actions={<StockSplitActions />}>
      <SimpleShowLayout>
        <Grid container rowSpacing={1} spacing={1} sx={{ marginLeft: '3px', marginTop: '3px', marginBottom: '10px' }}>
          <Grid item xs={12} md={12}>
            <ShowTitle />
          </Grid>

          <Grid item xs={2} md={1.5} sx={{ borderBottom: '1' }} className="show-row-odd">
            <ShowLabel label="resources.stock/stock-moves.fields.source_location" />
          </Grid>
          <Grid item xs={8} md={9} className="show-row-odd">
            <ShowField {...s('sourceStockLocationName')} />
          </Grid>
          <Grid item xs={2} md={1.5} className="show-row-odd" />

          <Grid item xs={2} md={1.5} sx={{ borderBottom: '1' }} className="show-row-odd">
            <ShowLabel label="resources.stock/stock-moves.fields.comment" />
          </Grid>
          <Grid item xs={8} md={9} className="show-row-odd">
            <ShowField {...s('comment')} />
          </Grid>
          <Grid item xs={2} md={1.5} className="show-row-odd" />

          <Grid item xs={2} md={1.5} sx={{ borderBottom: '1' }} className="show-row-odd">
            <ShowLabel label="resources.stock/stock-moves.fields.appliedDate" />
          </Grid>
          <Grid item xs={8} md={9} className="show-row-odd">
            <ShowDate {...s('appliedDate')} showTime />
          </Grid>
          <Grid item xs={2} md={1.5} className="show-row-odd" />

          <Grid item xs={2} md={1.5} sx={{ borderBottom: '1' }}>
            <ShowLabel label="resources.stock/stock-moves.fields.creator" />
          </Grid>
          <Grid item xs={8} md={9}>
            <ShowField {...s('createdBy')} />
          </Grid>
          <Grid item xs={2} md={1.5} />

          <Grid item xs={8} md={8}>
            <FunctionField render={(record: StockMoveEntity) => (
              <ShowStockMoveLines
                id={record.id.toString()}
                isApplied={!!record?.appliedDate}
                canDelete={false}
                hideSourceStockLine
                hideShelf
              />
            )}
            />
          </Grid>
        </Grid>
      </SimpleShowLayout>
    </Show>
  );
}
