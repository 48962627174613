/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: smn@advance.ch
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Paging } from './Paging';
import {
    PagingFromJSON,
    PagingFromJSONTyped,
    PagingToJSON,
    PagingToJSONTyped,
} from './Paging';
import type { PurchasingPurchaseOrderLineSolo } from './PurchasingPurchaseOrderLineSolo';
import {
    PurchasingPurchaseOrderLineSoloFromJSON,
    PurchasingPurchaseOrderLineSoloFromJSONTyped,
    PurchasingPurchaseOrderLineSoloToJSON,
    PurchasingPurchaseOrderLineSoloToJSONTyped,
} from './PurchasingPurchaseOrderLineSolo';

/**
 * 
 * @export
 * @interface PaginatedListPurchaseOrderLineSolo
 */
export interface PaginatedListPurchaseOrderLineSolo {
    /**
     * 
     * @type {Array<PurchasingPurchaseOrderLineSolo>}
     * @memberof PaginatedListPurchaseOrderLineSolo
     */
    list: Array<PurchasingPurchaseOrderLineSolo>;
    /**
     * 
     * @type {Paging}
     * @memberof PaginatedListPurchaseOrderLineSolo
     */
    paging: Paging;
}

/**
 * Check if a given object implements the PaginatedListPurchaseOrderLineSolo interface.
 */
export function instanceOfPaginatedListPurchaseOrderLineSolo(value: object): value is PaginatedListPurchaseOrderLineSolo {
    if (!('list' in value) || value['list'] === undefined) return false;
    if (!('paging' in value) || value['paging'] === undefined) return false;
    return true;
}

export function PaginatedListPurchaseOrderLineSoloFromJSON(json: any): PaginatedListPurchaseOrderLineSolo {
    return PaginatedListPurchaseOrderLineSoloFromJSONTyped(json, false);
}

export function PaginatedListPurchaseOrderLineSoloFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaginatedListPurchaseOrderLineSolo {
    if (json == null) {
        return json;
    }
    return {
        
        'list': ((json['list'] as Array<any>).map(PurchasingPurchaseOrderLineSoloFromJSON)),
        'paging': PagingFromJSON(json['paging']),
    };
}

  export function PaginatedListPurchaseOrderLineSoloToJSON(json: any): PaginatedListPurchaseOrderLineSolo {
      return PaginatedListPurchaseOrderLineSoloToJSONTyped(json, false);
  }

  export function PaginatedListPurchaseOrderLineSoloToJSONTyped(value?: PaginatedListPurchaseOrderLineSolo | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'list': ((value['list'] as Array<any>).map(PurchasingPurchaseOrderLineSoloToJSON)),
        'paging': PagingToJSON(value['paging']),
    };
}

