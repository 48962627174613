/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: smn@advance.ch
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PurchasingPurchaseOrderLine } from './PurchasingPurchaseOrderLine';
import {
    PurchasingPurchaseOrderLineFromJSON,
    PurchasingPurchaseOrderLineFromJSONTyped,
    PurchasingPurchaseOrderLineToJSON,
    PurchasingPurchaseOrderLineToJSONTyped,
} from './PurchasingPurchaseOrderLine';

/**
 * 
 * @export
 * @interface PurchasingPurchaseOrderEdit
 */
export interface PurchasingPurchaseOrderEdit {
    /**
     * 
     * @type {number}
     * @memberof PurchasingPurchaseOrderEdit
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof PurchasingPurchaseOrderEdit
     */
    deliveryAddress: PurchasingPurchaseOrderEditDeliveryAddressEnum;
    /**
     * 
     * @type {number}
     * @memberof PurchasingPurchaseOrderEdit
     */
    businessPartnerId: number;
    /**
     * 
     * @type {Date}
     * @memberof PurchasingPurchaseOrderEdit
     */
    plannedDate: Date;
    /**
     * 
     * @type {string}
     * @memberof PurchasingPurchaseOrderEdit
     */
    comment?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof PurchasingPurchaseOrderEdit
     */
    confirmationDate?: Date | null;
    /**
     * 
     * @type {Array<PurchasingPurchaseOrderLine>}
     * @memberof PurchasingPurchaseOrderEdit
     */
    lines: Array<PurchasingPurchaseOrderLine>;
}


/**
 * @export
 */
export const PurchasingPurchaseOrderEditDeliveryAddressEnum = {
    Andat: 'Andat',
    France: 'France',
    Swiss: 'Swiss'
} as const;
export type PurchasingPurchaseOrderEditDeliveryAddressEnum = typeof PurchasingPurchaseOrderEditDeliveryAddressEnum[keyof typeof PurchasingPurchaseOrderEditDeliveryAddressEnum];


/**
 * Check if a given object implements the PurchasingPurchaseOrderEdit interface.
 */
export function instanceOfPurchasingPurchaseOrderEdit(value: object): value is PurchasingPurchaseOrderEdit {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('deliveryAddress' in value) || value['deliveryAddress'] === undefined) return false;
    if (!('businessPartnerId' in value) || value['businessPartnerId'] === undefined) return false;
    if (!('plannedDate' in value) || value['plannedDate'] === undefined) return false;
    if (!('lines' in value) || value['lines'] === undefined) return false;
    return true;
}

export function PurchasingPurchaseOrderEditFromJSON(json: any): PurchasingPurchaseOrderEdit {
    return PurchasingPurchaseOrderEditFromJSONTyped(json, false);
}

export function PurchasingPurchaseOrderEditFromJSONTyped(json: any, ignoreDiscriminator: boolean): PurchasingPurchaseOrderEdit {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'deliveryAddress': json['deliveryAddress'],
        'businessPartnerId': json['businessPartnerId'],
        'plannedDate': (new Date(json['plannedDate'])),
        'comment': json['comment'] == null ? undefined : json['comment'],
        'confirmationDate': json['confirmationDate'] == null ? undefined : (new Date(json['confirmationDate'])),
        'lines': ((json['lines'] as Array<any>).map(PurchasingPurchaseOrderLineFromJSON)),
    };
}

  export function PurchasingPurchaseOrderEditToJSON(json: any): PurchasingPurchaseOrderEdit {
      return PurchasingPurchaseOrderEditToJSONTyped(json, false);
  }

  export function PurchasingPurchaseOrderEditToJSONTyped(value?: PurchasingPurchaseOrderEdit | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'deliveryAddress': value['deliveryAddress'],
        'businessPartnerId': value['businessPartnerId'],
        'plannedDate': ((value['plannedDate']).toISOString()),
        'comment': value['comment'],
        'confirmationDate': value['confirmationDate'] == null ? undefined : ((value['confirmationDate'] as any).toISOString()),
        'lines': ((value['lines'] as Array<any>).map(PurchasingPurchaseOrderLineToJSON)),
    };
}

