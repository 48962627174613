import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import DeleteIcon from '@mui/icons-material/Delete';
import DoneIcon from '@mui/icons-material/Done';
import EditIcon from '@mui/icons-material/Edit';
import { Button } from '@mui/material';
import React, { useContext } from 'react';
import { SimpleFormIteratorItemContext, useSourceContext } from 'react-admin';
import { useWatch } from 'react-hook-form';
import styles from './formIteratorIterm.module.scss';

export interface ShowProps<T> {
  record: T;
  index?: number;
}

interface FormIteratorItemProps<T> {
  Show: React.ComponentType<ShowProps<T>>,
  Edit: React.ComponentType<ShowProps<T | undefined>>,
  currentActive: number | undefined,
  setCurrentActive: (index: number | undefined) => void
}

export function FormIteratorItem<T>(props: FormIteratorItemProps<T>) {
  const {
    Show,
    Edit,
    currentActive,
    setCurrentActive
  } = props;

  const itemContext = useContext(SimpleFormIteratorItemContext);
  const src = useSourceContext();
  //@ts-ignore
  const record: T | undefined = useWatch<T>({ name: src.getSource('') });

  const isActive = React.useMemo(
    () => itemContext?.index === currentActive,
    [currentActive, itemContext]
  );

  return <div className={styles.formIteratorItem}>
    {
      isActive ?
        <Button onClick={() => setCurrentActive(undefined)} variant='outlined'><DoneIcon /></Button> :
        <>
          <Button onClick={() => setCurrentActive(itemContext?.index)} variant='outlined'><EditIcon /></Button>
          <div className="overlay">
            <Button onClick={() => setCurrentActive(itemContext?.index)} variant='outlined'>
              <EditIcon />
            </Button>
            <Button
              variant='outlined'
              disabled={itemContext === undefined || itemContext.index <= 0}
              onClick={() => itemContext?.reOrder(itemContext.index - 1)}
            >
              <ArrowUpwardIcon />
            </Button>
            <Button
              variant='outlined'
              disabled={itemContext === undefined || itemContext.index >= itemContext.total - 1}
              onClick={() => itemContext?.reOrder(itemContext.index + 1)}
            >
              <ArrowDownwardIcon />
            </Button>
            <Button variant='outlined' onClick={() => itemContext?.remove()}>
              <DeleteIcon />
            </Button>
          </div>
        </>
    }

    {
      isActive ?
        <Edit record={record} index={itemContext?.index} /> :
        record ?
          <Show record={record} index={itemContext?.index} />
          : null
    }
  </div >

}