import { Button } from '@mui/material';
import React from 'react';
import {
  SimpleFormIterator,
  SimpleFormIteratorProps,
  useArrayInput,
  useSimpleFormIterator,
} from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { FormIteratorItem, ShowProps } from './formIteratorItem';

interface FormIteratorProps<T> extends SimpleFormIteratorProps {
  Show: React.ComponentType<ShowProps<T>>,
  Edit: React.ComponentType<ShowProps<T | undefined>>,
  defaultValue: { [K in keyof T]: T[K] | null }
}

interface AddButtonProps<T> {
  setCurrentActive: (index: number | undefined) => void
  defaultValue: { [K in keyof T]: T[K] | null }
}

function AddButton<T>(props: AddButtonProps<T>) {
  const {
    defaultValue,
    setCurrentActive,
  } = props;
  const { append } = useArrayInput();
  const ctx = useSimpleFormIterator();
  const { resetField } = useFormContext();

  const add = React.useCallback(() => {
    append(defaultValue);
    // Make sure the newly added inputs are not considered dirty by react-hook-form
    // resetField(`${finalSource}.${fields.length}`, { defaultValue });
    resetField(`${ctx.source}.${ctx.total}`, { defaultValue });
    setCurrentActive(ctx.total);
  }, [ctx]);
  return <Button onClick={add}>Add</Button>;
}

export function FormIterator<T>(props: FormIteratorProps<T>) {
  const {
    Show,
    Edit,
    defaultValue,
  } = props;

  const [currentActive, setCurrentActive] = React.useState<number | undefined>();

  return (

    <SimpleFormIterator
      inline
      fullWidth
      disableRemove
      disableReordering
      addButton={<AddButton<T> defaultValue={defaultValue} setCurrentActive={setCurrentActive} />}
    >
      <FormIteratorItem Show={Show} Edit={Edit} currentActive={currentActive} setCurrentActive={setCurrentActive} />
    </SimpleFormIterator>
  );
}
