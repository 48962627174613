import { useQueryClient } from '@tanstack/react-query';
import { AuthUserEntity } from 'api';
import React from 'react';
import { Button, useRecordContext } from 'react-admin';
import { useNavigate } from 'react-router';
import { useAppState } from 'repositories/app';
import { eraseCookie, getCookie, setCookie } from 'utils/cookie';

interface HijackButtonProps {
  label: string;
}
const cookieName = 'hijack-user';

export function useHijack() {
  const queryClient = useQueryClient();
  const hijackUsername = getCookie(cookieName);
  const navigate = useNavigate();

  const resetData = () => {
    localStorage.removeItem('identity');
    queryClient.invalidateQueries();
    navigate('/');
    // window.location.reload();
  };

  return {
    isActive: !!hijackUsername,
    activate: (username: string) => {
      setCookie(cookieName, username);
      resetData();
    },
    reset: () => {
      eraseCookie(cookieName);
      resetData();
    },
  };
}

export function HijackButton(props: HijackButtonProps) {
  const {
    label,
  } = props;
  const record = useRecordContext<AuthUserEntity>();
  const { data } = useAppState();
  const hijack = useHijack();
  const appState = data?.appState;

  const hijackUser = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();

    if (record) {
      hijack.activate(record.username);
    }
  };
  return <Button onClick={hijackUser} label={label} disabled={!appState?.user.isAdmin} />;
}
