/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: smn@advance.ch
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SystemTupleInt64Decimal
 */
export interface SystemTupleInt64Decimal {
    /**
     * 
     * @type {number}
     * @memberof SystemTupleInt64Decimal
     */
    item1: number;
    /**
     * 
     * @type {number}
     * @memberof SystemTupleInt64Decimal
     */
    item2: number;
}

/**
 * Check if a given object implements the SystemTupleInt64Decimal interface.
 */
export function instanceOfSystemTupleInt64Decimal(value: object): value is SystemTupleInt64Decimal {
    if (!('item1' in value) || value['item1'] === undefined) return false;
    if (!('item2' in value) || value['item2'] === undefined) return false;
    return true;
}

export function SystemTupleInt64DecimalFromJSON(json: any): SystemTupleInt64Decimal {
    return SystemTupleInt64DecimalFromJSONTyped(json, false);
}

export function SystemTupleInt64DecimalFromJSONTyped(json: any, ignoreDiscriminator: boolean): SystemTupleInt64Decimal {
    if (json == null) {
        return json;
    }
    return {
        
        'item1': json['item1'],
        'item2': json['item2'],
    };
}

  export function SystemTupleInt64DecimalToJSON(json: any): SystemTupleInt64Decimal {
      return SystemTupleInt64DecimalToJSONTyped(json, false);
  }

  export function SystemTupleInt64DecimalToJSONTyped(value?: SystemTupleInt64Decimal | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'item1': value['item1'],
        'item2': value['item2'],
    };
}

