import ReceiptIcon from '@mui/icons-material/Receipt';
import createProductionOrder from './createProductionOrderContainer';
import editProductionOrder from './editProductionOrderContainer';
import ProductionOrderList from './list';
import showProductionOrder from './showProductionOrderContainer';

export default {
  name: 'manufacturing/production-orders',
  icon: ReceiptIcon,
  list: ProductionOrderList,
  create: createProductionOrder,
  edit: editProductionOrder,
  show: showProductionOrder,
  options: {
    label: 'Production Orders',
  },
};
