/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: smn@advance.ch
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AppUserPermissions } from './AppUserPermissions';
import {
    AppUserPermissionsFromJSON,
    AppUserPermissionsFromJSONTyped,
    AppUserPermissionsToJSON,
    AppUserPermissionsToJSONTyped,
} from './AppUserPermissions';
import type { AuthUserEntity } from './AuthUserEntity';
import {
    AuthUserEntityFromJSON,
    AuthUserEntityFromJSONTyped,
    AuthUserEntityToJSON,
    AuthUserEntityToJSONTyped,
} from './AuthUserEntity';

/**
 * 
 * @export
 * @interface AppStateEntity
 */
export interface AppStateEntity {
    /**
     * 
     * @type {AuthUserEntity}
     * @memberof AppStateEntity
     */
    user: AuthUserEntity;
    /**
     * 
     * @type {Array<AppUserPermissions>}
     * @memberof AppStateEntity
     */
    permissions: Array<AppUserPermissions>;
}

/**
 * Check if a given object implements the AppStateEntity interface.
 */
export function instanceOfAppStateEntity(value: object): value is AppStateEntity {
    if (!('user' in value) || value['user'] === undefined) return false;
    if (!('permissions' in value) || value['permissions'] === undefined) return false;
    return true;
}

export function AppStateEntityFromJSON(json: any): AppStateEntity {
    return AppStateEntityFromJSONTyped(json, false);
}

export function AppStateEntityFromJSONTyped(json: any, ignoreDiscriminator: boolean): AppStateEntity {
    if (json == null) {
        return json;
    }
    return {
        
        'user': AuthUserEntityFromJSON(json['user']),
        'permissions': ((json['permissions'] as Array<any>).map(AppUserPermissionsFromJSON)),
    };
}

  export function AppStateEntityToJSON(json: any): AppStateEntity {
      return AppStateEntityToJSONTyped(json, false);
  }

  export function AppStateEntityToJSONTyped(value?: AppStateEntity | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'user': AuthUserEntityToJSON(value['user']),
        'permissions': ((value['permissions'] as Array<any>).map(AppUserPermissionsToJSON)),
    };
}

