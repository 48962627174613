/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: smn@advance.ch
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { StockInventoryControlLineSchema } from './StockInventoryControlLineSchema';
import {
    StockInventoryControlLineSchemaFromJSON,
    StockInventoryControlLineSchemaFromJSONTyped,
    StockInventoryControlLineSchemaToJSON,
    StockInventoryControlLineSchemaToJSONTyped,
} from './StockInventoryControlLineSchema';
import type { StockInventoryControlMoveLineSchema } from './StockInventoryControlMoveLineSchema';
import {
    StockInventoryControlMoveLineSchemaFromJSON,
    StockInventoryControlMoveLineSchemaFromJSONTyped,
    StockInventoryControlMoveLineSchemaToJSON,
    StockInventoryControlMoveLineSchemaToJSONTyped,
} from './StockInventoryControlMoveLineSchema';

/**
 * 
 * @export
 * @interface StockInventoryControlDetail
 */
export interface StockInventoryControlDetail {
    /**
     * 
     * @type {number}
     * @memberof StockInventoryControlDetail
     */
    id: number;
    /**
     * 
     * @type {Date}
     * @memberof StockInventoryControlDetail
     */
    plannedDate?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof StockInventoryControlDetail
     */
    finishedDate?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof StockInventoryControlDetail
     */
    applyDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof StockInventoryControlDetail
     */
    comment?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StockInventoryControlDetail
     */
    stockMoveInFromName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StockInventoryControlDetail
     */
    stockMoveInToName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StockInventoryControlDetail
     */
    stockMoveOutFromName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StockInventoryControlDetail
     */
    stockMoveOutToName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StockInventoryControlDetail
     */
    stockLocationName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof StockInventoryControlDetail
     */
    stockLocationId: number;
    /**
     * 
     * @type {boolean}
     * @memberof StockInventoryControlDetail
     */
    isDisableStockLocation: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StockInventoryControlDetail
     */
    partialInventory: boolean;
    /**
     * 
     * @type {Array<StockInventoryControlLineSchema>}
     * @memberof StockInventoryControlDetail
     */
    incoming?: Array<StockInventoryControlLineSchema> | null;
    /**
     * 
     * @type {Array<StockInventoryControlLineSchema>}
     * @memberof StockInventoryControlDetail
     */
    leaving?: Array<StockInventoryControlLineSchema> | null;
    /**
     * 
     * @type {Array<StockInventoryControlMoveLineSchema>}
     * @memberof StockInventoryControlDetail
     */
    stockMoveIn?: Array<StockInventoryControlMoveLineSchema> | null;
    /**
     * 
     * @type {Array<StockInventoryControlMoveLineSchema>}
     * @memberof StockInventoryControlDetail
     */
    stockMoveOut?: Array<StockInventoryControlMoveLineSchema> | null;
}

/**
 * Check if a given object implements the StockInventoryControlDetail interface.
 */
export function instanceOfStockInventoryControlDetail(value: object): value is StockInventoryControlDetail {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('stockLocationId' in value) || value['stockLocationId'] === undefined) return false;
    if (!('isDisableStockLocation' in value) || value['isDisableStockLocation'] === undefined) return false;
    if (!('partialInventory' in value) || value['partialInventory'] === undefined) return false;
    return true;
}

export function StockInventoryControlDetailFromJSON(json: any): StockInventoryControlDetail {
    return StockInventoryControlDetailFromJSONTyped(json, false);
}

export function StockInventoryControlDetailFromJSONTyped(json: any, ignoreDiscriminator: boolean): StockInventoryControlDetail {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'plannedDate': json['plannedDate'] == null ? undefined : (new Date(json['plannedDate'])),
        'finishedDate': json['finishedDate'] == null ? undefined : (new Date(json['finishedDate'])),
        'applyDate': json['applyDate'] == null ? undefined : (new Date(json['applyDate'])),
        'comment': json['comment'] == null ? undefined : json['comment'],
        'stockMoveInFromName': json['stockMoveInFromName'] == null ? undefined : json['stockMoveInFromName'],
        'stockMoveInToName': json['stockMoveInToName'] == null ? undefined : json['stockMoveInToName'],
        'stockMoveOutFromName': json['stockMoveOutFromName'] == null ? undefined : json['stockMoveOutFromName'],
        'stockMoveOutToName': json['stockMoveOutToName'] == null ? undefined : json['stockMoveOutToName'],
        'stockLocationName': json['stockLocationName'] == null ? undefined : json['stockLocationName'],
        'stockLocationId': json['stockLocationId'],
        'isDisableStockLocation': json['isDisableStockLocation'],
        'partialInventory': json['partialInventory'],
        'incoming': json['incoming'] == null ? undefined : ((json['incoming'] as Array<any>).map(StockInventoryControlLineSchemaFromJSON)),
        'leaving': json['leaving'] == null ? undefined : ((json['leaving'] as Array<any>).map(StockInventoryControlLineSchemaFromJSON)),
        'stockMoveIn': json['stockMoveIn'] == null ? undefined : ((json['stockMoveIn'] as Array<any>).map(StockInventoryControlMoveLineSchemaFromJSON)),
        'stockMoveOut': json['stockMoveOut'] == null ? undefined : ((json['stockMoveOut'] as Array<any>).map(StockInventoryControlMoveLineSchemaFromJSON)),
    };
}

  export function StockInventoryControlDetailToJSON(json: any): StockInventoryControlDetail {
      return StockInventoryControlDetailToJSONTyped(json, false);
  }

  export function StockInventoryControlDetailToJSONTyped(value?: StockInventoryControlDetail | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'plannedDate': value['plannedDate'] == null ? undefined : ((value['plannedDate'] as any).toISOString()),
        'finishedDate': value['finishedDate'] == null ? undefined : ((value['finishedDate'] as any).toISOString()),
        'applyDate': value['applyDate'] == null ? undefined : ((value['applyDate'] as any).toISOString()),
        'comment': value['comment'],
        'stockMoveInFromName': value['stockMoveInFromName'],
        'stockMoveInToName': value['stockMoveInToName'],
        'stockMoveOutFromName': value['stockMoveOutFromName'],
        'stockMoveOutToName': value['stockMoveOutToName'],
        'stockLocationName': value['stockLocationName'],
        'stockLocationId': value['stockLocationId'],
        'isDisableStockLocation': value['isDisableStockLocation'],
        'partialInventory': value['partialInventory'],
        'incoming': value['incoming'] == null ? undefined : ((value['incoming'] as Array<any>).map(StockInventoryControlLineSchemaToJSON)),
        'leaving': value['leaving'] == null ? undefined : ((value['leaving'] as Array<any>).map(StockInventoryControlLineSchemaToJSON)),
        'stockMoveIn': value['stockMoveIn'] == null ? undefined : ((value['stockMoveIn'] as Array<any>).map(StockInventoryControlMoveLineSchemaToJSON)),
        'stockMoveOut': value['stockMoveOut'] == null ? undefined : ((value['stockMoveOut'] as Array<any>).map(StockInventoryControlMoveLineSchemaToJSON)),
    };
}

