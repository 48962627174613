import { ProductProductionOrderDetail, ShipmentExportDetailSchema } from 'api';
import List from 'components/list/List';
import Datagrid from 'components/list/dataGrid';
import * as React from 'react';
import { TextField } from 'react-admin';
import { Source } from 'utils/source';
import filters from './filters';

export default function () {
  const s = Source<ProductProductionOrderDetail>();
  return (
    <List
      filters={filters}
    >
      <Datagrid rowClick="show">
        <TextField {...s('name')} />
        <TextField {...s('businessPartner.name')} sortBy="businessPartnerName" />
        <TextField {...s('orderDate')} />
        <TextField {...s('comment')} />
      </Datagrid>
    </List>
  );
}
