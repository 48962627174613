import React from 'react';
import { Source } from 'utils/source';

import { TextInput } from 'react-admin';
import { ExportShipmentSearchRequest } from 'api';
import { SelectBusinessPartners } from 'resources/product/rawMaterials/filters';

const filterQuery = Source<ExportShipmentSearchRequest>();

export default [
  <TextInput source="query" alwaysOn />,
  <SelectBusinessPartners label="Customer" {...filterQuery('customerId')} />,
];
