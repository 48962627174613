/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: smn@advance.ch
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ShipmentExportLineSchema
 */
export interface ShipmentExportLineSchema {
    /**
     * 
     * @type {number}
     * @memberof ShipmentExportLineSchema
     */
    itemId: number;
    /**
     * 
     * @type {number}
     * @memberof ShipmentExportLineSchema
     */
    amount: number;
}

/**
 * Check if a given object implements the ShipmentExportLineSchema interface.
 */
export function instanceOfShipmentExportLineSchema(value: object): value is ShipmentExportLineSchema {
    if (!('itemId' in value) || value['itemId'] === undefined) return false;
    if (!('amount' in value) || value['amount'] === undefined) return false;
    return true;
}

export function ShipmentExportLineSchemaFromJSON(json: any): ShipmentExportLineSchema {
    return ShipmentExportLineSchemaFromJSONTyped(json, false);
}

export function ShipmentExportLineSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShipmentExportLineSchema {
    if (json == null) {
        return json;
    }
    return {
        
        'itemId': json['itemId'],
        'amount': json['amount'],
    };
}

  export function ShipmentExportLineSchemaToJSON(json: any): ShipmentExportLineSchema {
      return ShipmentExportLineSchemaToJSONTyped(json, false);
  }

  export function ShipmentExportLineSchemaToJSONTyped(value?: ShipmentExportLineSchema | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'itemId': value['itemId'],
        'amount': value['amount'],
    };
}

