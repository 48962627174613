/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: smn@advance.ch
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ProductProductionOrderLineDetail } from './ProductProductionOrderLineDetail';
import {
    ProductProductionOrderLineDetailFromJSON,
    ProductProductionOrderLineDetailFromJSONTyped,
    ProductProductionOrderLineDetailToJSON,
    ProductProductionOrderLineDetailToJSONTyped,
} from './ProductProductionOrderLineDetail';
import type { BusinessPartnerCreate } from './BusinessPartnerCreate';
import {
    BusinessPartnerCreateFromJSON,
    BusinessPartnerCreateFromJSONTyped,
    BusinessPartnerCreateToJSON,
    BusinessPartnerCreateToJSONTyped,
} from './BusinessPartnerCreate';

/**
 * 
 * @export
 * @interface ProductProductionOrderDetail
 */
export interface ProductProductionOrderDetail {
    /**
     * 
     * @type {number}
     * @memberof ProductProductionOrderDetail
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ProductProductionOrderDetail
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProductProductionOrderDetail
     */
    businessPartnerId: number;
    /**
     * 
     * @type {Date}
     * @memberof ProductProductionOrderDetail
     */
    orderDate: Date;
    /**
     * 
     * @type {string}
     * @memberof ProductProductionOrderDetail
     */
    comment?: string | null;
    /**
     * 
     * @type {BusinessPartnerCreate}
     * @memberof ProductProductionOrderDetail
     */
    businessPartner: BusinessPartnerCreate;
    /**
     * 
     * @type {Array<ProductProductionOrderLineDetail>}
     * @memberof ProductProductionOrderDetail
     */
    productionOrderLines?: Array<ProductProductionOrderLineDetail> | null;
}

/**
 * Check if a given object implements the ProductProductionOrderDetail interface.
 */
export function instanceOfProductProductionOrderDetail(value: object): value is ProductProductionOrderDetail {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('businessPartnerId' in value) || value['businessPartnerId'] === undefined) return false;
    if (!('orderDate' in value) || value['orderDate'] === undefined) return false;
    if (!('businessPartner' in value) || value['businessPartner'] === undefined) return false;
    return true;
}

export function ProductProductionOrderDetailFromJSON(json: any): ProductProductionOrderDetail {
    return ProductProductionOrderDetailFromJSONTyped(json, false);
}

export function ProductProductionOrderDetailFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductProductionOrderDetail {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'] == null ? undefined : json['name'],
        'businessPartnerId': json['businessPartnerId'],
        'orderDate': (new Date(json['orderDate'])),
        'comment': json['comment'] == null ? undefined : json['comment'],
        'businessPartner': BusinessPartnerCreateFromJSON(json['businessPartner']),
        'productionOrderLines': json['productionOrderLines'] == null ? undefined : ((json['productionOrderLines'] as Array<any>).map(ProductProductionOrderLineDetailFromJSON)),
    };
}

  export function ProductProductionOrderDetailToJSON(json: any): ProductProductionOrderDetail {
      return ProductProductionOrderDetailToJSONTyped(json, false);
  }

  export function ProductProductionOrderDetailToJSONTyped(value?: ProductProductionOrderDetail | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'name': value['name'],
        'businessPartnerId': value['businessPartnerId'],
        'orderDate': ((value['orderDate']).toISOString().substring(0,10)),
        'comment': value['comment'],
        'businessPartner': BusinessPartnerCreateToJSON(value['businessPartner']),
        'productionOrderLines': value['productionOrderLines'] == null ? undefined : ((value['productionOrderLines'] as Array<any>).map(ProductProductionOrderLineDetailToJSON)),
    };
}

