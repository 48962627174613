import {
  Grid, Table, TableBody, TableCell, TableHead, TableRow,
} from '@mui/material';
import { ShipmentExportDetailSchema } from 'api';
import urls from 'app/urls';
import ShowLabel from 'components/commons/showLabel';
import ShowTitle from 'components/commons/showTitle';
import EnumField from 'components/fields/enumField';
import FileView from 'components/fields/file/FileView';
import ShowActions from 'components/layout/actions/show';
import * as React from 'react';
import {
  DateField,
  Show,
  TabbedShowLayout,
  TextField,
  useRecordContext,
  useTranslate,
} from 'react-admin';
import { incomingShipmentTransportType } from 'resources/stock/incomingShipment/enum';
import formatString from 'utils/formatString';
import { Source } from 'utils/source';

function ExportShipmentTable() {
  const translate = useTranslate();
  const record = useRecordContext<ShipmentExportDetailSchema>();
  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>#</TableCell>
          <TableCell>{translate('resources.shipment/export_shipment_box.length')}</TableCell>
          <TableCell>{translate('resources.shipment/export_shipment_box.width')}</TableCell>
          <TableCell>{translate('resources.shipment/export_shipment_box.height')}</TableCell>
          <TableCell>{translate('resources.shipment/export_shipment_box.volume')}</TableCell>
          <TableCell>{translate('resources.shipment/export_shipment_box.weight')}</TableCell>
          <TableCell>{translate('resources.shipment/export_shipment_box.comment')}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {record?.boxes?.map((row, index: number) => (
          <TableRow key={row.id}>
            <TableCell>{index + 1}</TableCell>
            <TableCell>{row?.length}</TableCell>
            <TableCell>{row?.height}</TableCell>
            <TableCell>{row?.width}</TableCell>
            <TableCell>{row?.volume}</TableCell>
            <TableCell>{row?.weight}</TableCell>
            <TableCell>{row?.comment}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
function UploadFile() {
  const record = useRecordContext<ShipmentExportDetailSchema>();
  return (
    <div>
      {record?.id && (
        <FileView
          endpoint={formatString(urls.ExportShipmentFile_GetFiles.url, {
            id: record?.id?.toString() as string,
          })}
          getUrl={urls.ExportShipmentFile_GetFiles}
          uploadUrl={urls.ExportShipmentFile_UploadFile}
        />
      )}
    </div>
  );
}
export default function () {
  const s = Source<ShipmentExportDetailSchema>();

  return (
    <Show actions={<ShowActions />}>
      <TabbedShowLayout>
        <TabbedShowLayout.Tab label="Summary">
          <Grid
            container
            rowSpacing={1}
            spacing={1}
            sx={{ marginLeft: '3px', marginTop: '3px', marginBottom: '10px' }}
          >
            <Grid item xs={12} md={12}>
              <ShowTitle />
            </Grid>
            <Grid item xs={4}>
              <ShowLabel label="resources.shipment/export_shipment.name" />
              <TextField {...s('name')} />
            </Grid>
            <Grid item xs={4}>
              <ShowLabel label="resources.stock/incomingShipment.fields.transportType" />
              <EnumField {...s('transportType')} choices={incomingShipmentTransportType} />
            </Grid>
            <Grid item xs={2} />
            <Grid item xs={4}>
              <ShowLabel label="resources.shipment/export_shipment.actualDate" />
              <DateField {...s('actualDate')} />
            </Grid>
            <Grid item xs={4}>
              <ShowLabel label="resources.stock/stock-moves.fields.plannedDate" />
              <DateField {...s('plannedDate')} />
            </Grid>
            <Grid item xs={2} />
            <Grid item xs={4}>
              <ShowLabel label="resources.business-partner/business-partners.name" />
              <TextField {...s('customer.name')} />
            </Grid>
            <Grid item xs={4}>
              <ShowLabel label="resources.stock/incomingShipment.fields.comment" />
              <TextField {...s('comment')} />
            </Grid>
          </Grid>
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="Boxes">
          <ExportShipmentTable />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="Files">
          <UploadFile />
        </TabbedShowLayout.Tab>
      </TabbedShowLayout>
    </Show>
  );
}
