/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: smn@advance.ch
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ProductProductionItemDetailSchema } from './ProductProductionItemDetailSchema';
import {
    ProductProductionItemDetailSchemaFromJSON,
    ProductProductionItemDetailSchemaFromJSONTyped,
    ProductProductionItemDetailSchemaToJSON,
    ProductProductionItemDetailSchemaToJSONTyped,
} from './ProductProductionItemDetailSchema';

/**
 * 
 * @export
 * @interface ProductProductionOrderLineSchema
 */
export interface ProductProductionOrderLineSchema {
    /**
     * 
     * @type {number}
     * @memberof ProductProductionOrderLineSchema
     */
    productId: number;
    /**
     * 
     * @type {number}
     * @memberof ProductProductionOrderLineSchema
     */
    productionOrderId: number;
    /**
     * 
     * @type {number}
     * @memberof ProductProductionOrderLineSchema
     */
    amount: number;
    /**
     * 
     * @type {Date}
     * @memberof ProductProductionOrderLineSchema
     */
    requestDate?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof ProductProductionOrderLineSchema
     */
    plannedDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof ProductProductionOrderLineSchema
     */
    comment?: string | null;
    /**
     * 
     * @type {Array<ProductProductionItemDetailSchema>}
     * @memberof ProductProductionOrderLineSchema
     */
    productionItems?: Array<ProductProductionItemDetailSchema> | null;
}

/**
 * Check if a given object implements the ProductProductionOrderLineSchema interface.
 */
export function instanceOfProductProductionOrderLineSchema(value: object): value is ProductProductionOrderLineSchema {
    if (!('productId' in value) || value['productId'] === undefined) return false;
    if (!('productionOrderId' in value) || value['productionOrderId'] === undefined) return false;
    if (!('amount' in value) || value['amount'] === undefined) return false;
    return true;
}

export function ProductProductionOrderLineSchemaFromJSON(json: any): ProductProductionOrderLineSchema {
    return ProductProductionOrderLineSchemaFromJSONTyped(json, false);
}

export function ProductProductionOrderLineSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductProductionOrderLineSchema {
    if (json == null) {
        return json;
    }
    return {
        
        'productId': json['productId'],
        'productionOrderId': json['productionOrderId'],
        'amount': json['amount'],
        'requestDate': json['requestDate'] == null ? undefined : (new Date(json['requestDate'])),
        'plannedDate': json['plannedDate'] == null ? undefined : (new Date(json['plannedDate'])),
        'comment': json['comment'] == null ? undefined : json['comment'],
        'productionItems': json['productionItems'] == null ? undefined : ((json['productionItems'] as Array<any>).map(ProductProductionItemDetailSchemaFromJSON)),
    };
}

  export function ProductProductionOrderLineSchemaToJSON(json: any): ProductProductionOrderLineSchema {
      return ProductProductionOrderLineSchemaToJSONTyped(json, false);
  }

  export function ProductProductionOrderLineSchemaToJSONTyped(value?: ProductProductionOrderLineSchema | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'productId': value['productId'],
        'productionOrderId': value['productionOrderId'],
        'amount': value['amount'],
        'requestDate': value['requestDate'] == null ? undefined : ((value['requestDate'] as any).toISOString().substring(0,10)),
        'plannedDate': value['plannedDate'] == null ? undefined : ((value['plannedDate'] as any).toISOString().substring(0,10)),
        'comment': value['comment'],
        'productionItems': value['productionItems'] == null ? undefined : ((value['productionItems'] as Array<any>).map(ProductProductionItemDetailSchemaToJSON)),
    };
}

