import { InputAdornment } from '@mui/material';
import * as React from 'react';
import {
  TextInput,
  number,
  required,
} from 'react-admin';
import { InputAttributes, NumericFormat } from 'react-number-format';
import { useCurrencies } from 'repositories/app';

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const NumberFormatCustom = React.forwardRef<typeof NumericFormat<InputAttributes>, CustomProps>((props, ref) => {
  const { onChange, ...other } = props;
  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      valueIsNumericString
    />
  );
});

interface PriceInputProps {
  currencyId?: string,
  source: string,
  isRequire?: boolean,
  onChange?: () => void,
}

export default function PriceInput(props: PriceInputProps) {
  const {
    currencyId,
    source,
    onChange,
    isRequire = true,
  } = props;

  const { data } = useCurrencies();
  const currency = React.useMemo(() => {
    if (currencyId !== undefined) {
      return data?.find((x) => x.id === currencyId);
    }
  }, [data, currencyId]);

  let inputProps;

  if (currency?.direction === 1) {
    inputProps = {
      startAdornment: <InputAdornment position="start">{currency?.symbol ?? ''}</InputAdornment>,
      inputComponent: NumberFormatCustom as any,
    };
  } else if (currency !== undefined) {
    inputProps = {
      endAdornment: <InputAdornment position="end">{currency.symbol ?? ''}</InputAdornment>,
      inputComponent: NumberFormatCustom as any,
    };
  }

  return (
    <TextInput
      source={source}
      onChange={onChange}
      validate={isRequire ? [required(), number()] : []}
      fullWidth
      helperText={false}
      InputProps={inputProps}
    />
  );
}
