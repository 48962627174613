import { Grid } from '@mui/material';
import { PurchasingPurchaseOrderLineDetail } from 'api';
import RawMaterialChooser from 'components/chooser/rawMaterialChooser';
import CustomDatePicker from 'components/input/date/CustomDatePicker';
import { ResourceQuantityInput } from 'components/input/quantity/resourceFields';
import React from 'react';
import {
  ArrayInput, FormDataConsumer, SimpleFormIterator, TextInput,
} from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { Source } from 'utils/source';
import useTranslate from 'utils/translate';

interface ShipmentLineFormProps {
  scopedFormData: PurchasingPurchaseOrderLineDetail,
  supplierId?: number,
}

interface PurchaseOrderLinesProps {
  source: string;
  supplierIdSource: string;
}

export function POLineForm(props: ShipmentLineFormProps) {
  const {
    scopedFormData,
    supplierId,
  } = props;
  const translate = useTranslate();
  const s = Source<PurchasingPurchaseOrderLineDetail>();

  // const { data: resource } = useResourceQuery(scopedFormData.resourceId || 0, { enabled:
  // !!scopedFormData.resourceId });

  return (
    <Grid container spacing={2} marginTop="10px" marginBottom="10px">
      <Grid item md={4} sm={6}>

        <RawMaterialChooser
          // focused={false}
          required
          label={translate('mai.resources')}
          // validate={[required()]}
          {...s('rawMaterialId')}
          defaultSupplier={supplierId}
          className="resourcesSelect"
        // onChange={() => onChangeHandle(scopedFormData ?? '0')}
        />
      </Grid>
      <Grid item md={4} sm={6}>
        <ResourceQuantityInput
          className="amount"
          required
          label={translate('mai.amount')}
          {...s('amount')}
          resourceId={scopedFormData?.rawMaterialId}
          resourceType="RawMaterial"
        />
      </Grid>
      <Grid item md={4} sm={12}>

        <CustomDatePicker
          {...s('confirmedDeliveryDate')}
          fullWidth
          variant="outlined"
          helperText={false}
        />
      </Grid>

      <Grid item md={12}>
        <TextInput
          label={translate('mai.comment')}
          {...s('comment')}
          fullWidth
          variant="outlined"
          helperText={false}
          className="comment"
        />

      </Grid>

    </Grid>
  );
}

export function PurchaseOrderLines(props: PurchaseOrderLinesProps) {
  const {
    source,
    supplierIdSource,
  } = props;

  const formData = useFormContext();

  const supplierId = supplierIdSource ? formData.watch(supplierIdSource) : undefined;

  console.log('supplierid', supplierId);

  return (
    <ArrayInput source={source}>
      <SimpleFormIterator className="form" disableClear>
        <FormDataConsumer>
          {({ scopedFormData }) => (
            <POLineForm
              // @ts-ignore
              scopedFormData={scopedFormData}
              supplierId={supplierId}
            />
          )}
        </FormDataConsumer>
      </SimpleFormIterator>
    </ArrayInput>
  );
}
