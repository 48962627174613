import ProductionItemField from 'components/fields/productionField';
import React from 'react';
import {
  Identifier,
  useInput,
} from 'react-admin';

interface ProductionItemInputProps {
  source: string;
  defaultValue?: Identifier;
  required?: boolean;
}

export default function ProductionItemInput(props: ProductionItemInputProps) {
  const {
    source,
    defaultValue,
    required,
  } = props;

  const field = useInput<number>({
    source,
    defaultValue,
    isRequired: required,
  });

  return (
    <ProductionItemField
      onChange={field.field.onChange}
      value={field.field.value || null}
    />
  );
}
