/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: smn@advance.ch
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ProductProductionOrderLineSchema } from './ProductProductionOrderLineSchema';
import {
    ProductProductionOrderLineSchemaFromJSON,
    ProductProductionOrderLineSchemaFromJSONTyped,
    ProductProductionOrderLineSchemaToJSON,
    ProductProductionOrderLineSchemaToJSONTyped,
} from './ProductProductionOrderLineSchema';

/**
 * 
 * @export
 * @interface ProductProductionOrderSchema
 */
export interface ProductProductionOrderSchema {
    /**
     * 
     * @type {string}
     * @memberof ProductProductionOrderSchema
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProductProductionOrderSchema
     */
    businessPartnerId: number;
    /**
     * 
     * @type {Date}
     * @memberof ProductProductionOrderSchema
     */
    orderDate: Date;
    /**
     * 
     * @type {string}
     * @memberof ProductProductionOrderSchema
     */
    comment?: string | null;
    /**
     * 
     * @type {Array<ProductProductionOrderLineSchema>}
     * @memberof ProductProductionOrderSchema
     */
    productionOrderLines?: Array<ProductProductionOrderLineSchema> | null;
}

/**
 * Check if a given object implements the ProductProductionOrderSchema interface.
 */
export function instanceOfProductProductionOrderSchema(value: object): value is ProductProductionOrderSchema {
    if (!('businessPartnerId' in value) || value['businessPartnerId'] === undefined) return false;
    if (!('orderDate' in value) || value['orderDate'] === undefined) return false;
    return true;
}

export function ProductProductionOrderSchemaFromJSON(json: any): ProductProductionOrderSchema {
    return ProductProductionOrderSchemaFromJSONTyped(json, false);
}

export function ProductProductionOrderSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductProductionOrderSchema {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'] == null ? undefined : json['name'],
        'businessPartnerId': json['businessPartnerId'],
        'orderDate': (new Date(json['orderDate'])),
        'comment': json['comment'] == null ? undefined : json['comment'],
        'productionOrderLines': json['productionOrderLines'] == null ? undefined : ((json['productionOrderLines'] as Array<any>).map(ProductProductionOrderLineSchemaFromJSON)),
    };
}

  export function ProductProductionOrderSchemaToJSON(json: any): ProductProductionOrderSchema {
      return ProductProductionOrderSchemaToJSONTyped(json, false);
  }

  export function ProductProductionOrderSchemaToJSONTyped(value?: ProductProductionOrderSchema | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'name': value['name'],
        'businessPartnerId': value['businessPartnerId'],
        'orderDate': ((value['orderDate']).toISOString().substring(0,10)),
        'comment': value['comment'],
        'productionOrderLines': value['productionOrderLines'] == null ? undefined : ((value['productionOrderLines'] as Array<any>).map(ProductProductionOrderLineSchemaToJSON)),
    };
}

