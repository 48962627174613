import { deepmerge } from '@mui/utils';
import { defaultDarkTheme, defaultLightTheme } from 'react-admin';

export const customTheme = {
  components: {
    MuiTextInput: {
      defaultProps: {
        variant: 'outlined' as const,
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'outlined' as const,
      },
    },
    MuiFormControl: {
      defaultProps: {
        variant: 'outlined' as const,
      },
    },
  },
};

export const lightTheme = deepmerge(defaultLightTheme, customTheme);
export const darkTheme = deepmerge(defaultDarkTheme, customTheme);