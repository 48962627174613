/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: smn@advance.ch
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AuthUserInfo
 */
export interface AuthUserInfo {
    /**
     * 
     * @type {string}
     * @memberof AuthUserInfo
     */
    phone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuthUserInfo
     */
    firstname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuthUserInfo
     */
    lastname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuthUserInfo
     */
    gender: AuthUserInfoGenderEnum;
    /**
     * 
     * @type {Date}
     * @memberof AuthUserInfo
     */
    birthday?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof AuthUserInfo
     */
    address?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuthUserInfo
     */
    note?: string | null;
}


/**
 * @export
 */
export const AuthUserInfoGenderEnum = {
    Male: 'Male',
    Female: 'Female',
    Other: 'Other'
} as const;
export type AuthUserInfoGenderEnum = typeof AuthUserInfoGenderEnum[keyof typeof AuthUserInfoGenderEnum];


/**
 * Check if a given object implements the AuthUserInfo interface.
 */
export function instanceOfAuthUserInfo(value: object): value is AuthUserInfo {
    if (!('gender' in value) || value['gender'] === undefined) return false;
    return true;
}

export function AuthUserInfoFromJSON(json: any): AuthUserInfo {
    return AuthUserInfoFromJSONTyped(json, false);
}

export function AuthUserInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuthUserInfo {
    if (json == null) {
        return json;
    }
    return {
        
        'phone': json['phone'] == null ? undefined : json['phone'],
        'firstname': json['firstname'] == null ? undefined : json['firstname'],
        'lastname': json['lastname'] == null ? undefined : json['lastname'],
        'gender': json['gender'],
        'birthday': json['birthday'] == null ? undefined : (new Date(json['birthday'])),
        'address': json['address'] == null ? undefined : json['address'],
        'note': json['note'] == null ? undefined : json['note'],
    };
}

  export function AuthUserInfoToJSON(json: any): AuthUserInfo {
      return AuthUserInfoToJSONTyped(json, false);
  }

  export function AuthUserInfoToJSONTyped(value?: AuthUserInfo | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'phone': value['phone'],
        'firstname': value['firstname'],
        'lastname': value['lastname'],
        'gender': value['gender'],
        'birthday': value['birthday'] == null ? undefined : ((value['birthday'] as any).toISOString().substring(0,10)),
        'address': value['address'],
        'note': value['note'],
    };
}

