import { Grid } from '@mui/material';
import ShowField from 'components/commons/showField';
import ShowLabel from 'components/commons/showLabel';
import ShowTitle from 'components/commons/showTitle';
import ShowActions from 'components/layout/actions/show';
import * as React from 'react';
import {
  ImageField,
  Show,
  SimpleShowLayout,
} from 'react-admin';

export default function () {
  return (
    <Show actions={<ShowActions />}>
      <SimpleShowLayout>
        <Grid container rowSpacing={1} spacing={1} sx={{ marginLeft: '3px', marginTop: '3px', marginBottom: '10px' }}>
          <Grid item xs={12} md={12}>
            <ShowTitle />
          </Grid>
          <Grid className="show-row-odd" item xs={2} md={1.5} sx={{ borderBottom: '1' }}>
            <ShowLabel label="resources.stock/stockLocations/shelf.fields.name" />
          </Grid>
          <Grid className="show-row-odd" item xs={8} md={9}>
            <ShowField source="name" />
          </Grid>
          <Grid className="show-row-odd" item xs={2} md={1.5} />
          <Grid item xs={2} md={1.5}>
            <ShowLabel label="resources.stock/stockLocations/shelf.fields.stockLocationName" />
          </Grid>
          <Grid item xs={8} md={9}>
            <ShowField source="stockLocationName" />
          </Grid>
          <Grid item xs={2} md={1.5} />
          <Grid className="show-row-odd" item xs={2} md={1.5}>
            <ShowLabel label="resources.stock/stockLocations/shelf.fields.comment" />
          </Grid>
          <Grid className="show-row-odd" item xs={8} md={9}>
            <ShowField source="comment" />
          </Grid>
          <Grid item xs={2} md={1.5} />
          <Grid className="show-row-odd" item xs={2} md={1.5}>
            <ShowLabel label="resources.stock/stockLocations/shelf.fields.picture" />
          </Grid>
          <Grid className="show-row-odd" item xs={8} md={9}>
            <ImageField source="image" />
          </Grid>
        </Grid>
      </SimpleShowLayout>
    </Show>
  );
}
