import {
  Grid, Table, TableBody, TableCell, TableHead, TableRow,
} from '@mui/material';
import { ProductProductionOrderDetail } from 'api';
import ShowLabel from 'components/commons/showLabel';
import ShowTitle from 'components/commons/showTitle';
import ShowActions from 'components/layout/actions/show';
import * as React from 'react';
import {
  DateField,
  Show,
  TabbedShowLayout,
  TextField,
  useRecordContext,
  useTranslate,
} from 'react-admin';
import { Source } from 'utils/source';

function ProductionOrderTable() {
  const translate = useTranslate();
  const record = useRecordContext<ProductProductionOrderDetail>();
  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>#</TableCell>
          <TableCell>{translate('resources.product/products.fields.name')}</TableCell>
          <TableCell>{translate('resources.product/productionItems.fields.amount')}</TableCell>
          <TableCell>{translate('resources.product/production_order.requestDate')}</TableCell>
          <TableCell>{translate('resources.product/production_order.plannedDate')}</TableCell>
          <TableCell>{translate('resources.product/production_order.comment')}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {record?.productionOrderLines?.map((row, index: number) => (
          <TableRow key={row.id}>
            <TableCell>{index + 1}</TableCell>
            <TableCell>{row?.product.name}</TableCell>
            <TableCell>{row?.amount}</TableCell>
            <TableCell>
              <DateField source="requestDate" />
            </TableCell>
            <TableCell>
              <DateField source="plannedDate" />
            </TableCell>
            <TableCell>{row?.comment}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
export default function () {
  const s = Source<ProductProductionOrderDetail>();

  return (
    <Show actions={<ShowActions />}>
      <TabbedShowLayout>
        <TabbedShowLayout.Tab label="Summary">
          <Grid
            container
            rowSpacing={1}
            spacing={1}
            sx={{ marginLeft: '3px', marginTop: '3px', marginBottom: '10px' }}
          >
            <Grid item xs={12} md={12}>
              <ShowTitle />
            </Grid>
            <Grid item xs={4}>
              <ShowLabel label="resources.product/production_order.name" />
              <TextField {...s('name')} />
            </Grid>
            <Grid item xs={4}>
              <ShowLabel label="resources.business-partner/business-partners.name" />
              <TextField {...s('businessPartner.name')} />
            </Grid>
            <Grid item xs={2} />
            <Grid item xs={4}>
              <ShowLabel label="resources.product/production_order.orderDate" />
              <DateField {...s('orderDate')} />
            </Grid>
            <Grid item xs={4}>
              <ShowLabel label="resources.product/production_order.comment" />
              <TextField {...s('comment')} />
            </Grid>
            <Grid item xs={2} />
          </Grid>
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="Lines">
          <ProductionOrderTable />
        </TabbedShowLayout.Tab>
      </TabbedShowLayout>
    </Show>
  );
}
