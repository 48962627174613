import { Grid } from '@mui/material';
import { ProductResourcePriceCreateEntity, ProductsResourcePriceBulkCreateEntity } from 'api';
import * as React from 'react';

import ResourceChooser from 'components/chooser/resourceChooser';
import CustomDatePicker from 'components/input/date/CustomDatePicker';
import PriceInput from 'components/input/priceInput';
import {
  ArrayInput,
  FormDataConsumer,
  required, SaveHandler, SelectInput,
  SimpleForm, SimpleFormIterator,
  useNotify,
  useRedirect,
  useRefresh,
  useResourceContext
} from 'react-admin';
import api from 'repositories/api';
import { useCurrencies } from 'repositories/app';
import sleep from 'utils/sleep';
import { Source } from 'utils/source';

function ResourcePriceItemForm() {
  const s = Source<ProductResourcePriceCreateEntity>();
  const { data: currencies } = useCurrencies();

  return (
    <>
      <Grid container spacing={2} rowSpacing={0.1} marginTop={2}>
        <Grid item xs={2} md={1} />
        <Grid item xs={4} md={5}>
          <ResourceChooser
            {...s('resourceId')}
            required
            className="resourcesSelect"
            label="resources.billOfResources.fields.resources"
            sx={{ width: '100%' }}
          />
        </Grid>
        <Grid item xs={4} md={5}>
          <SelectInput
            {...s('currencyId')}
            choices={currencies ?? []}
            validate={[required()]}
            fullWidth
            margin="none"
            variant="outlined"
            helperText={false}
          />
        </Grid>
        <Grid item xs={2} md={1} />

        <Grid item xs={2} md={1} />
        <Grid item xs={4} md={5}>
          <FormDataConsumer sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
          >
            {({ formData }) => <PriceInput {...s('price')} currencyId={formData.currencyId} />}
          </FormDataConsumer>
        </Grid>
        <Grid item xs={4} md={5}>

        </Grid>
        <Grid item xs={2} md={1} />

        <Grid item xs={2} md={1} />
        <Grid item xs={4} md={5}>
          <CustomDatePicker
            {...s('startDate')}
            fullWidth
            validate={[required()]}
            variant="outlined"
            helperText={false}
          />
        </Grid>
        <Grid item xs={4} md={5}>
          <CustomDatePicker
            {...s('endDate')}
            fullWidth
            variant="outlined"
            helperText={false}
          />
        </Grid>
        <Grid item xs={2} md={1} />

      </Grid>
    </>
  );
}

export default function ResourcePricesForm() {
  const resource = useResourceContext();
  const refresh = useRefresh();
  const notify = useNotify();
  const redirect = useRedirect();
  const s = (source: string) => ({ source });

  const submit = async (data: ProductsResourcePriceBulkCreateEntity) => {
    await api.resourcePricesBulkCreate({
      productsResourcePriceBulkCreateEntity: data
    });
    await sleep(1000);
    notify('Create Successfully');
    redirect('list', resource);
    refresh();
  };

  return (
    <>
      <SimpleForm
        onSubmit={submit as SaveHandler<ProductsResourcePriceBulkCreateEntity>}
        defaultValues={{ resourcePrices: [{}] }}
      >
        <ArrayInput {...s('resourcePrices')}>
          <SimpleFormIterator className="form" disableClear>
            <FormDataConsumer>
              {({ scopedFormData }) => (
                <ResourcePriceItemForm
                  // @ts-ignore
                  formData={scopedFormData}
                />
              )}
            </FormDataConsumer>
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </>
  );
}
