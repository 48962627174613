/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: smn@advance.ch
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { StockLocationSchema } from './StockLocationSchema';
import {
    StockLocationSchemaFromJSON,
    StockLocationSchemaFromJSONTyped,
    StockLocationSchemaToJSON,
    StockLocationSchemaToJSONTyped,
} from './StockLocationSchema';
import type { StockLinesLot } from './StockLinesLot';
import {
    StockLinesLotFromJSON,
    StockLinesLotFromJSONTyped,
    StockLinesLotToJSON,
    StockLinesLotToJSONTyped,
} from './StockLinesLot';

/**
 * 
 * @export
 * @interface StockLinesLineDetail
 */
export interface StockLinesLineDetail {
    /**
     * 
     * @type {number}
     * @memberof StockLinesLineDetail
     */
    id: number;
    /**
     * 
     * @type {StockLinesLot}
     * @memberof StockLinesLineDetail
     */
    stockLot: StockLinesLot;
    /**
     * 
     * @type {number}
     * @memberof StockLinesLineDetail
     */
    labelId: number;
    /**
     * 
     * @type {number}
     * @memberof StockLinesLineDetail
     */
    amount: number;
    /**
     * 
     * @type {number}
     * @memberof StockLinesLineDetail
     */
    stockLocationId: number;
    /**
     * 
     * @type {StockLocationSchema}
     * @memberof StockLinesLineDetail
     */
    stockLocation: StockLocationSchema;
    /**
     * 
     * @type {number}
     * @memberof StockLinesLineDetail
     */
    stockShelfId: number;
    /**
     * 
     * @type {number}
     * @memberof StockLinesLineDetail
     */
    resourceId: number;
    /**
     * 
     * @type {string}
     * @memberof StockLinesLineDetail
     */
    articleNo?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StockLinesLineDetail
     */
    resourceName: string;
    /**
     * 
     * @type {number}
     * @memberof StockLinesLineDetail
     */
    quantityTypeId: number;
    /**
     * 
     * @type {string}
     * @memberof StockLinesLineDetail
     */
    quantityTypeName: string;
    /**
     * 
     * @type {string}
     * @memberof StockLinesLineDetail
     */
    stockShelfName?: string | null;
}

/**
 * Check if a given object implements the StockLinesLineDetail interface.
 */
export function instanceOfStockLinesLineDetail(value: object): value is StockLinesLineDetail {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('stockLot' in value) || value['stockLot'] === undefined) return false;
    if (!('labelId' in value) || value['labelId'] === undefined) return false;
    if (!('amount' in value) || value['amount'] === undefined) return false;
    if (!('stockLocationId' in value) || value['stockLocationId'] === undefined) return false;
    if (!('stockLocation' in value) || value['stockLocation'] === undefined) return false;
    if (!('stockShelfId' in value) || value['stockShelfId'] === undefined) return false;
    if (!('resourceId' in value) || value['resourceId'] === undefined) return false;
    if (!('resourceName' in value) || value['resourceName'] === undefined) return false;
    if (!('quantityTypeId' in value) || value['quantityTypeId'] === undefined) return false;
    if (!('quantityTypeName' in value) || value['quantityTypeName'] === undefined) return false;
    return true;
}

export function StockLinesLineDetailFromJSON(json: any): StockLinesLineDetail {
    return StockLinesLineDetailFromJSONTyped(json, false);
}

export function StockLinesLineDetailFromJSONTyped(json: any, ignoreDiscriminator: boolean): StockLinesLineDetail {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'stockLot': StockLinesLotFromJSON(json['stockLot']),
        'labelId': json['labelId'],
        'amount': json['amount'],
        'stockLocationId': json['stockLocationId'],
        'stockLocation': StockLocationSchemaFromJSON(json['stockLocation']),
        'stockShelfId': json['stockShelfId'],
        'resourceId': json['resourceId'],
        'articleNo': json['articleNo'] == null ? undefined : json['articleNo'],
        'resourceName': json['resourceName'],
        'quantityTypeId': json['quantityTypeId'],
        'quantityTypeName': json['quantityTypeName'],
        'stockShelfName': json['stockShelfName'] == null ? undefined : json['stockShelfName'],
    };
}

  export function StockLinesLineDetailToJSON(json: any): StockLinesLineDetail {
      return StockLinesLineDetailToJSONTyped(json, false);
  }

  export function StockLinesLineDetailToJSONTyped(value?: StockLinesLineDetail | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'stockLot': StockLinesLotToJSON(value['stockLot']),
        'labelId': value['labelId'],
        'amount': value['amount'],
        'stockLocationId': value['stockLocationId'],
        'stockLocation': StockLocationSchemaToJSON(value['stockLocation']),
        'stockShelfId': value['stockShelfId'],
        'resourceId': value['resourceId'],
        'articleNo': value['articleNo'],
        'resourceName': value['resourceName'],
        'quantityTypeId': value['quantityTypeId'],
        'quantityTypeName': value['quantityTypeName'],
        'stockShelfName': value['stockShelfName'],
    };
}

