/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: smn@advance.ch
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StockCreateLineDTO
 */
export interface StockCreateLineDTO {
    /**
     * 
     * @type {number}
     * @memberof StockCreateLineDTO
     */
    stockLineId: number;
    /**
     * 
     * @type {number}
     * @memberof StockCreateLineDTO
     */
    shelfId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof StockCreateLineDTO
     */
    amount: number;
    /**
     * 
     * @type {boolean}
     * @memberof StockCreateLineDTO
     */
    moveCompleteLine: boolean;
}

/**
 * Check if a given object implements the StockCreateLineDTO interface.
 */
export function instanceOfStockCreateLineDTO(value: object): value is StockCreateLineDTO {
    if (!('stockLineId' in value) || value['stockLineId'] === undefined) return false;
    if (!('amount' in value) || value['amount'] === undefined) return false;
    if (!('moveCompleteLine' in value) || value['moveCompleteLine'] === undefined) return false;
    return true;
}

export function StockCreateLineDTOFromJSON(json: any): StockCreateLineDTO {
    return StockCreateLineDTOFromJSONTyped(json, false);
}

export function StockCreateLineDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): StockCreateLineDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'stockLineId': json['stockLineId'],
        'shelfId': json['shelfId'] == null ? undefined : json['shelfId'],
        'amount': json['amount'],
        'moveCompleteLine': json['moveCompleteLine'],
    };
}

  export function StockCreateLineDTOToJSON(json: any): StockCreateLineDTO {
      return StockCreateLineDTOToJSONTyped(json, false);
  }

  export function StockCreateLineDTOToJSONTyped(value?: StockCreateLineDTO | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'stockLineId': value['stockLineId'],
        'shelfId': value['shelfId'],
        'amount': value['amount'],
        'moveCompleteLine': value['moveCompleteLine'],
    };
}

