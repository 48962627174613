import useScanDetection from 'hooks/useScanDetection';
import React, { useEffect } from 'react';
import { useNotify } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import FormState from '../form/state';
import { SupplierScanResult, handleSupplierBarcode } from './barcodeHandler';
import { EdelridPopup, PorcherPopup } from './popup';

interface BarcodeHandlerProps {
  onSubmit: (data: FormState) => Promise<void>,
}

export default function BarcodeHandler(props: BarcodeHandlerProps) {
  const [state, setState] = React.useState<SupplierScanResult | undefined>();
  const form = useFormContext<FormState>();
  const notify = useNotify();

  const onSubmit = async (data: FormState) => {
    // eslint-disable-next-line react/destructuring-assignment
    await props.onSubmit(data);
    setState(undefined);
    notify('Record created', { type: 'success' });
  };

  const reset = () => {
    form.reset({
      resourceId: undefined,
      stockLotName: '',
      amount: undefined,
    });
  };

  useScanDetection({
    averageWaitTime: 20,
    resetInput: true,
    onComplete: async (code) => {
      const barCodeScanData = code.toString().trim();
      const result = await handleSupplierBarcode(state, barCodeScanData);

      if (result?.error === undefined) {
        if (result.supplierPopup !== undefined) {
          setState(result);
        } else {
          // reset the local state if not in a multi-barcode context
          setState(undefined);
        }

        if (result.resource) {
          form.setValue('resourceId', result.resource.id);
        }
        if (result.amount) {
          form.setValue('amount', result.amount);
        }
        if (result.stockLotName) {
          form.setValue('stockLotName', result.stockLotName);
        }
        if (result?.resource !== undefined && result.amount !== undefined && result.stockLotName !== undefined) {
          console.log('submit');
          setState(undefined);
          form.handleSubmit(onSubmit)();
        }
      } else {
        notify(result.error, { type: 'error' });
        result.error = undefined;
      }
    },
    minLength: 2,
    // averageWaitTime: 10,
  });

  const onClose = () => {
    setState(undefined);
    reset();
  };

  const popupData = {
    state,
    onClose,
  };

  useEffect(() => {
    reset();
  }, [form.formState.isSubmitSuccessful]);

  return (
    <>
      <PorcherPopup {...popupData} />
      <EdelridPopup {...popupData} />
    </>
  );
}
