import { useQuery } from '@tanstack/react-query';
import { create, keyResolver, windowScheduler } from '@yornaath/batshit';
import {
  ProductListSchema, ProductResourceDetailSchema, ResourcesSearchRequest,
} from 'api';
import { ProductResourceDetailSchemaStockTracingEnum } from 'api/models/ProductResourceDetailSchema';
import { QueryOptions, getApiQuery, getInfiniteApiQuery } from 'repositories/base';
import enumToOptions from 'utils/enumToOptions';
import api from '../api';

// eslint-disable-next-line import/prefer-default-export

export const useResourcesQuery = (query?: ResourcesSearchRequest) => useQuery({
  queryKey: ['Resources', query],
  queryFn: () => api.resourcesSearch(query),
});

export const useResources = () => useQuery({
  queryKey: ['Resources'],
  queryFn: () => api.resourcesSearch({ limit: 99999 }),
  staleTime: 10,
});

export const useInfiniteResourcesQuery = getInfiniteApiQuery(api.resourcesSearch, {});
export const useInfiniteRawMaterialsQuery = getInfiniteApiQuery(api.rawMaterialsSearch, {});
export const useInfiniteProductsQuery = getInfiniteApiQuery(api.productsSearch, {});

const resourceBatch = create({
  // The fetcher resolves the list of queries(here just a list of user ids as number) to one single api call.
  fetcher: async (ids: number[]) => {
    const result = await api.resourcesSearch({
      ids: ids.join(','),
      limit: 9999,
    });

    if (!result.list) {
      throw new Error('No list received');
    }

    return result.list;
  },
  // when we call users.fetch, this will resolve the correct user using the field `id`
  resolver: keyResolver('id'),
  // this will batch all calls to users.fetch that are made within 10 milliseconds.
  scheduler: windowScheduler(10),
});

const productBatch = create({
  // The fetcher resolves the list of queries(here just a list of user ids as number) to one single api call.
  fetcher: async (ids: number[]) => {
    const result = await api.productsSearch({
      ids: ids.join(','),
      limit: 9999,
    });

    if (!result.list) {
      throw new Error('No list received');
    }

    return result.list;
  },
  // when we call users.fetch, this will resolve the correct user using the field `id`
  resolver: keyResolver('id'),
  // this will batch all calls to users.fetch that are made within 10 milliseconds.
  scheduler: windowScheduler(10),
});

export const useResourceQuery = (
  id: number,
  options?: QueryOptions<ProductResourceDetailSchema>,
) => useQuery({
  queryKey: ['Resources', id],
  queryFn: () => resourceBatch.fetch(id),
  ...options,
});

export const useProductQuery = (
  id: number,
  options?: QueryOptions<ProductListSchema>,
) => useQuery({
  queryKey: ['Products', id],
  queryFn: () => productBatch.fetch(id),
  ...options,
});

export const useRawMaterialQuery = getApiQuery(api.rawMaterialsGet);

export const useProductDetail = getApiQuery(api.productsGet);

export default useResourcesQuery;

export const StockTracingChoices = enumToOptions(
  ProductResourceDetailSchemaStockTracingEnum,
  (key) => `mai.rawMaterials.stockTracingCode.${key}`,
);

export const useProductionItem = () => useQuery({
  queryKey: ['ProductionItem'],

  queryFn: async () => {
    const pi = await api.productionItemsSearch({
      limit: 9000,
    });
    return pi.list;
  },
});
