import createResourcePrice from './createResourcePriceContainer';
import editResourcePrice from './editResourcePriceContainer';
import productionItemList from './list';
import showResourcePrice from './showResourcePriceContainer';
import { PriceChange } from '@mui/icons-material';

export default {
  name: 'product/resource-prices',
  icon: PriceChange,
  list: productionItemList,
  create: createResourcePrice,
  edit: editResourcePrice,
  show: showResourcePrice,
  options: {
    label: 'Resource Prices',
  },
};
