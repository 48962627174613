/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: smn@advance.ch
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ProductProductionItemDetailSchema } from './ProductProductionItemDetailSchema';
import {
    ProductProductionItemDetailSchemaFromJSON,
    ProductProductionItemDetailSchemaFromJSONTyped,
    ProductProductionItemDetailSchemaToJSON,
    ProductProductionItemDetailSchemaToJSONTyped,
} from './ProductProductionItemDetailSchema';

/**
 * 
 * @export
 * @interface ShipmentExportLineDetail
 */
export interface ShipmentExportLineDetail {
    /**
     * 
     * @type {number}
     * @memberof ShipmentExportLineDetail
     */
    itemId: number;
    /**
     * 
     * @type {number}
     * @memberof ShipmentExportLineDetail
     */
    amount: number;
    /**
     * 
     * @type {number}
     * @memberof ShipmentExportLineDetail
     */
    id: number;
    /**
     * 
     * @type {ProductProductionItemDetailSchema}
     * @memberof ShipmentExportLineDetail
     */
    item?: ProductProductionItemDetailSchema;
}

/**
 * Check if a given object implements the ShipmentExportLineDetail interface.
 */
export function instanceOfShipmentExportLineDetail(value: object): value is ShipmentExportLineDetail {
    if (!('itemId' in value) || value['itemId'] === undefined) return false;
    if (!('amount' in value) || value['amount'] === undefined) return false;
    if (!('id' in value) || value['id'] === undefined) return false;
    return true;
}

export function ShipmentExportLineDetailFromJSON(json: any): ShipmentExportLineDetail {
    return ShipmentExportLineDetailFromJSONTyped(json, false);
}

export function ShipmentExportLineDetailFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShipmentExportLineDetail {
    if (json == null) {
        return json;
    }
    return {
        
        'itemId': json['itemId'],
        'amount': json['amount'],
        'id': json['id'],
        'item': json['item'] == null ? undefined : ProductProductionItemDetailSchemaFromJSON(json['item']),
    };
}

  export function ShipmentExportLineDetailToJSON(json: any): ShipmentExportLineDetail {
      return ShipmentExportLineDetailToJSONTyped(json, false);
  }

  export function ShipmentExportLineDetailToJSONTyped(value?: ShipmentExportLineDetail | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'itemId': value['itemId'],
        'amount': value['amount'],
        'id': value['id'],
        'item': ProductProductionItemDetailSchemaToJSON(value['item']),
    };
}

