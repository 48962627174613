/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: smn@advance.ch
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProductCreateSchema
 */
export interface ProductCreateSchema {
    /**
     * 
     * @type {string}
     * @memberof ProductCreateSchema
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ProductCreateSchema
     */
    articleNo?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProductCreateSchema
     */
    isActive: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductCreateSchema
     */
    isUniqueLotNo: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductCreateSchema
     */
    stockTracing: ProductCreateSchemaStockTracingEnum;
    /**
     * 
     * @type {number}
     * @memberof ProductCreateSchema
     */
    quantityTypeId: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof ProductCreateSchema
     */
    categories: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof ProductCreateSchema
     */
    image?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductCreateSchema
     */
    size?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductCreateSchema
     */
    color?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductCreateSchema
     */
    option?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProductCreateSchema
     */
    isReadyToSell: boolean;
}


/**
 * @export
 */
export const ProductCreateSchemaStockTracingEnum = {
    None: 'None',
    Lot: 'Lot',
    Serial: 'Serial'
} as const;
export type ProductCreateSchemaStockTracingEnum = typeof ProductCreateSchemaStockTracingEnum[keyof typeof ProductCreateSchemaStockTracingEnum];


/**
 * Check if a given object implements the ProductCreateSchema interface.
 */
export function instanceOfProductCreateSchema(value: object): value is ProductCreateSchema {
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('isActive' in value) || value['isActive'] === undefined) return false;
    if (!('isUniqueLotNo' in value) || value['isUniqueLotNo'] === undefined) return false;
    if (!('stockTracing' in value) || value['stockTracing'] === undefined) return false;
    if (!('quantityTypeId' in value) || value['quantityTypeId'] === undefined) return false;
    if (!('categories' in value) || value['categories'] === undefined) return false;
    if (!('isReadyToSell' in value) || value['isReadyToSell'] === undefined) return false;
    return true;
}

export function ProductCreateSchemaFromJSON(json: any): ProductCreateSchema {
    return ProductCreateSchemaFromJSONTyped(json, false);
}

export function ProductCreateSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductCreateSchema {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'articleNo': json['articleNo'] == null ? undefined : json['articleNo'],
        'isActive': json['isActive'],
        'isUniqueLotNo': json['isUniqueLotNo'],
        'stockTracing': json['stockTracing'],
        'quantityTypeId': json['quantityTypeId'],
        'categories': json['categories'],
        'image': json['image'] == null ? undefined : json['image'],
        'size': json['size'] == null ? undefined : json['size'],
        'color': json['color'] == null ? undefined : json['color'],
        'option': json['option'] == null ? undefined : json['option'],
        'isReadyToSell': json['isReadyToSell'],
    };
}

  export function ProductCreateSchemaToJSON(json: any): ProductCreateSchema {
      return ProductCreateSchemaToJSONTyped(json, false);
  }

  export function ProductCreateSchemaToJSONTyped(value?: ProductCreateSchema | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'name': value['name'],
        'articleNo': value['articleNo'],
        'isActive': value['isActive'],
        'isUniqueLotNo': value['isUniqueLotNo'],
        'stockTracing': value['stockTracing'],
        'quantityTypeId': value['quantityTypeId'],
        'categories': value['categories'],
        'image': value['image'],
        'size': value['size'],
        'color': value['color'],
        'option': value['option'],
        'isReadyToSell': value['isReadyToSell'],
    };
}

