/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: smn@advance.ch
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StockInventoryControlLineSchema
 */
export interface StockInventoryControlLineSchema {
    /**
     * 
     * @type {number}
     * @memberof StockInventoryControlLineSchema
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof StockInventoryControlLineSchema
     */
    amount: number;
    /**
     * 
     * @type {number}
     * @memberof StockInventoryControlLineSchema
     */
    amountInStock?: number | null;
    /**
     * 
     * @type {number}
     * @memberof StockInventoryControlLineSchema
     */
    changeAmount: number;
    /**
     * 
     * @type {string}
     * @memberof StockInventoryControlLineSchema
     */
    comment?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StockInventoryControlLineSchema
     */
    articleNo?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StockInventoryControlLineSchema
     */
    articleName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StockInventoryControlLineSchema
     */
    lotNo?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StockInventoryControlLineSchema
     */
    shelf?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StockInventoryControlLineSchema
     */
    createdBy?: string | null;
}

/**
 * Check if a given object implements the StockInventoryControlLineSchema interface.
 */
export function instanceOfStockInventoryControlLineSchema(value: object): value is StockInventoryControlLineSchema {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('amount' in value) || value['amount'] === undefined) return false;
    if (!('changeAmount' in value) || value['changeAmount'] === undefined) return false;
    return true;
}

export function StockInventoryControlLineSchemaFromJSON(json: any): StockInventoryControlLineSchema {
    return StockInventoryControlLineSchemaFromJSONTyped(json, false);
}

export function StockInventoryControlLineSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): StockInventoryControlLineSchema {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'amount': json['amount'],
        'amountInStock': json['amountInStock'] == null ? undefined : json['amountInStock'],
        'changeAmount': json['changeAmount'],
        'comment': json['comment'] == null ? undefined : json['comment'],
        'articleNo': json['articleNo'] == null ? undefined : json['articleNo'],
        'articleName': json['articleName'] == null ? undefined : json['articleName'],
        'lotNo': json['lotNo'] == null ? undefined : json['lotNo'],
        'shelf': json['shelf'] == null ? undefined : json['shelf'],
        'createdBy': json['createdBy'] == null ? undefined : json['createdBy'],
    };
}

  export function StockInventoryControlLineSchemaToJSON(json: any): StockInventoryControlLineSchema {
      return StockInventoryControlLineSchemaToJSONTyped(json, false);
  }

  export function StockInventoryControlLineSchemaToJSONTyped(value?: StockInventoryControlLineSchema | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'amount': value['amount'],
        'amountInStock': value['amountInStock'],
        'changeAmount': value['changeAmount'],
        'comment': value['comment'],
        'articleNo': value['articleNo'],
        'articleName': value['articleName'],
        'lotNo': value['lotNo'],
        'shelf': value['shelf'],
        'createdBy': value['createdBy'],
    };
}

