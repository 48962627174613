import {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { FieldValues, useFormContext } from 'react-hook-form';

interface BufferCharacter {
  time: number,
  char: string,
}

interface Buffer {
  current: BufferCharacter[]
}

interface Config {
  /** Time to wait from last character to then trigger an evaluation of the buffer. */
  timeToEvaluate?: number,
  /** Average time between characters in milliseconds.
   *  Used to determine if input is from keyboard or a scanner. Defaults to 50ms. */
  averageWaitTime?: number,
  /** Character that barcode scanner prefixes input with. */
  startCharacters?: string[],
  /** Character that barcode scanner suffixes input with. Defaults to line return. */
  endCharacters?: string[],
  /** Callback to use on complete scan input. */
  onComplete: (code: string) => void,
  /** Callback to use on error. */
  onError?: (error: string) => void,
  /** Minimum length a scanned code should be. Defaults to 0. */
  minLength?: number,
  /** Ignore scan input if this node is focused. */
  ignoreIfFocusOn?: Node,
  resetInput?: boolean
  /** Bind keydown event to this node. Defaults to document. */
  container?: Node
}

/**
* Checks for scan input within a container and sends the output to a callback function.
* @param param0 Config object
*/
const useScanDetection = ({
  timeToEvaluate = 100,
  averageWaitTime = 50,
  startCharacters = [],
  endCharacters = ['Enter'],
  onComplete,
  onError,
  minLength = 1,
  ignoreIfFocusOn,
  resetInput = false,
  container = document,
}: Config) => {
  const buffer: Buffer = useRef([]);
  const timeout: any = useRef(false);
  const form = useFormContext();
  const [inputData, setInputData] = useState<FieldValues>();

  const clearBuffer = () => {
    buffer.current = [];
  };

  const evaluateBuffer = useCallback((event?: KeyboardEvent) => {
    clearTimeout(timeout.current);

    // calculate average time diff between character inputs
    const sum = buffer.current
      .map(({ time }, k, arr) => (k > 0 ? time - arr[k - 1].time : 0))
      .slice(1)
      .reduce((total, delta) => total + delta, 0);
    const avg = sum / (buffer.current.length - 1);

    // strip special codes ("Enter", "Shift",...)
    const code = buffer.current
      .slice(startCharacters.length > 0 ? 1 : 0)
      .filter((x) => x.char.length === 1)
      .map(({ char }) => char)
      .join('');

    if (
      avg <= averageWaitTime
      && buffer.current.slice(startCharacters.length > 0 ? 1 : 0).length >= minLength
    ) {
      if (
        !endCharacters
        || buffer.current.find((item) => endCharacters.includes(item.char))
      ) {
        if (resetInput) {
          if (event) {
            event.stopPropagation();
            event.preventDefault();
          }
          if (inputData) {
            Object.entries(inputData).forEach(([key, value]) => {
              form.setValue(key, value);
            });
          }
        }
        onComplete(code);
      } else {
        console.log('jo, no endchar');
      }
    } else if (avg <= averageWaitTime && !!onError) {
      onError(code);
    }
    clearBuffer();
  }, [averageWaitTime, endCharacters, form, inputData, minLength, onError, resetInput, startCharacters.length]);

  const onKeyDown: Function = useCallback((event: KeyboardEvent) => {
    if (event.currentTarget === ignoreIfFocusOn) {
      return;
    }

    if (buffer.current.length === 0 && resetInput) {
      setInputData(form?.getValues());
    }

    if (
      startCharacters.length === 0
      || buffer.current.length > 0
      || buffer.current.find((item) => startCharacters.includes(item.char))
    ) {
      clearTimeout(timeout.current);
      timeout.current = setTimeout(evaluateBuffer, timeToEvaluate);
      buffer.current.push({ time: performance.now(), char: event.key });
    }
    if (endCharacters.includes(event.key)) {
      evaluateBuffer(event);
    }
  }, [ignoreIfFocusOn, resetInput, startCharacters, endCharacters, form, evaluateBuffer, timeToEvaluate]);

  useEffect(() => () => {
    clearTimeout(timeout.current);
  }, []);

  useEffect(() => {
    container.addEventListener('keypress', (onKeyDown) as EventListener);
    return () => {
      container.removeEventListener('keypress', (onKeyDown) as EventListener);
    };
  }, [container, onKeyDown]);
};

export default useScanDetection;
