import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import PasswordIcon from '@mui/icons-material/Password';
import {
  Dialog, DialogTitle, Grid, TextField,
} from '@mui/material';
import Button from '@mui/material/Button';
import { AuthUserEntity, ResponseInfo } from 'api';
import useHasAccess from 'hooks/useHasAccess';
import * as React from 'react';
import {
  Confirm,
  ListButton,
  ShowButton,
  TopToolbar,
  useNotify,
  useRecordContext,
  useRefresh,
} from 'react-admin';
import api from 'repositories/api';
import useTranslate from 'utils/translate';

function LockAccount() {
  const record = useRecordContext<AuthUserEntity>();
  const translate = useTranslate();
  const hasAccess = useHasAccess();
  const [open, setOpen] = React.useState(false);
  const notify = useNotify();
  const refresh = useRefresh();

  const canLock = hasAccess({ url: 'auth/users/{id}/lock', httpMethod: 'PUT' });
  const canUnlock = hasAccess({ url: 'auth/users/{id}/unlock', httpMethod: 'PUT' });

  let button;
  let enabled = false;
  let title;
  let action: () => Promise<ResponseInfo>;

  if (!record) {
    return null;
  }

  if (record.isActive) {
    enabled = canLock;
    button = (
      <>
        <LockIcon fontSize="small" sx={{ mr: 1 }} />
        {translate('mai.auth.lockAccount')}
      </>
    );
    title = 'Lock Account?';
    action = async () => api.usersLockAccount({ id: record.id! });
  } else {
    enabled = canUnlock;
    button = (
      <>
        <LockOpenIcon fontSize="small" sx={{ mr: 1 }} />
        {translate('mai.auth.unlockAccount')}
      </>
    );
    title = 'Unlock Account?';
    action = async () => api.usersUnLockAccount({ id: record.id! });
  }

  return (
    <>
      <Button color="warning" onClick={() => setOpen(true)} size="small" disabled={!enabled}>
        {button}
      </Button>
      <Confirm
        isOpen={open}
        title={title}
        content=""
        onClose={() => setOpen(false)}
        onConfirm={async () => {
          const result = await action();
          if (result.code === 200) {
            notify('Done', { type: 'success' });
          }
          setOpen(false);
          refresh();
        }}
      />
    </>
  );
}

function ChangePassword() {
  const record = useRecordContext<AuthUserEntity>();
  const translate = useTranslate();
  const hasAccess = useHasAccess();
  const [open, setOpen] = React.useState(false);
  const [newPassword, setNewPassword] = React.useState('');
  const notify = useNotify();

  const canResetPassword = hasAccess({ url: 'auth/users/{id}/reset-password', httpMethod: 'PUT' });

  return (
    <>
      <Button color="primary" onClick={() => setOpen(true)} size="small" disabled={!canResetPassword}>
        <PasswordIcon fontSize="small" sx={{ mr: 1 }} />
        {translate('mai.auth.resetPassword')}
      </Button>
      <Dialog
        open={open}
        title="Reset Password"
        onClose={() => setOpen(false)}
      >
        <DialogTitle>
          {translate('mai.changePassword')}
        </DialogTitle>
        <form onSubmit={async (event) => {
          event.preventDefault();
          await api.usersResetPassword({ id: record!.id, authResetPasswordSchema: { newPassword } });
          notify('success');
          setOpen(false);
          setNewPassword('');
        }}
        >
          <Grid container spacing={2} rowSpacing={0.5}>

            <Grid item xs={1} />
            <Grid item xs={10}>
              <TextField
                type="password"
                variant="outlined"
                required
                onChange={(e) => setNewPassword(e.target.value)}
                value={newPassword}
              />
            </Grid>
            <Grid item xs={1} />

            <Grid item xs={1} />
            <Grid item xs={5}>
              <Button variant="outlined" type="submit">
                {translate('ra.action.save')}
              </Button>
            </Grid>
            <Grid item xs={5}>
              <Button onClick={() => setOpen(false)}>
                {translate('ra.action.cancel')}
              </Button>
            </Grid>
            <Grid item xs={1} />

          </Grid>
        </form>
      </Dialog>

    </>
  );
}

export default React.memo((props: any) => {
  const {
    isShow,
    isList,
  } = props;

  return (
    <TopToolbar>
      {isShow !== false && <ShowButton />}
      {isList !== false && <ListButton />}
      <ChangePassword />
      <LockAccount />
    </TopToolbar>
  );
});
