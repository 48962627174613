/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: smn@advance.ch
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Paging } from './Paging';
import {
    PagingFromJSON,
    PagingFromJSONTyped,
    PagingToJSON,
    PagingToJSONTyped,
} from './Paging';
import type { AuthUserEntity } from './AuthUserEntity';
import {
    AuthUserEntityFromJSON,
    AuthUserEntityFromJSONTyped,
    AuthUserEntityToJSON,
    AuthUserEntityToJSONTyped,
} from './AuthUserEntity';

/**
 * 
 * @export
 * @interface PaginatedListUserEntity
 */
export interface PaginatedListUserEntity {
    /**
     * 
     * @type {Array<AuthUserEntity>}
     * @memberof PaginatedListUserEntity
     */
    list: Array<AuthUserEntity>;
    /**
     * 
     * @type {Paging}
     * @memberof PaginatedListUserEntity
     */
    paging: Paging;
}

/**
 * Check if a given object implements the PaginatedListUserEntity interface.
 */
export function instanceOfPaginatedListUserEntity(value: object): value is PaginatedListUserEntity {
    if (!('list' in value) || value['list'] === undefined) return false;
    if (!('paging' in value) || value['paging'] === undefined) return false;
    return true;
}

export function PaginatedListUserEntityFromJSON(json: any): PaginatedListUserEntity {
    return PaginatedListUserEntityFromJSONTyped(json, false);
}

export function PaginatedListUserEntityFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaginatedListUserEntity {
    if (json == null) {
        return json;
    }
    return {
        
        'list': ((json['list'] as Array<any>).map(AuthUserEntityFromJSON)),
        'paging': PagingFromJSON(json['paging']),
    };
}

  export function PaginatedListUserEntityToJSON(json: any): PaginatedListUserEntity {
      return PaginatedListUserEntityToJSONTyped(json, false);
  }

  export function PaginatedListUserEntityToJSONTyped(value?: PaginatedListUserEntity | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'list': ((value['list'] as Array<any>).map(AuthUserEntityToJSON)),
        'paging': PagingToJSON(value['paging']),
    };
}

