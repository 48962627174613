import {
  ProductBillOfResourceLineSchema, ProductBillOfResourcesDTO,
} from 'api';
import ResourceChooser from 'components/chooser/resourceChooser';
import ShowQuantity from 'components/commons/showQuantity';
import { FormIterator } from 'components/forms/formIterator/formIterator';
import { ResourceQuantityInput } from 'components/input/quantity/resourceFields';
import React from 'react';
import {
  ArrayInput,
  TextInput,
} from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { useResources } from 'repositories/products/product';
import { Source } from 'utils/source';
import PasteBillOfResources from './pasteBillOfResources';

interface BillOfResourcesFormProps {
  source: string
}

function EditBillOfResourceLine(props: { record?: ProductBillOfResourceLineSchema }) {
  const { record } = props;
  const s = Source<ProductBillOfResourceLineSchema>();

  return (
    <div className="edit">
      <ResourceChooser
        {...s('resourceId')}
        required
        className="resourcesSelect"
        label="resources.billOfResources.fields.resources"
        sx={{ width: '40%' }}
      />

      <ResourceQuantityInput
        className="amountInput"
        label="resources.billOfResources.fields.amount"
        {...s('amount')}
        resourceId={record?.resourceId}
      />
      <TextInput
        label="resources.billOfResources.fields.comment"
        {...s('comment')}
        fullWidth
        inputProps={{ maxLength: 250 }}
        variant="outlined"
        helperText={false}
        className="commentInput"
      />
    </div>
  );
}

function ShowBillOfResourceLine(props: { record: ProductBillOfResourceLineSchema }) {
  const {
    record,
  } = props;

  const { data: resources } = useResources();
  const resource = React.useMemo(
    () => resources?.list.find((x) => x.id === record.resourceId),
    [resources],
  );

  return (
    <div>
      <p>{resource?.articleNo} / {resource?.name}</p>
      <ShowQuantity
        quantityTypeId={resource?.quantityTypeId}
        value={record.amount}
      />
      <p>{record.comment}</p>
    </div>
  );
}

interface BillOfResourcePasterProps {
  onChange: () => void;
  source: string;
}

export function usePasteData() {
  const { data: resources } = useResources();

  return React.useCallback((data: [number, number][]) => {
    const resourceIds = new Map(resources?.list.map((resource) => [resource.articleNo, resource.id]));

    const newLines = data.map(([articleNo, amount]) => ({
      resourceId: articleNo ? resourceIds.get(articleNo.toString()) : undefined,
      amount,
    }));

    return newLines.filter((resource) => resource.resourceId !== undefined);
  }, [resources]);
}

function BillOfResourcePaster(props: BillOfResourcePasterProps) {
  const {
    source,
    onChange,
  } = props;
  const form = useFormContext();
  const getPasteData = usePasteData();
  const lines: ProductBillOfResourceLineSchema[] | undefined = form.watch(source);
  const hasLines = React.useMemo(() => lines?.length, [lines]);

  const onPaste = async (data: [number, number][]) => {
    const newLines = getPasteData(data)
      .filter((line) => line.amount > 0)
      .map((line) => ({ ...line, comment: '' }));

    form.setValue(source, newLines, { shouldDirty: true });
    onChange();
  };

  if (hasLines) {
    return null;
  }

  return <PasteBillOfResources onSubmit={onPaste} />;
}

export default function BillOfResourcesForm(props: BillOfResourcesFormProps) {
  const {
    source,
  } = props;
  const s = Source<ProductBillOfResourcesDTO>(source);
  const [key, setKey] = React.useState(0);
  return (
    <>
      <BillOfResourcePaster {...s('lines')} onChange={() => setKey(key + 1)} />
      <ArrayInput
        {...s('lines')}
        key={key}
        sx={{
          marginTop: '0',
        }}
      >
        <FormIterator<ProductBillOfResourceLineSchema>
          Show={ShowBillOfResourceLine}
          Edit={EditBillOfResourceLine}
          defaultValue={{ amount: null, resourceId: null }}
        />
      </ArrayInput>
    </>
  );
}
