import React from 'react';
import { Source } from 'utils/source';

import { TextInput } from 'react-admin';
import { ProductionOrdersSearchRequest } from 'api';
import { SelectBusinessPartners } from 'resources/product/rawMaterials/filters';

const filterQuery = Source<ProductionOrdersSearchRequest>();

export default [
  <TextInput source="query" alwaysOn />,
  <SelectBusinessPartners label="Business Partner" {...filterQuery('businessPartnerId')} />,
];
