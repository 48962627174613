import { Autocomplete, AutocompleteProps, TextField } from '@mui/material';
import { ProductProductionItemDetailSchema } from 'api';
import React from 'react';
import { useProductionItem } from 'repositories/products/product';

/* eslint-disable @typescript-eslint/indent */
type BPAutoCompletProps = Omit<
  AutocompleteProps<ProductProductionItemDetailSchema, false, false, false>,
  'defaultValue' | 'onChange' | 'options' | 'value' | 'renderInput'
>;
/* eslint-enable @typescript-eslint/indent */

interface ExportShipmentBoxInputProps extends BPAutoCompletProps {
  onChange: (id: number | undefined) => void;
  value: number | null;
  required?: boolean;
}

export default function ExportShipmentBoxField(props: ExportShipmentBoxInputProps) {
  const {
    value,
    onChange,
    required,
    ...rest
  } = props;

  const { data } = useProductionItem();
  const selectedValue = React.useMemo(
    () => (value ? data?.find((elem) => elem.id === value) || null : null),
    [data, value],
  );

  return (
    <Autocomplete
      {...rest}
      options={data ?? []}
      value={selectedValue}
      onChange={(bprt, newValue) => onChange(newValue?.id)}
      size="small"
      renderInput={(params) => <TextField {...params} label="Production Item" required={required} />}
      getOptionLabel={(el) => el.serialNo}
    />
  );
}
