/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: smn@advance.ch
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AuthUserInfo } from './AuthUserInfo';
import {
    AuthUserInfoFromJSON,
    AuthUserInfoFromJSONTyped,
    AuthUserInfoToJSON,
    AuthUserInfoToJSONTyped,
} from './AuthUserInfo';

/**
 * 
 * @export
 * @interface AuthUserDetailEntity
 */
export interface AuthUserDetailEntity {
    /**
     * 
     * @type {number}
     * @memberof AuthUserDetailEntity
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof AuthUserDetailEntity
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof AuthUserDetailEntity
     */
    email?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof AuthUserDetailEntity
     */
    lastLogin?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof AuthUserDetailEntity
     */
    lastLogout?: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof AuthUserDetailEntity
     */
    isActive: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AuthUserDetailEntity
     */
    isAdmin: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AuthUserDetailEntity
     */
    isSpecialAccess: boolean;
    /**
     * 
     * @type {AuthUserInfo}
     * @memberof AuthUserDetailEntity
     */
    information: AuthUserInfo;
    /**
     * 
     * @type {Array<string>}
     * @memberof AuthUserDetailEntity
     */
    groupIds: Array<string>;
}

/**
 * Check if a given object implements the AuthUserDetailEntity interface.
 */
export function instanceOfAuthUserDetailEntity(value: object): value is AuthUserDetailEntity {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('username' in value) || value['username'] === undefined) return false;
    if (!('isActive' in value) || value['isActive'] === undefined) return false;
    if (!('isAdmin' in value) || value['isAdmin'] === undefined) return false;
    if (!('isSpecialAccess' in value) || value['isSpecialAccess'] === undefined) return false;
    if (!('information' in value) || value['information'] === undefined) return false;
    if (!('groupIds' in value) || value['groupIds'] === undefined) return false;
    return true;
}

export function AuthUserDetailEntityFromJSON(json: any): AuthUserDetailEntity {
    return AuthUserDetailEntityFromJSONTyped(json, false);
}

export function AuthUserDetailEntityFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuthUserDetailEntity {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'username': json['username'],
        'email': json['email'] == null ? undefined : json['email'],
        'lastLogin': json['lastLogin'] == null ? undefined : (new Date(json['lastLogin'])),
        'lastLogout': json['lastLogout'] == null ? undefined : (new Date(json['lastLogout'])),
        'isActive': json['isActive'],
        'isAdmin': json['isAdmin'],
        'isSpecialAccess': json['isSpecialAccess'],
        'information': AuthUserInfoFromJSON(json['information']),
        'groupIds': json['groupIds'],
    };
}

  export function AuthUserDetailEntityToJSON(json: any): AuthUserDetailEntity {
      return AuthUserDetailEntityToJSONTyped(json, false);
  }

  export function AuthUserDetailEntityToJSONTyped(value?: AuthUserDetailEntity | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'username': value['username'],
        'email': value['email'],
        'lastLogin': value['lastLogin'] == null ? undefined : ((value['lastLogin'] as any).toISOString()),
        'lastLogout': value['lastLogout'] == null ? undefined : ((value['lastLogout'] as any).toISOString()),
        'isActive': value['isActive'],
        'isAdmin': value['isAdmin'],
        'isSpecialAccess': value['isSpecialAccess'],
        'information': AuthUserInfoToJSON(value['information']),
        'groupIds': value['groupIds'],
    };
}

