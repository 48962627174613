/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: smn@advance.ch
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProductRawMaterialCreateEntity
 */
export interface ProductRawMaterialCreateEntity {
    /**
     * 
     * @type {string}
     * @memberof ProductRawMaterialCreateEntity
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ProductRawMaterialCreateEntity
     */
    articleNo?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProductRawMaterialCreateEntity
     */
    isActive: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductRawMaterialCreateEntity
     */
    isUniqueLotNo: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductRawMaterialCreateEntity
     */
    stockTracing: ProductRawMaterialCreateEntityStockTracingEnum;
    /**
     * 
     * @type {number}
     * @memberof ProductRawMaterialCreateEntity
     */
    quantityTypeId: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof ProductRawMaterialCreateEntity
     */
    categories: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof ProductRawMaterialCreateEntity
     */
    image?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProductRawMaterialCreateEntity
     */
    supplierId: number;
    /**
     * 
     * @type {number}
     * @memberof ProductRawMaterialCreateEntity
     */
    leadTime?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProductRawMaterialCreateEntity
     */
    supplierArticleNo?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductRawMaterialCreateEntity
     */
    supplierArticleName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProductRawMaterialCreateEntity
     */
    supplierQuantityTypeId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProductRawMaterialCreateEntity
     */
    supplierQuantityFactor?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProductRawMaterialCreateEntity
     */
    moq?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProductRawMaterialCreateEntity
     */
    reserveQuantity?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProductRawMaterialCreateEntity
     */
    stockConditioning?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProductRawMaterialCreateEntity
     */
    supplierConditioning?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProductRawMaterialCreateEntity
     */
    weightPerUnit?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProductRawMaterialCreateEntity
     */
    hsCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductRawMaterialCreateEntity
     */
    originCountryId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductRawMaterialCreateEntity
     */
    incoterm?: ProductRawMaterialCreateEntityIncotermEnum | null;
}


/**
 * @export
 */
export const ProductRawMaterialCreateEntityStockTracingEnum = {
    None: 'None',
    Lot: 'Lot',
    Serial: 'Serial'
} as const;
export type ProductRawMaterialCreateEntityStockTracingEnum = typeof ProductRawMaterialCreateEntityStockTracingEnum[keyof typeof ProductRawMaterialCreateEntityStockTracingEnum];

/**
 * @export
 */
export const ProductRawMaterialCreateEntityIncotermEnum = {
    Cfr: 'CFR',
    Cif: 'CIF',
    Ddu: 'DDU',
    Exw: 'EXW',
    Fob: 'FOB'
} as const;
export type ProductRawMaterialCreateEntityIncotermEnum = typeof ProductRawMaterialCreateEntityIncotermEnum[keyof typeof ProductRawMaterialCreateEntityIncotermEnum];


/**
 * Check if a given object implements the ProductRawMaterialCreateEntity interface.
 */
export function instanceOfProductRawMaterialCreateEntity(value: object): value is ProductRawMaterialCreateEntity {
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('isActive' in value) || value['isActive'] === undefined) return false;
    if (!('isUniqueLotNo' in value) || value['isUniqueLotNo'] === undefined) return false;
    if (!('stockTracing' in value) || value['stockTracing'] === undefined) return false;
    if (!('quantityTypeId' in value) || value['quantityTypeId'] === undefined) return false;
    if (!('categories' in value) || value['categories'] === undefined) return false;
    if (!('supplierId' in value) || value['supplierId'] === undefined) return false;
    return true;
}

export function ProductRawMaterialCreateEntityFromJSON(json: any): ProductRawMaterialCreateEntity {
    return ProductRawMaterialCreateEntityFromJSONTyped(json, false);
}

export function ProductRawMaterialCreateEntityFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductRawMaterialCreateEntity {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'articleNo': json['articleNo'] == null ? undefined : json['articleNo'],
        'isActive': json['isActive'],
        'isUniqueLotNo': json['isUniqueLotNo'],
        'stockTracing': json['stockTracing'],
        'quantityTypeId': json['quantityTypeId'],
        'categories': json['categories'],
        'image': json['image'] == null ? undefined : json['image'],
        'supplierId': json['supplierId'],
        'leadTime': json['leadTime'] == null ? undefined : json['leadTime'],
        'supplierArticleNo': json['supplierArticleNo'] == null ? undefined : json['supplierArticleNo'],
        'supplierArticleName': json['supplierArticleName'] == null ? undefined : json['supplierArticleName'],
        'supplierQuantityTypeId': json['supplierQuantityTypeId'] == null ? undefined : json['supplierQuantityTypeId'],
        'supplierQuantityFactor': json['supplierQuantityFactor'] == null ? undefined : json['supplierQuantityFactor'],
        'moq': json['moq'] == null ? undefined : json['moq'],
        'reserveQuantity': json['reserveQuantity'] == null ? undefined : json['reserveQuantity'],
        'stockConditioning': json['stockConditioning'] == null ? undefined : json['stockConditioning'],
        'supplierConditioning': json['supplierConditioning'] == null ? undefined : json['supplierConditioning'],
        'weightPerUnit': json['weightPerUnit'] == null ? undefined : json['weightPerUnit'],
        'hsCode': json['hsCode'] == null ? undefined : json['hsCode'],
        'originCountryId': json['originCountryId'] == null ? undefined : json['originCountryId'],
        'incoterm': json['incoterm'] == null ? undefined : json['incoterm'],
    };
}

  export function ProductRawMaterialCreateEntityToJSON(json: any): ProductRawMaterialCreateEntity {
      return ProductRawMaterialCreateEntityToJSONTyped(json, false);
  }

  export function ProductRawMaterialCreateEntityToJSONTyped(value?: ProductRawMaterialCreateEntity | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'name': value['name'],
        'articleNo': value['articleNo'],
        'isActive': value['isActive'],
        'isUniqueLotNo': value['isUniqueLotNo'],
        'stockTracing': value['stockTracing'],
        'quantityTypeId': value['quantityTypeId'],
        'categories': value['categories'],
        'image': value['image'],
        'supplierId': value['supplierId'],
        'leadTime': value['leadTime'],
        'supplierArticleNo': value['supplierArticleNo'],
        'supplierArticleName': value['supplierArticleName'],
        'supplierQuantityTypeId': value['supplierQuantityTypeId'],
        'supplierQuantityFactor': value['supplierQuantityFactor'],
        'moq': value['moq'],
        'reserveQuantity': value['reserveQuantity'],
        'stockConditioning': value['stockConditioning'],
        'supplierConditioning': value['supplierConditioning'],
        'weightPerUnit': value['weightPerUnit'],
        'hsCode': value['hsCode'],
        'originCountryId': value['originCountryId'],
        'incoterm': value['incoterm'],
    };
}

