/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: smn@advance.ch
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StockMoveLineSimple
 */
export interface StockMoveLineSimple {
    /**
     * 
     * @type {number}
     * @memberof StockMoveLineSimple
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof StockMoveLineSimple
     */
    sourceStockLocationId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof StockMoveLineSimple
     */
    destinationStockLocationId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof StockMoveLineSimple
     */
    amount: number;
}

/**
 * Check if a given object implements the StockMoveLineSimple interface.
 */
export function instanceOfStockMoveLineSimple(value: object): value is StockMoveLineSimple {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('amount' in value) || value['amount'] === undefined) return false;
    return true;
}

export function StockMoveLineSimpleFromJSON(json: any): StockMoveLineSimple {
    return StockMoveLineSimpleFromJSONTyped(json, false);
}

export function StockMoveLineSimpleFromJSONTyped(json: any, ignoreDiscriminator: boolean): StockMoveLineSimple {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'sourceStockLocationId': json['sourceStockLocationId'] == null ? undefined : json['sourceStockLocationId'],
        'destinationStockLocationId': json['destinationStockLocationId'] == null ? undefined : json['destinationStockLocationId'],
        'amount': json['amount'],
    };
}

  export function StockMoveLineSimpleToJSON(json: any): StockMoveLineSimple {
      return StockMoveLineSimpleToJSONTyped(json, false);
  }

  export function StockMoveLineSimpleToJSONTyped(value?: StockMoveLineSimple | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'sourceStockLocationId': value['sourceStockLocationId'],
        'destinationStockLocationId': value['destinationStockLocationId'],
        'amount': value['amount'],
    };
}

