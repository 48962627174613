/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: smn@advance.ch
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StockInventoryControlMoveLineSchema
 */
export interface StockInventoryControlMoveLineSchema {
    /**
     * 
     * @type {number}
     * @memberof StockInventoryControlMoveLineSchema
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof StockInventoryControlMoveLineSchema
     */
    changeAmount: number;
    /**
     * 
     * @type {string}
     * @memberof StockInventoryControlMoveLineSchema
     */
    articleNo?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StockInventoryControlMoveLineSchema
     */
    articleName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StockInventoryControlMoveLineSchema
     */
    comment?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StockInventoryControlMoveLineSchema
     */
    lotNo?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StockInventoryControlMoveLineSchema
     */
    createdBy?: string | null;
}

/**
 * Check if a given object implements the StockInventoryControlMoveLineSchema interface.
 */
export function instanceOfStockInventoryControlMoveLineSchema(value: object): value is StockInventoryControlMoveLineSchema {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('changeAmount' in value) || value['changeAmount'] === undefined) return false;
    return true;
}

export function StockInventoryControlMoveLineSchemaFromJSON(json: any): StockInventoryControlMoveLineSchema {
    return StockInventoryControlMoveLineSchemaFromJSONTyped(json, false);
}

export function StockInventoryControlMoveLineSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): StockInventoryControlMoveLineSchema {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'changeAmount': json['changeAmount'],
        'articleNo': json['articleNo'] == null ? undefined : json['articleNo'],
        'articleName': json['articleName'] == null ? undefined : json['articleName'],
        'comment': json['comment'] == null ? undefined : json['comment'],
        'lotNo': json['lotNo'] == null ? undefined : json['lotNo'],
        'createdBy': json['createdBy'] == null ? undefined : json['createdBy'],
    };
}

  export function StockInventoryControlMoveLineSchemaToJSON(json: any): StockInventoryControlMoveLineSchema {
      return StockInventoryControlMoveLineSchemaToJSONTyped(json, false);
  }

  export function StockInventoryControlMoveLineSchemaToJSONTyped(value?: StockInventoryControlMoveLineSchema | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'changeAmount': value['changeAmount'],
        'articleNo': value['articleNo'],
        'articleName': value['articleName'],
        'comment': value['comment'],
        'lotNo': value['lotNo'],
        'createdBy': value['createdBy'],
    };
}

