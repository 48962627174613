/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: smn@advance.ch
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StockInventoryControlCreate
 */
export interface StockInventoryControlCreate {
    /**
     * 
     * @type {Date}
     * @memberof StockInventoryControlCreate
     */
    plannedDate?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof StockInventoryControlCreate
     */
    stockLocationId: number;
    /**
     * 
     * @type {string}
     * @memberof StockInventoryControlCreate
     */
    comment?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof StockInventoryControlCreate
     */
    partialInventory: boolean;
}

/**
 * Check if a given object implements the StockInventoryControlCreate interface.
 */
export function instanceOfStockInventoryControlCreate(value: object): value is StockInventoryControlCreate {
    if (!('stockLocationId' in value) || value['stockLocationId'] === undefined) return false;
    if (!('partialInventory' in value) || value['partialInventory'] === undefined) return false;
    return true;
}

export function StockInventoryControlCreateFromJSON(json: any): StockInventoryControlCreate {
    return StockInventoryControlCreateFromJSONTyped(json, false);
}

export function StockInventoryControlCreateFromJSONTyped(json: any, ignoreDiscriminator: boolean): StockInventoryControlCreate {
    if (json == null) {
        return json;
    }
    return {
        
        'plannedDate': json['plannedDate'] == null ? undefined : (new Date(json['plannedDate'])),
        'stockLocationId': json['stockLocationId'],
        'comment': json['comment'] == null ? undefined : json['comment'],
        'partialInventory': json['partialInventory'],
    };
}

  export function StockInventoryControlCreateToJSON(json: any): StockInventoryControlCreate {
      return StockInventoryControlCreateToJSONTyped(json, false);
  }

  export function StockInventoryControlCreateToJSONTyped(value?: StockInventoryControlCreate | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'plannedDate': value['plannedDate'] == null ? undefined : ((value['plannedDate'] as any).toISOString()),
        'stockLocationId': value['stockLocationId'],
        'comment': value['comment'],
        'partialInventory': value['partialInventory'],
    };
}

