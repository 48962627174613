import ClearIcon from '@mui/icons-material/Clear';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  TextField,
  Typography,
} from '@mui/material';
import Divider from '@mui/material/Divider';
import { StockLinesLineDetail } from 'api';
import ShowQuantity, { useQuantityFormat } from 'components/commons/showQuantity';
import * as React from 'react';
import {
  SelectInputProps,
  useInput,
} from 'react-admin';
import { useResourceQuery } from 'repositories/products/product';
import { useResourceStockLines, useStockLine } from 'repositories/stock/stockLines';
import { useStockShelfs } from 'repositories/stock/stockShelf';
import useTranslate from 'utils/translate';
import ResourceChooser from '../resourceChooser';
import StockLocationChooser from '../stockLocationChooser';
import styles from './stockLine.module.scss';

export interface StockLineChooserProps extends Omit<SelectInputProps, 'sx' | 'onChange'> {
  isFullWidth?: boolean;
  value?: number;
  onChange?: (value: number) => void;
  onChangeValue?: (arg0: StockLinesLineDetail) => void;

  stockLocation?: number;
  filterEmpty?: boolean;
}

export default function StockLineChooser(props: StockLineChooserProps) {
  const {
    value,
    source,
    onChange = () => ({}),
    required,
    disabled = false,
    isFullWidth = false,
    stockLocation,
    filterEmpty = true,
    onChangeValue,
    ...rest
  } = props;

  const translate = useTranslate();
  const [open, setOpen] = React.useState(false);

  const [resourceId, setResourceId] = React.useState<number | undefined>();
  const [stockLocationId, setStockLocationId] = React.useState<number | undefined>(stockLocation);

  const { data: resource } = useResourceQuery(resourceId as number, { enabled: !!resourceId });

  const { data: choices } = useResourceStockLines({
    resourceId: resourceId as number,
    stockLocationId,
    filterEmpty,
  }, { enabled: resourceId !== undefined });

  const {
    field,
  } = useInput({
    onChange,
    source: source || '',
  });
  const stockLineId: number | undefined = value !== undefined ? value : field.value;

  const { data: currentChoice } = useStockLine({ id: stockLineId as number }, { enabled: !!stockLineId });
  const formatQuantity = useQuantityFormat(currentChoice?.quantityTypeId);
  const { data: stockShelfs } = useStockShelfs();

  React.useEffect(() => {
    setResourceId(currentChoice?.resourceId);
    if (currentChoice !== undefined) {
      onChangeValue?.(currentChoice);
    }
  }, [currentChoice, onChangeValue]);

  const [disableFocus, setDisableFocus] = React.useState(false);

  const setClose = () => {
    setDisableFocus(true);
    setOpen(false);
  };

  let placeholderText = '';
  if (currentChoice) {
    // eslint-disable-next-line max-len
    placeholderText = `${currentChoice.resourceName} ${currentChoice.stockLot?.name} (${formatQuantity(currentChoice.amount)})`;
  }

  return (
    <div>
      <Box>
        <TextField
          id="placehold"
          label={translate('mai.stockLine.selectStockLine')}
          fullWidth={isFullWidth}
          disabled={disabled}
          inputProps={{
            endadornment: field.value && !required ? (
              <IconButton>
                <ClearIcon />
              </IconButton>
            ) : null,
          }}
          value={placeholderText}
          onClick={() => setOpen(true)}
          required={required}
          onFocus={() => {
            if (disableFocus) {
              setDisableFocus(false);
            } else {
              setOpen(true);
            }
          }}
          {...rest}
        // type="search"
        />

      </Box>

      <Dialog
        className={styles.stockLineChooserDialog}
        open={open}
        onClose={setClose}
      >
        <DialogTitle>
          Choose StockLine
        </DialogTitle>
        <DialogContent className="content">
          <div className="filter">
            <ResourceChooser value={resourceId} onChange={(id) => setResourceId(id)} required />
            {stockLocation === undefined && (
              <StockLocationChooser onChange={setStockLocationId} value={stockLocationId} />
            )}
          </div>

          <Divider sx={{ my: 0.5 }} />
          <div className="choices">
            <List className="list">
              {choices?.map((choice) => (

                <ListItem
                  className="choice"
                  key={choice.id}
                  onClick={() => {
                    field.onChange(choice.id);
                    setClose();
                  }}
                >
                  <Typography>
                    {choice.stockLocation.name}
                    {
                      choice.stockShelfId == null ?
                        null :
                        " / " + stockShelfs?.find(x => x.id == choice.stockShelfId)?.name
                    }
                  </Typography>
                  <Typography>{choice.stockLot.name}</Typography>
                  {choice.stockLot.supplierName && <Typography>{choice.stockLot.name}</Typography>}
                  <ShowQuantity value={choice.amount} quantityTypeId={resource?.quantityTypeId} />

                </ListItem>
              ))}
            </List>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
