
import { AxiosError } from 'axios';
import {
  CreateParams,
  CreateResult,
  DataProvider,
  HttpError,
  Identifier,
  UpdateResult,
  withLifecycleCallbacks
} from 'react-admin';
import repositories from '../repositories';

export interface ResponseGenerator {
  config?: unknown,
  data?: unknown,
  headers?: unknown,
  request?: unknown,
  status?: number,
  statusText?: string
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const convertFileToBase64 = (file: any) => new Promise((resolve, reject) => {
  if (file.rawFile instanceof File) {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(file.rawFile);
  } else {
    reject();
  }
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const prepareImageForUpload = async (params: any): Promise<unknown> => {
  let image;
  if (params.srcImage && params.srcImage.rawFile instanceof File) {
    image = await convertFileToBase64(params.srcImage);
    return {
      ...params,
      image,
    };
  }
  return params;
};

const handleError = (reason: AxiosError | HttpError | unknown) => {
  const axiosReason = reason as AxiosError;

  if (axiosReason?.toJSON) {
    const body = axiosReason.toJSON();
    throw new Error(JSON.stringify(body));
  }

  throw reason;
};

const dataProvider: DataProvider = withLifecycleCallbacks({
  getList: async (resource, params) => {
    const page = params.pagination?.page;
    const perPage = params.pagination?.perPage;
    const field = params.sort?.field;
    const order = params.sort?.order;
    try {
      const res = await repositories.get(`/${resource}`, {
        params: {
          Page: page,
          Limit: perPage,
          SortBy: field,
          Sort: order,
          ...params.filter,
        },
      });
      return ({
        data: res?.data?.list ?? [],
        total: res?.data?.paging?.total ?? 0,
        res: res ?? {},
      });
    } catch (reason) {
      return handleError(reason);
    }
  },

  getOne: async (resource, params) => {
    const id = params.id ? params.id : 0;
    try {
      const res = await repositories.get(`/${resource}/${id}`);
      return ({
        data: res?.data ?? {},
        res: res ?? {},
      });
    } catch (reason) {
      return handleError(reason);
    }
  },

  getMany: async (resource, params) => {
    try {
      const res = await repositories.get(`/${resource}`, {
        params: {
          ids: params.ids.join(','),
        },
      });
      return ({
        data: res?.data?.list ?? {},
        total: res?.data?.paging?.total ?? 0,
        res: res ?? {},
      });
    } catch (reason) {
      return handleError(reason);
    }
  },

  getManyReference: async (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const id = params.id ? params.id : 0;
    try {
      const res = await repositories.get(`/${resource}`, {
        Page: page,
        Limit: perPage,
        Field: field,
        Order: order,
        ...params.filter,
        [params.target]: id,
      });
      return ({
        data: res?.data?.list ?? [],
        total: res?.data?.paging?.total ?? 0,
        res: res ?? {},
      });
    } catch (reason) {
      return handleError(reason);
    }
  },

  create: async (resource, params: CreateParams) => {
    try {
      const res = await repositories.post(`/${resource}`, params.data);
      const data = {
        ...params.data,
        id: (res?.data?.data?.id ?? 0) as Identifier
      };
      return ({
        data,
        //res: res ?? {},
      }) as CreateResult;
    } catch (reason) {
      return handleError(reason);
    }
  },

  update: async (resource, params) => {
    const id = params.id ? params.id : 0;
    try {
      const res = await repositories.put(`/${resource}/${id}`, params.data);
      return ({
        data: params.data ?? {},
        res: res ?? {},
      }) as UpdateResult;
    } catch (reason) {
      return handleError(reason);
    }
  },

  updateMany: async (resource, params) => {
    try {
      const res = await repositories.put(`/${resource}`, params.data, {
        params: {
          ids: params.ids,
        },
      });
      return ({
        data: res.data ?? {},
        res: res ?? {},
      });
    } catch (reason) {
      return handleError(reason);
    }
  },

  delete: async (resource, params) => {
    const id = params.id ? params.id : 0;
    try {
      const res = await repositories.delete(`/${resource}/${id}`);
      return ({
        data: res.data ?? {},
        res: res ?? {},
      });
    } catch (reason) {
      return handleError(reason);
    }
  },

  deleteMany: async (resource, params) => {
    try {
      const res = await repositories.delete(`/${resource}`, {
        params: {
          ids: params.ids.join(','),
        },
      });
      return ({
        data: [],
        res: res ?? {},
      });
    } catch (reason) {
      return handleError(reason);
    }
  },
}, [
  {
    resource: 'product/raw-materials',
    beforeSave: async (params) => prepareImageForUpload(params),
  },
  {
    resource: 'product/products',
    beforeSave: async (params) => prepareImageForUpload(params),
  },
  {
    resource: 'stock/shelf',
    beforeSave: async (params) => prepareImageForUpload(params),
  },
]);
export default dataProvider;
