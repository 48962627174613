import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import IconButton from '@mui/material/IconButton';
import React from 'react';
import {
  FormDataConsumer,
  ImageField,
  ImageInput,
  ImageInputProps,
} from 'react-admin';
import { useFormContext } from 'react-hook-form';
import styles from './styles.module.scss';

interface CustomImageInputProps extends ImageInputProps {
  className?: string | undefined,
  label?: string | undefined,
  source: string,
}

export function CustomImageInput(props: CustomImageInputProps) {
  const {
    className, label, source,
  } = props;
  const { setValue } = useFormContext();

  const handleDeleteImage = () => {
    setValue(source, null, { shouldDirty: true });
  };

  return (
    <>
      <ImageInput
        source="srcImage"
        label={label}
        accept={{ "image/*": [] }}
        multiple={false}
        className={className}
      >
        <ImageField
          source="src"
          title="title"
        />
      </ImageInput>
      <FormDataConsumer>
        {({ formData }) => {
          if (!formData.srcImage && formData[source]) {
            return (
              <div className={styles['image-container']}>
                <IconButton aria-label="delete" onClick={handleDeleteImage} className={styles['delete-btn']}>
                  <RemoveCircleIcon />
                </IconButton>
                <ImageField source={source} className={styles.image} />
              </div>
            );
          }
        }}
      </FormDataConsumer>
    </>
  );
}
