/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: smn@advance.ch
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Paging } from './Paging';
import {
    PagingFromJSON,
    PagingFromJSONTyped,
    PagingToJSON,
    PagingToJSONTyped,
} from './Paging';
import type { ShipmentExportDetailSchema } from './ShipmentExportDetailSchema';
import {
    ShipmentExportDetailSchemaFromJSON,
    ShipmentExportDetailSchemaFromJSONTyped,
    ShipmentExportDetailSchemaToJSON,
    ShipmentExportDetailSchemaToJSONTyped,
} from './ShipmentExportDetailSchema';

/**
 * 
 * @export
 * @interface PaginatedListExportShipmentDetailSchema
 */
export interface PaginatedListExportShipmentDetailSchema {
    /**
     * 
     * @type {Array<ShipmentExportDetailSchema>}
     * @memberof PaginatedListExportShipmentDetailSchema
     */
    list: Array<ShipmentExportDetailSchema>;
    /**
     * 
     * @type {Paging}
     * @memberof PaginatedListExportShipmentDetailSchema
     */
    paging: Paging;
}

/**
 * Check if a given object implements the PaginatedListExportShipmentDetailSchema interface.
 */
export function instanceOfPaginatedListExportShipmentDetailSchema(value: object): value is PaginatedListExportShipmentDetailSchema {
    if (!('list' in value) || value['list'] === undefined) return false;
    if (!('paging' in value) || value['paging'] === undefined) return false;
    return true;
}

export function PaginatedListExportShipmentDetailSchemaFromJSON(json: any): PaginatedListExportShipmentDetailSchema {
    return PaginatedListExportShipmentDetailSchemaFromJSONTyped(json, false);
}

export function PaginatedListExportShipmentDetailSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaginatedListExportShipmentDetailSchema {
    if (json == null) {
        return json;
    }
    return {
        
        'list': ((json['list'] as Array<any>).map(ShipmentExportDetailSchemaFromJSON)),
        'paging': PagingFromJSON(json['paging']),
    };
}

  export function PaginatedListExportShipmentDetailSchemaToJSON(json: any): PaginatedListExportShipmentDetailSchema {
      return PaginatedListExportShipmentDetailSchemaToJSONTyped(json, false);
  }

  export function PaginatedListExportShipmentDetailSchemaToJSONTyped(value?: PaginatedListExportShipmentDetailSchema | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'list': ((value['list'] as Array<any>).map(ShipmentExportDetailSchemaToJSON)),
        'paging': PagingToJSON(value['paging']),
    };
}

