/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: smn@advance.ch
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ProductProductionOrderLineDetail } from './ProductProductionOrderLineDetail';
import {
    ProductProductionOrderLineDetailFromJSON,
    ProductProductionOrderLineDetailFromJSONTyped,
    ProductProductionOrderLineDetailToJSON,
    ProductProductionOrderLineDetailToJSONTyped,
} from './ProductProductionOrderLineDetail';
import type { ProductDetailSchema } from './ProductDetailSchema';
import {
    ProductDetailSchemaFromJSON,
    ProductDetailSchemaFromJSONTyped,
    ProductDetailSchemaToJSON,
    ProductDetailSchemaToJSONTyped,
} from './ProductDetailSchema';

/**
 * 
 * @export
 * @interface ProductProductionItemDetailSchema
 */
export interface ProductProductionItemDetailSchema {
    /**
     * 
     * @type {number}
     * @memberof ProductProductionItemDetailSchema
     */
    productId: number;
    /**
     * 
     * @type {number}
     * @memberof ProductProductionItemDetailSchema
     */
    productionOrderLineId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProductProductionItemDetailSchema
     */
    serialNo: string;
    /**
     * 
     * @type {string}
     * @memberof ProductProductionItemDetailSchema
     */
    productionNo?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProductProductionItemDetailSchema
     */
    amount: number;
    /**
     * 
     * @type {Date}
     * @memberof ProductProductionItemDetailSchema
     */
    plannedEndDate?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof ProductProductionItemDetailSchema
     */
    plannedExportDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof ProductProductionItemDetailSchema
     */
    comment?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProductProductionItemDetailSchema
     */
    id: number;
    /**
     * 
     * @type {ProductDetailSchema}
     * @memberof ProductProductionItemDetailSchema
     */
    product?: ProductDetailSchema;
    /**
     * 
     * @type {ProductProductionOrderLineDetail}
     * @memberof ProductProductionItemDetailSchema
     */
    productionOrderLine?: ProductProductionOrderLineDetail;
}

/**
 * Check if a given object implements the ProductProductionItemDetailSchema interface.
 */
export function instanceOfProductProductionItemDetailSchema(value: object): value is ProductProductionItemDetailSchema {
    if (!('productId' in value) || value['productId'] === undefined) return false;
    if (!('serialNo' in value) || value['serialNo'] === undefined) return false;
    if (!('amount' in value) || value['amount'] === undefined) return false;
    if (!('id' in value) || value['id'] === undefined) return false;
    return true;
}

export function ProductProductionItemDetailSchemaFromJSON(json: any): ProductProductionItemDetailSchema {
    return ProductProductionItemDetailSchemaFromJSONTyped(json, false);
}

export function ProductProductionItemDetailSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductProductionItemDetailSchema {
    if (json == null) {
        return json;
    }
    return {
        
        'productId': json['productId'],
        'productionOrderLineId': json['productionOrderLineId'] == null ? undefined : json['productionOrderLineId'],
        'serialNo': json['serialNo'],
        'productionNo': json['productionNo'] == null ? undefined : json['productionNo'],
        'amount': json['amount'],
        'plannedEndDate': json['plannedEndDate'] == null ? undefined : (new Date(json['plannedEndDate'])),
        'plannedExportDate': json['plannedExportDate'] == null ? undefined : (new Date(json['plannedExportDate'])),
        'comment': json['comment'] == null ? undefined : json['comment'],
        'id': json['id'],
        'product': json['product'] == null ? undefined : ProductDetailSchemaFromJSON(json['product']),
        'productionOrderLine': json['productionOrderLine'] == null ? undefined : ProductProductionOrderLineDetailFromJSON(json['productionOrderLine']),
    };
}

  export function ProductProductionItemDetailSchemaToJSON(json: any): ProductProductionItemDetailSchema {
      return ProductProductionItemDetailSchemaToJSONTyped(json, false);
  }

  export function ProductProductionItemDetailSchemaToJSONTyped(value?: ProductProductionItemDetailSchema | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'productId': value['productId'],
        'productionOrderLineId': value['productionOrderLineId'],
        'serialNo': value['serialNo'],
        'productionNo': value['productionNo'],
        'amount': value['amount'],
        'plannedEndDate': value['plannedEndDate'] == null ? undefined : ((value['plannedEndDate'] as any).toISOString()),
        'plannedExportDate': value['plannedExportDate'] == null ? undefined : ((value['plannedExportDate'] as any).toISOString()),
        'comment': value['comment'],
        'id': value['id'],
        'product': ProductDetailSchemaToJSON(value['product']),
        'productionOrderLine': ProductProductionOrderLineDetailToJSON(value['productionOrderLine']),
    };
}

