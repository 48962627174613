/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: smn@advance.ch
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Paging } from './Paging';
import {
    PagingFromJSON,
    PagingFromJSONTyped,
    PagingToJSON,
    PagingToJSONTyped,
} from './Paging';
import type { StockIncomingShipmentList } from './StockIncomingShipmentList';
import {
    StockIncomingShipmentListFromJSON,
    StockIncomingShipmentListFromJSONTyped,
    StockIncomingShipmentListToJSON,
    StockIncomingShipmentListToJSONTyped,
} from './StockIncomingShipmentList';

/**
 * 
 * @export
 * @interface PaginatedListIncomingShipment
 */
export interface PaginatedListIncomingShipment {
    /**
     * 
     * @type {Array<StockIncomingShipmentList>}
     * @memberof PaginatedListIncomingShipment
     */
    list: Array<StockIncomingShipmentList>;
    /**
     * 
     * @type {Paging}
     * @memberof PaginatedListIncomingShipment
     */
    paging: Paging;
}

/**
 * Check if a given object implements the PaginatedListIncomingShipment interface.
 */
export function instanceOfPaginatedListIncomingShipment(value: object): value is PaginatedListIncomingShipment {
    if (!('list' in value) || value['list'] === undefined) return false;
    if (!('paging' in value) || value['paging'] === undefined) return false;
    return true;
}

export function PaginatedListIncomingShipmentFromJSON(json: any): PaginatedListIncomingShipment {
    return PaginatedListIncomingShipmentFromJSONTyped(json, false);
}

export function PaginatedListIncomingShipmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaginatedListIncomingShipment {
    if (json == null) {
        return json;
    }
    return {
        
        'list': ((json['list'] as Array<any>).map(StockIncomingShipmentListFromJSON)),
        'paging': PagingFromJSON(json['paging']),
    };
}

  export function PaginatedListIncomingShipmentToJSON(json: any): PaginatedListIncomingShipment {
      return PaginatedListIncomingShipmentToJSONTyped(json, false);
  }

  export function PaginatedListIncomingShipmentToJSONTyped(value?: PaginatedListIncomingShipment | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'list': ((value['list'] as Array<any>).map(StockIncomingShipmentListToJSON)),
        'paging': PagingToJSON(value['paging']),
    };
}

