import DoneIcon from '@mui/icons-material/Done';
import DownloadIcon from '@mui/icons-material/Download';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { Button, Card, CardContent } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { FileDTO } from 'api';
import { Endpoint } from 'app/urls';
import useHasAccess from 'hooks/useHasAccess';
import React from 'react';
import { Accept } from 'react-dropzone';
import repository from 'repositories';
import FileInput from './fileUpload';
import styles from './FileView.module.scss';

export function fileUrl(endpoint: string, id: number) {
  return `/api/1.0/${endpoint}/${id}`;
}

export function FileListView(props: { endpoint: string, showPreview?: boolean }) {
  const {
    endpoint,
    showPreview,
  } = props;

  const { data: files } = useQuery({
    queryKey: [endpoint],
    queryFn: async () => {
      const data = await repository.get<FileDTO[]>(endpoint);
      return data.data;
    },
    staleTime: 0,
  });

  return (
    <div className={styles.files}>
      <div className="files">
        {files?.map((file) => (
          <div className="file" key={file.id}>
            <div className={styles.wrapPreview}>
              {
                showPreview && (
                  <div className={styles.preview}>
                    <img src={fileUrl(endpoint, file.id)} alt="" />
                  </div>
                )
              }
              <p>{file.fileName}</p>
            </div>
            <div>
              <Button target="_blank" href={fileUrl(endpoint, file.id)}><DownloadIcon /></Button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

interface FileListViewProps {
  endpoint: string,
  getUrl?: Endpoint,
  uploadUrl?: Endpoint,
  accept?: Accept,
  showPreview?: boolean,
}

export default function FileView(props: FileListViewProps) {
  const {
    getUrl,
    uploadUrl,
  } = props;
  const [isEdit, setIsEdit] = React.useState(false);
  const hasAccess = useHasAccess();
  const hasUploadAccess = uploadUrl === undefined ? true : hasAccess(uploadUrl);
  const hasShowAccess = getUrl === undefined ? true : hasAccess(getUrl);

  if (!hasShowAccess) {
    return null;
  }

  let inner;
  let header;
  if (!isEdit) {
    inner = <FileListView {...props} />;
    header = (
      <div className="title">
        <h3>Files</h3>
        <Button
          startIcon={<FileUploadIcon />}
          variant="outlined"
          onClick={() => setIsEdit(!isEdit)}
          disabled={!hasUploadAccess}
        >
          Upload
        </Button>
      </div>
    );
  } else {
    inner = <FileInput {...props} />;
    header = (
      <div className="title">
        <h3>File Upload</h3>
        <Button
          variant="outlined"
          onClick={() => setIsEdit(false)}
          startIcon={<DoneIcon />}
        >
          Done
        </Button>
      </div>
    );
  }

  return (
    <Card className={styles.fileView}>
      <CardContent>
        {header}
        {inner}
      </CardContent>
    </Card>
  );
}
