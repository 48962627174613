import { useMutation, useQuery } from '@tanstack/react-query';
import { ProfileUpdateRequest } from 'api';
import repositories from '..';
import api from '../api';

const resource = '/auth/profile';

export const useProfile = () => useQuery({
  queryKey: ['Profile'],
  queryFn: () => api.profileGet(),
});
export const useMutateProfile = () => useMutation({
  mutationKey: ['Profile'],
  mutationFn: (params: ProfileUpdateRequest) => api.profileUpdate(params),
});

export default {
  getProfile: () => repositories.get(`${resource}`),
  saveProfile: (data: any) => repositories.put(`${resource}/${data.id ?? 0}`, data),
  changePassword: (data: any) => repositories.put(`${resource}/${data.id ?? 0}/password`, data),
};
