import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import * as React from 'react';
import { Title } from 'react-admin';

export default function DashBoard() {
  return (
    <Card>
      <Title title="mai.dashboard" />
      <CardContent>
        <h1>Welcome</h1>
      </CardContent>
    </Card>
  );
};
