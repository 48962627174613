/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: smn@advance.ch
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ProductBillOfResourceLineDetail } from './ProductBillOfResourceLineDetail';
import {
    ProductBillOfResourceLineDetailFromJSON,
    ProductBillOfResourceLineDetailFromJSONTyped,
    ProductBillOfResourceLineDetailToJSON,
    ProductBillOfResourceLineDetailToJSONTyped,
} from './ProductBillOfResourceLineDetail';

/**
 * 
 * @export
 * @interface ProductBillOfResourcesDetailDTO
 */
export interface ProductBillOfResourcesDetailDTO {
    /**
     * 
     * @type {Array<ProductBillOfResourceLineDetail>}
     * @memberof ProductBillOfResourcesDetailDTO
     */
    lines: Array<ProductBillOfResourceLineDetail>;
}

/**
 * Check if a given object implements the ProductBillOfResourcesDetailDTO interface.
 */
export function instanceOfProductBillOfResourcesDetailDTO(value: object): value is ProductBillOfResourcesDetailDTO {
    if (!('lines' in value) || value['lines'] === undefined) return false;
    return true;
}

export function ProductBillOfResourcesDetailDTOFromJSON(json: any): ProductBillOfResourcesDetailDTO {
    return ProductBillOfResourcesDetailDTOFromJSONTyped(json, false);
}

export function ProductBillOfResourcesDetailDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductBillOfResourcesDetailDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'lines': ((json['lines'] as Array<any>).map(ProductBillOfResourceLineDetailFromJSON)),
    };
}

  export function ProductBillOfResourcesDetailDTOToJSON(json: any): ProductBillOfResourcesDetailDTO {
      return ProductBillOfResourcesDetailDTOToJSONTyped(json, false);
  }

  export function ProductBillOfResourcesDetailDTOToJSONTyped(value?: ProductBillOfResourcesDetailDTO | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'lines': ((value['lines'] as Array<any>).map(ProductBillOfResourceLineDetailToJSON)),
    };
}

