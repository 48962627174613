import { Typography, TypographyProps } from '@mui/material';
import { AppQuantityTypeOption } from 'api';
import * as React from 'react';
import { useQuantityType } from 'repositories/app';

function thousandSeparator(x: number | string, digits: number) {
  if (typeof (x) === 'string') {
    x = parseFloat(x);
  }
  return x.toFixed(digits).replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
}

function quantityFormat(value: number, quantityType?: AppQuantityTypeOption) {
  if (quantityType) {
    return `${thousandSeparator(value, quantityType.decimals || 0)} ${quantityType.unit}`;
  }
  return value.toLocaleString();
}

export function useQuantityFormat(quantityTypeId?: number) {
  const quantityType = useQuantityType(quantityTypeId);
  return (value: number) => quantityFormat(value, quantityType);
}

interface ShowQuantityBaseProps extends TypographyProps {
  value?: number;
  quantityType?: AppQuantityTypeOption;
}

export function ShowQuantityBase(props: ShowQuantityBaseProps) {
  const {
    value,
    quantityType,
  } = props;

  if (!quantityType || value == null) {
    return null;
  }

  return (
    <Typography
      component="span"
      variant="body2"
      display="content"
      textAlign="left"
    >
      {quantityFormat(value, quantityType)}
    </Typography>
  );
}

interface ShowQuantityProps extends TypographyProps {
  value?: number;
  quantityTypeId?: number;
}

export default function ShowQuantity(props: ShowQuantityProps) {
  const {
    value,
    quantityTypeId,
  } = props;

  const quantityType = useQuantityType(quantityTypeId);

  return <ShowQuantityBase quantityType={quantityType} value={value} />;
}
