/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: smn@advance.ch
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { BusinessPartnerCreate } from './BusinessPartnerCreate';
import {
    BusinessPartnerCreateFromJSON,
    BusinessPartnerCreateFromJSONTyped,
    BusinessPartnerCreateToJSON,
    BusinessPartnerCreateToJSONTyped,
} from './BusinessPartnerCreate';
import type { ShipmentExportBoxDetail } from './ShipmentExportBoxDetail';
import {
    ShipmentExportBoxDetailFromJSON,
    ShipmentExportBoxDetailFromJSONTyped,
    ShipmentExportBoxDetailToJSON,
    ShipmentExportBoxDetailToJSONTyped,
} from './ShipmentExportBoxDetail';

/**
 * 
 * @export
 * @interface ShipmentExportDetailSchema
 */
export interface ShipmentExportDetailSchema {
    /**
     * 
     * @type {number}
     * @memberof ShipmentExportDetailSchema
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ShipmentExportDetailSchema
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ShipmentExportDetailSchema
     */
    transportType: ShipmentExportDetailSchemaTransportTypeEnum;
    /**
     * 
     * @type {Date}
     * @memberof ShipmentExportDetailSchema
     */
    plannedDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof ShipmentExportDetailSchema
     */
    actualDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof ShipmentExportDetailSchema
     */
    trackingNo?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ShipmentExportDetailSchema
     */
    customerId: number;
    /**
     * 
     * @type {string}
     * @memberof ShipmentExportDetailSchema
     */
    comment?: string | null;
    /**
     * 
     * @type {BusinessPartnerCreate}
     * @memberof ShipmentExportDetailSchema
     */
    customer?: BusinessPartnerCreate;
    /**
     * 
     * @type {Array<ShipmentExportBoxDetail>}
     * @memberof ShipmentExportDetailSchema
     */
    boxes?: Array<ShipmentExportBoxDetail> | null;
}


/**
 * @export
 */
export const ShipmentExportDetailSchemaTransportTypeEnum = {
    Courier: 'Courier',
    Air: 'Air',
    Sea: 'Sea',
    Truck: 'Truck',
    Other: 'Other'
} as const;
export type ShipmentExportDetailSchemaTransportTypeEnum = typeof ShipmentExportDetailSchemaTransportTypeEnum[keyof typeof ShipmentExportDetailSchemaTransportTypeEnum];


/**
 * Check if a given object implements the ShipmentExportDetailSchema interface.
 */
export function instanceOfShipmentExportDetailSchema(value: object): value is ShipmentExportDetailSchema {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('transportType' in value) || value['transportType'] === undefined) return false;
    if (!('plannedDate' in value) || value['plannedDate'] === undefined) return false;
    if (!('customerId' in value) || value['customerId'] === undefined) return false;
    return true;
}

export function ShipmentExportDetailSchemaFromJSON(json: any): ShipmentExportDetailSchema {
    return ShipmentExportDetailSchemaFromJSONTyped(json, false);
}

export function ShipmentExportDetailSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShipmentExportDetailSchema {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'transportType': json['transportType'],
        'plannedDate': (new Date(json['plannedDate'])),
        'actualDate': json['actualDate'] == null ? undefined : (new Date(json['actualDate'])),
        'trackingNo': json['trackingNo'] == null ? undefined : json['trackingNo'],
        'customerId': json['customerId'],
        'comment': json['comment'] == null ? undefined : json['comment'],
        'customer': json['customer'] == null ? undefined : BusinessPartnerCreateFromJSON(json['customer']),
        'boxes': json['boxes'] == null ? undefined : ((json['boxes'] as Array<any>).map(ShipmentExportBoxDetailFromJSON)),
    };
}

  export function ShipmentExportDetailSchemaToJSON(json: any): ShipmentExportDetailSchema {
      return ShipmentExportDetailSchemaToJSONTyped(json, false);
  }

  export function ShipmentExportDetailSchemaToJSONTyped(value?: ShipmentExportDetailSchema | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'name': value['name'],
        'transportType': value['transportType'],
        'plannedDate': ((value['plannedDate']).toISOString().substring(0,10)),
        'actualDate': value['actualDate'] == null ? undefined : ((value['actualDate'] as any).toISOString()),
        'trackingNo': value['trackingNo'],
        'customerId': value['customerId'],
        'comment': value['comment'],
        'customer': BusinessPartnerCreateToJSON(value['customer']),
        'boxes': value['boxes'] == null ? undefined : ((value['boxes'] as Array<any>).map(ShipmentExportBoxDetailToJSON)),
    };
}

