/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: smn@advance.ch
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProductResourcePriceCreateEntity
 */
export interface ProductResourcePriceCreateEntity {
    /**
     * 
     * @type {number}
     * @memberof ProductResourcePriceCreateEntity
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof ProductResourcePriceCreateEntity
     */
    resourceId: number;
    /**
     * 
     * @type {Date}
     * @memberof ProductResourcePriceCreateEntity
     */
    startDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof ProductResourcePriceCreateEntity
     */
    endDate?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof ProductResourcePriceCreateEntity
     */
    price: number;
    /**
     * 
     * @type {string}
     * @memberof ProductResourcePriceCreateEntity
     */
    currencyId: string;
}

/**
 * Check if a given object implements the ProductResourcePriceCreateEntity interface.
 */
export function instanceOfProductResourcePriceCreateEntity(value: object): value is ProductResourcePriceCreateEntity {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('resourceId' in value) || value['resourceId'] === undefined) return false;
    if (!('startDate' in value) || value['startDate'] === undefined) return false;
    if (!('price' in value) || value['price'] === undefined) return false;
    if (!('currencyId' in value) || value['currencyId'] === undefined) return false;
    return true;
}

export function ProductResourcePriceCreateEntityFromJSON(json: any): ProductResourcePriceCreateEntity {
    return ProductResourcePriceCreateEntityFromJSONTyped(json, false);
}

export function ProductResourcePriceCreateEntityFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductResourcePriceCreateEntity {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'resourceId': json['resourceId'],
        'startDate': (new Date(json['startDate'])),
        'endDate': json['endDate'] == null ? undefined : (new Date(json['endDate'])),
        'price': json['price'],
        'currencyId': json['currencyId'],
    };
}

  export function ProductResourcePriceCreateEntityToJSON(json: any): ProductResourcePriceCreateEntity {
      return ProductResourcePriceCreateEntityToJSONTyped(json, false);
  }

  export function ProductResourcePriceCreateEntityToJSONTyped(value?: ProductResourcePriceCreateEntity | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'resourceId': value['resourceId'],
        'startDate': ((value['startDate']).toISOString()),
        'endDate': value['endDate'] == null ? undefined : ((value['endDate'] as any).toISOString()),
        'price': value['price'],
        'currencyId': value['currencyId'],
    };
}

