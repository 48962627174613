import { InfiniteQueryObserverResult } from '@tanstack/react-query';
import React, { useEffect, useRef } from 'react';

interface InfiniteScrollProps<T> extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  fetchNextPage: () => Promise<InfiniteQueryObserverResult<T, unknown>>;
  isLoading: boolean;
  hasNextPage: boolean;
  threshold?: number;
}

function InfiniteScroll<T>({
  fetchNextPage,
  isLoading,
  hasNextPage,
  children,
  threshold = 0,
  ...props
}: InfiniteScrollProps<T>) {
  const lastElementRef = useRef(null);

  useEffect(() => {
    if (!hasNextPage || isLoading) return;

    const observerCallback = (entries: IntersectionObserverEntry[]) => {
      if (entries[0].isIntersecting) {
        fetchNextPage();
      }
    };

    const observer = new IntersectionObserver(observerCallback, { threshold });
    const currentRef = lastElementRef.current;
    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, [isLoading, hasNextPage, fetchNextPage, threshold]);

  return (
    <div {...props}>
      {children}
      {isLoading && <div>Loading...</div>}
      <div style={{ height: '1px' }} ref={lastElementRef} />
    </div>
  );
}

export default InfiniteScroll;
