/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: smn@advance.ch
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AuthUserInfo } from './AuthUserInfo';
import {
    AuthUserInfoFromJSON,
    AuthUserInfoFromJSONTyped,
    AuthUserInfoToJSON,
    AuthUserInfoToJSONTyped,
} from './AuthUserInfo';

/**
 * 
 * @export
 * @interface AuthCreateUser
 */
export interface AuthCreateUser {
    /**
     * 
     * @type {number}
     * @memberof AuthCreateUser
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof AuthCreateUser
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof AuthCreateUser
     */
    email?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof AuthCreateUser
     */
    lastLogin?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof AuthCreateUser
     */
    lastLogout?: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof AuthCreateUser
     */
    isActive: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AuthCreateUser
     */
    isAdmin: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AuthCreateUser
     */
    isSpecialAccess: boolean;
    /**
     * 
     * @type {AuthUserInfo}
     * @memberof AuthCreateUser
     */
    information: AuthUserInfo;
    /**
     * 
     * @type {Array<string>}
     * @memberof AuthCreateUser
     */
    groupIds: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof AuthCreateUser
     */
    password: string;
}

/**
 * Check if a given object implements the AuthCreateUser interface.
 */
export function instanceOfAuthCreateUser(value: object): value is AuthCreateUser {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('username' in value) || value['username'] === undefined) return false;
    if (!('isActive' in value) || value['isActive'] === undefined) return false;
    if (!('isAdmin' in value) || value['isAdmin'] === undefined) return false;
    if (!('isSpecialAccess' in value) || value['isSpecialAccess'] === undefined) return false;
    if (!('information' in value) || value['information'] === undefined) return false;
    if (!('groupIds' in value) || value['groupIds'] === undefined) return false;
    if (!('password' in value) || value['password'] === undefined) return false;
    return true;
}

export function AuthCreateUserFromJSON(json: any): AuthCreateUser {
    return AuthCreateUserFromJSONTyped(json, false);
}

export function AuthCreateUserFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuthCreateUser {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'username': json['username'],
        'email': json['email'] == null ? undefined : json['email'],
        'lastLogin': json['lastLogin'] == null ? undefined : (new Date(json['lastLogin'])),
        'lastLogout': json['lastLogout'] == null ? undefined : (new Date(json['lastLogout'])),
        'isActive': json['isActive'],
        'isAdmin': json['isAdmin'],
        'isSpecialAccess': json['isSpecialAccess'],
        'information': AuthUserInfoFromJSON(json['information']),
        'groupIds': json['groupIds'],
        'password': json['password'],
    };
}

  export function AuthCreateUserToJSON(json: any): AuthCreateUser {
      return AuthCreateUserToJSONTyped(json, false);
  }

  export function AuthCreateUserToJSONTyped(value?: AuthCreateUser | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'username': value['username'],
        'email': value['email'],
        'lastLogin': value['lastLogin'] == null ? undefined : ((value['lastLogin'] as any).toISOString()),
        'lastLogout': value['lastLogout'] == null ? undefined : ((value['lastLogout'] as any).toISOString()),
        'isActive': value['isActive'],
        'isAdmin': value['isAdmin'],
        'isSpecialAccess': value['isSpecialAccess'],
        'information': AuthUserInfoToJSON(value['information']),
        'groupIds': value['groupIds'],
        'password': value['password'],
    };
}

