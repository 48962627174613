import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import {
  Dialog, DialogContent, DialogTitle, Grid,
} from '@mui/material';
import { ProductProductionItemSchema } from 'api';
import * as React from 'react';

import CustomDatePicker from 'components/input/date/CustomDatePicker';
import { ResourceQuantityInput } from 'components/input/quantity/resourceFields';
import {
  ArrayInput,
  Button,
  FormDataConsumer,
  ReferenceInput, required, SelectInput,
  SimpleForm, SimpleFormIterator,
  TextInput,
  useRefresh,
} from 'react-admin';
import sleep from 'utils/sleep';
import { Source } from 'utils/source';

interface ProductionItemFormProps {
  formData: ProductProductionItemSchema;
}

function ProductionItemForm(props: ProductionItemFormProps) {
  const {
    formData,
  } = props;

  const s = Source<ProductProductionItemSchema>();

  return (
    <Grid container spacing={2} rowSpacing={0.1} marginTop={2}>
      <Grid item xs={12} sm={7}>
        <ReferenceInput label="products" {...s('productId')} reference="product/products">
          <SelectInput
            optionText="name"
            fullWidth
            validate={[required()]}
            sx={{ marginTop: '0px' }}
          />
        </ReferenceInput>
      </Grid>
      <Grid item xs={12} sm={5}>
        <ResourceQuantityInput
          className=""
          required
          label="mai.incomingShipment.receive.Amount"
          {...s('amount')}
          resourceId={formData.productId}
        />
      </Grid>

      <Grid item xs={12} sm={7}>
        <TextInput
          {...s('comment')}
          fullWidth
          variant="outlined"
          helperText={false}
        />
      </Grid>

      <Grid item xs={12} sm={5}>
        <CustomDatePicker
          {...s('plannedEndDate')}
          fullWidth
          variant="outlined"
          helperText={false}
        />
      </Grid>
    </Grid>
  );
}

export default function ProductionItemsForm(props: { open?: any; setOpen?: any; }) {
  const { open, setOpen } = props;
  const refresh = useRefresh();
  const s = (source: string) => ({ source });
  const setClose = () => {
    setOpen(false);
  };
  const submit = async (data: any) => {
    console.log('submit', data);
    await sleep(4000);
    setClose();
    refresh();
  };

  return (
    <Dialog
      open={open}
      onClose={setClose}
    >
      <DialogTitle>
        Create List Production
      </DialogTitle>
      <DialogContent>
        <SimpleForm onSubmit={submit} defaultValues={{ productionItems: [{}] }}>
          <ArrayInput {...s('productionItems')}>
            <SimpleFormIterator className="form" disableClear>
              <FormDataConsumer>
                {({ scopedFormData }) => (
                  <ProductionItemForm
                    // @ts-ignore
                    formData={scopedFormData}
                  />
                )}
              </FormDataConsumer>
            </SimpleFormIterator>
          </ArrayInput>
        </SimpleForm>
      </DialogContent>
    </Dialog>

  );
}

export function BatchCreateButton() {
  const [open, setOpen] = React.useState(false);
  return (
    <>
      <Button
        onClick={() => setOpen((value) => !value)}
        label="Bulk"
        startIcon={<PlaylistAddIcon />}
      />
      <ProductionItemsForm
        setOpen={setOpen}
        open={open}
      />
    </>
  );
}
