/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: smn@advance.ch
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AppQuantityTypeOption
 */
export interface AppQuantityTypeOption {
    /**
     * 
     * @type {number}
     * @memberof AppQuantityTypeOption
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof AppQuantityTypeOption
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof AppQuantityTypeOption
     */
    decimals: number;
    /**
     * 
     * @type {string}
     * @memberof AppQuantityTypeOption
     */
    unit: string;
}

/**
 * Check if a given object implements the AppQuantityTypeOption interface.
 */
export function instanceOfAppQuantityTypeOption(value: object): value is AppQuantityTypeOption {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('decimals' in value) || value['decimals'] === undefined) return false;
    if (!('unit' in value) || value['unit'] === undefined) return false;
    return true;
}

export function AppQuantityTypeOptionFromJSON(json: any): AppQuantityTypeOption {
    return AppQuantityTypeOptionFromJSONTyped(json, false);
}

export function AppQuantityTypeOptionFromJSONTyped(json: any, ignoreDiscriminator: boolean): AppQuantityTypeOption {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'decimals': json['decimals'],
        'unit': json['unit'],
    };
}

  export function AppQuantityTypeOptionToJSON(json: any): AppQuantityTypeOption {
      return AppQuantityTypeOptionToJSONTyped(json, false);
  }

  export function AppQuantityTypeOptionToJSONTyped(value?: AppQuantityTypeOption | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'name': value['name'],
        'decimals': value['decimals'],
        'unit': value['unit'],
    };
}

