/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: smn@advance.ch
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StockPlannedMoveLine
 */
export interface StockPlannedMoveLine {
    /**
     * 
     * @type {string}
     * @memberof StockPlannedMoveLine
     */
    articleNo?: string | null;
    /**
     * 
     * @type {number}
     * @memberof StockPlannedMoveLine
     */
    resourceId: number;
    /**
     * 
     * @type {string}
     * @memberof StockPlannedMoveLine
     */
    resourceName: string;
    /**
     * 
     * @type {number}
     * @memberof StockPlannedMoveLine
     */
    quantityTypeId: number;
    /**
     * 
     * @type {number}
     * @memberof StockPlannedMoveLine
     */
    amount: number;
    /**
     * 
     * @type {string}
     * @memberof StockPlannedMoveLine
     */
    comment?: string | null;
    /**
     * 
     * @type {number}
     * @memberof StockPlannedMoveLine
     */
    actualAmount: number;
}

/**
 * Check if a given object implements the StockPlannedMoveLine interface.
 */
export function instanceOfStockPlannedMoveLine(value: object): value is StockPlannedMoveLine {
    if (!('resourceId' in value) || value['resourceId'] === undefined) return false;
    if (!('resourceName' in value) || value['resourceName'] === undefined) return false;
    if (!('quantityTypeId' in value) || value['quantityTypeId'] === undefined) return false;
    if (!('amount' in value) || value['amount'] === undefined) return false;
    if (!('actualAmount' in value) || value['actualAmount'] === undefined) return false;
    return true;
}

export function StockPlannedMoveLineFromJSON(json: any): StockPlannedMoveLine {
    return StockPlannedMoveLineFromJSONTyped(json, false);
}

export function StockPlannedMoveLineFromJSONTyped(json: any, ignoreDiscriminator: boolean): StockPlannedMoveLine {
    if (json == null) {
        return json;
    }
    return {
        
        'articleNo': json['articleNo'] == null ? undefined : json['articleNo'],
        'resourceId': json['resourceId'],
        'resourceName': json['resourceName'],
        'quantityTypeId': json['quantityTypeId'],
        'amount': json['amount'],
        'comment': json['comment'] == null ? undefined : json['comment'],
        'actualAmount': json['actualAmount'],
    };
}

  export function StockPlannedMoveLineToJSON(json: any): StockPlannedMoveLine {
      return StockPlannedMoveLineToJSONTyped(json, false);
  }

  export function StockPlannedMoveLineToJSONTyped(value?: StockPlannedMoveLine | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'articleNo': value['articleNo'],
        'resourceId': value['resourceId'],
        'resourceName': value['resourceName'],
        'quantityTypeId': value['quantityTypeId'],
        'amount': value['amount'],
        'comment': value['comment'],
        'actualAmount': value['actualAmount'],
    };
}

