/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: smn@advance.ch
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ShipmentExportLineDetail } from './ShipmentExportLineDetail';
import {
    ShipmentExportLineDetailFromJSON,
    ShipmentExportLineDetailFromJSONTyped,
    ShipmentExportLineDetailToJSON,
    ShipmentExportLineDetailToJSONTyped,
} from './ShipmentExportLineDetail';

/**
 * 
 * @export
 * @interface ShipmentExportBoxDetail
 */
export interface ShipmentExportBoxDetail {
    /**
     * 
     * @type {number}
     * @memberof ShipmentExportBoxDetail
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof ShipmentExportBoxDetail
     */
    length?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ShipmentExportBoxDetail
     */
    width?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ShipmentExportBoxDetail
     */
    height?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ShipmentExportBoxDetail
     */
    volume: number;
    /**
     * 
     * @type {number}
     * @memberof ShipmentExportBoxDetail
     */
    weight: number;
    /**
     * 
     * @type {string}
     * @memberof ShipmentExportBoxDetail
     */
    comment?: string | null;
    /**
     * 
     * @type {Array<ShipmentExportLineDetail>}
     * @memberof ShipmentExportBoxDetail
     */
    lines?: Array<ShipmentExportLineDetail> | null;
}

/**
 * Check if a given object implements the ShipmentExportBoxDetail interface.
 */
export function instanceOfShipmentExportBoxDetail(value: object): value is ShipmentExportBoxDetail {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('volume' in value) || value['volume'] === undefined) return false;
    if (!('weight' in value) || value['weight'] === undefined) return false;
    return true;
}

export function ShipmentExportBoxDetailFromJSON(json: any): ShipmentExportBoxDetail {
    return ShipmentExportBoxDetailFromJSONTyped(json, false);
}

export function ShipmentExportBoxDetailFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShipmentExportBoxDetail {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'length': json['length'] == null ? undefined : json['length'],
        'width': json['width'] == null ? undefined : json['width'],
        'height': json['height'] == null ? undefined : json['height'],
        'volume': json['volume'],
        'weight': json['weight'],
        'comment': json['comment'] == null ? undefined : json['comment'],
        'lines': json['lines'] == null ? undefined : ((json['lines'] as Array<any>).map(ShipmentExportLineDetailFromJSON)),
    };
}

  export function ShipmentExportBoxDetailToJSON(json: any): ShipmentExportBoxDetail {
      return ShipmentExportBoxDetailToJSONTyped(json, false);
  }

  export function ShipmentExportBoxDetailToJSONTyped(value?: ShipmentExportBoxDetail | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'length': value['length'],
        'width': value['width'],
        'height': value['height'],
        'volume': value['volume'],
        'weight': value['weight'],
        'comment': value['comment'],
        'lines': value['lines'] == null ? undefined : ((value['lines'] as Array<any>).map(ShipmentExportLineDetailToJSON)),
    };
}

