/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: smn@advance.ch
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ShipmentExportBoxSchema } from './ShipmentExportBoxSchema';
import {
    ShipmentExportBoxSchemaFromJSON,
    ShipmentExportBoxSchemaFromJSONTyped,
    ShipmentExportBoxSchemaToJSON,
    ShipmentExportBoxSchemaToJSONTyped,
} from './ShipmentExportBoxSchema';

/**
 * 
 * @export
 * @interface ShipmentExportSchema
 */
export interface ShipmentExportSchema {
    /**
     * 
     * @type {string}
     * @memberof ShipmentExportSchema
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ShipmentExportSchema
     */
    transportType: ShipmentExportSchemaTransportTypeEnum;
    /**
     * 
     * @type {Date}
     * @memberof ShipmentExportSchema
     */
    plannedDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof ShipmentExportSchema
     */
    actualDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof ShipmentExportSchema
     */
    trackingNo?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ShipmentExportSchema
     */
    customerId: number;
    /**
     * 
     * @type {string}
     * @memberof ShipmentExportSchema
     */
    comment?: string | null;
    /**
     * 
     * @type {Array<ShipmentExportBoxSchema>}
     * @memberof ShipmentExportSchema
     */
    boxes?: Array<ShipmentExportBoxSchema> | null;
}


/**
 * @export
 */
export const ShipmentExportSchemaTransportTypeEnum = {
    Courier: 'Courier',
    Air: 'Air',
    Sea: 'Sea',
    Truck: 'Truck',
    Other: 'Other'
} as const;
export type ShipmentExportSchemaTransportTypeEnum = typeof ShipmentExportSchemaTransportTypeEnum[keyof typeof ShipmentExportSchemaTransportTypeEnum];


/**
 * Check if a given object implements the ShipmentExportSchema interface.
 */
export function instanceOfShipmentExportSchema(value: object): value is ShipmentExportSchema {
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('transportType' in value) || value['transportType'] === undefined) return false;
    if (!('plannedDate' in value) || value['plannedDate'] === undefined) return false;
    if (!('customerId' in value) || value['customerId'] === undefined) return false;
    return true;
}

export function ShipmentExportSchemaFromJSON(json: any): ShipmentExportSchema {
    return ShipmentExportSchemaFromJSONTyped(json, false);
}

export function ShipmentExportSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShipmentExportSchema {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'transportType': json['transportType'],
        'plannedDate': (new Date(json['plannedDate'])),
        'actualDate': json['actualDate'] == null ? undefined : (new Date(json['actualDate'])),
        'trackingNo': json['trackingNo'] == null ? undefined : json['trackingNo'],
        'customerId': json['customerId'],
        'comment': json['comment'] == null ? undefined : json['comment'],
        'boxes': json['boxes'] == null ? undefined : ((json['boxes'] as Array<any>).map(ShipmentExportBoxSchemaFromJSON)),
    };
}

  export function ShipmentExportSchemaToJSON(json: any): ShipmentExportSchema {
      return ShipmentExportSchemaToJSONTyped(json, false);
  }

  export function ShipmentExportSchemaToJSONTyped(value?: ShipmentExportSchema | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'name': value['name'],
        'transportType': value['transportType'],
        'plannedDate': ((value['plannedDate']).toISOString().substring(0,10)),
        'actualDate': value['actualDate'] == null ? undefined : ((value['actualDate'] as any).toISOString()),
        'trackingNo': value['trackingNo'],
        'customerId': value['customerId'],
        'comment': value['comment'],
        'boxes': value['boxes'] == null ? undefined : ((value['boxes'] as Array<any>).map(ShipmentExportBoxSchemaToJSON)),
    };
}

