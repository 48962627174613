/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: smn@advance.ch
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Paging } from './Paging';
import {
    PagingFromJSON,
    PagingFromJSONTyped,
    PagingToJSON,
    PagingToJSONTyped,
} from './Paging';
import type { AuthGroupEntity } from './AuthGroupEntity';
import {
    AuthGroupEntityFromJSON,
    AuthGroupEntityFromJSONTyped,
    AuthGroupEntityToJSON,
    AuthGroupEntityToJSONTyped,
} from './AuthGroupEntity';

/**
 * 
 * @export
 * @interface PaginatedListGroupEntity
 */
export interface PaginatedListGroupEntity {
    /**
     * 
     * @type {Array<AuthGroupEntity>}
     * @memberof PaginatedListGroupEntity
     */
    list: Array<AuthGroupEntity>;
    /**
     * 
     * @type {Paging}
     * @memberof PaginatedListGroupEntity
     */
    paging: Paging;
}

/**
 * Check if a given object implements the PaginatedListGroupEntity interface.
 */
export function instanceOfPaginatedListGroupEntity(value: object): value is PaginatedListGroupEntity {
    if (!('list' in value) || value['list'] === undefined) return false;
    if (!('paging' in value) || value['paging'] === undefined) return false;
    return true;
}

export function PaginatedListGroupEntityFromJSON(json: any): PaginatedListGroupEntity {
    return PaginatedListGroupEntityFromJSONTyped(json, false);
}

export function PaginatedListGroupEntityFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaginatedListGroupEntity {
    if (json == null) {
        return json;
    }
    return {
        
        'list': ((json['list'] as Array<any>).map(AuthGroupEntityFromJSON)),
        'paging': PagingFromJSON(json['paging']),
    };
}

  export function PaginatedListGroupEntityToJSON(json: any): PaginatedListGroupEntity {
      return PaginatedListGroupEntityToJSONTyped(json, false);
  }

  export function PaginatedListGroupEntityToJSONTyped(value?: PaginatedListGroupEntity | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'list': ((value['list'] as Array<any>).map(AuthGroupEntityToJSON)),
        'paging': PagingToJSON(value['paging']),
    };
}

