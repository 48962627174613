import EditActions from 'components/layout/actions/edit';
import React from 'react';
import { Edit } from 'react-admin';
import ResourcePriceForm from './form/resourcePriceForm';

export default function EditResourcePriceContainer() {
  return (
    <Edit redirect="show" actions={<EditActions />}>
      <ResourcePriceForm />
    </Edit>
  );
}
