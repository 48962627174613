import ReceiptIcon from '@mui/icons-material/Receipt';
import createExportShipment from './createExportShipmentContainer';
import editExportShipment from './editExportShipmentContainer';
import exportShipmentList from './list';
import showExportShipment from './showExportShipmentContainer';

export default {
  name: 'shipment/export-shipment',
  icon: ReceiptIcon,
  list: exportShipmentList,
  create: createExportShipment,
  edit: editExportShipment,
  show: showExportShipment,
  options: {
    label: 'Export Shipments',
  },
};
