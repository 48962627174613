import { Chip } from '@mui/material';
import get from 'lodash/get';
import React from 'react';
import { useRecordContext } from 'react-admin';
import { useCategories } from 'repositories/products/categories';

interface CategoryFieldProps {
  source: string;
  // eslint-disable-next-line react/no-unused-prop-types
  sortable?: boolean
}

export default function CategoryField(props: CategoryFieldProps) {
  const {
    source,
  } = props;
  const { data: categories } = useCategories();

  const record = useRecordContext(props);
  const categoryIds: number[] | undefined = get(record, source);

  const selectedCategories = categoryIds?.map((catId: number) => categories?.find((cat) => cat.id === catId));
  const sortedCategories = selectedCategories?.sort((cat1, cat2) => {
    if (cat1?.name && cat2?.name && cat1.name < cat2.name) return -1;
    return 1;
  });
  const categoryChips = sortedCategories?.map((cat) => (
    <Chip key={cat?.id} label={cat?.name} style={cat?.color ? { backgroundColor: cat?.color } : undefined} />
  ));

  return (
    <div>
      {categoryChips}
    </div>
  );
}
