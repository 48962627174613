import { Popover, Typography } from '@mui/material';
import { ProductResourcePriceDetailEntity } from 'api';
import clsx from 'clsx';
import get from 'lodash/get';
import * as React from 'react';
import { Datagrid, DateField, Identifier, useRecordContext } from 'react-admin';
import { useCurrency } from 'repositories/app';
import { usePrices } from 'repositories/products/prices';
import { Source } from 'utils/source';
import PriceField from './priceField';
import styles from './priceField.module.scss';

interface PricesFieldProps {
  source?: string;
  currencySource?: string;
  decimals?: number;
  // eslint-disable-next-line react/no-unused-prop-types
  label?: string;
}

interface PriceListProps {
  resourceId?: Identifier;
}

function PriceList(props: PriceListProps) {
  const p = Source<ProductResourcePriceDetailEntity>();
  const resourcePrices = usePrices({
    resourceId: props.resourceId as number
  }, { enabled: !!props.resourceId })

  // TODO: make infiniteList, show different empty message, etc
  return (
    <Datagrid resource='prices' bulkActionButtons={false} data={resourcePrices.data?.list || []}>
      <DateField {...p('startDate')} />
      <DateField {...p('endDate')} />
      <PriceField {...p('price')} currencySource={p('currencyId').source} />
    </Datagrid>
  )
}

export default function PricesField(props: PricesFieldProps) {
  const {
    source,
    currencySource,
    decimals,
  } = props;

  const record = useRecordContext();
  let price = get(record, source || 'price');
  const currencyId = get(record, currencySource || 'currency');

  const currency = useCurrency(currencyId);
  const [anchorEl, setAnchorEl] = React.useState<HTMLSpanElement | null>(null);

  if (decimals != null) {
    price = price.ToFixed(decimals);
  }

  const open = Boolean(anchorEl);
  // const open = true;

  let data = [
    <span key="price">{price}</span>,
  ];

  if (currency) {
    data.push(<span key="currency">{currency.symbol}</span>);

    if (currency.direction < 0) {
      data = data.reverse();
    }
  }

  return (
    <div className={clsx(styles.preview, open && 'active')}>
      <Typography
        variant="body2"
        className={styles.show_price}
        onMouseEnter={(event) => setAnchorEl(event.currentTarget)}
        onMouseLeave={() => setAnchorEl(null)}
      >
        <div>
          {data}
          <Popover
            className={styles.preview_box}
            anchorEl={anchorEl}
            open={open}
            disableRestoreFocus
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <PriceList resourceId={record?.id} />
          </Popover>
        </div>
      </Typography>
    </div>
  );
}
