import ChecklistIcon from '@mui/icons-material/Checklist';
import { Grid } from '@mui/material';
import { StockInventoryControlDetail, StockInventoryControlMoveLineSchema } from 'api';
import urls from 'app/urls';
import ShowDate from 'components/commons/showDate';
import ShowLabel from 'components/commons/showLabel';
import ShowTitle from 'components/commons/showTitle';
import Loading from 'components/shared/loading';
import useHasAccess from 'hooks/useHasAccess';
import * as React from 'react';
import {
  ArrayField,
  Button,
  Confirm,
  Datagrid,
  EditButton,
  HttpError,
  ListButton,
  Show,
  TabbedShowLayout,
  TextField,
  TopToolbar,
  useNotify,
  useRecordContext,
  useRefresh,
} from 'react-admin';
import { Link } from 'react-router-dom';
import api from 'repositories/api';
import { useInventoryControlLines } from 'repositories/stock/inventoryControls';
import formatString from 'utils/formatString';
import { Source } from 'utils/source';
import { InventoryControlTable } from './lines/inventoryControlConfirmList';

function InventoryControlLineButton() {
  const hasAccess = useHasAccess();
  const inventory = useRecordContext<StockInventoryControlDetail>();
  return (
    <Button
      startIcon={<ChecklistIcon />}
      label="resources.stock/inventoryControl.fields.addInventoryLine"
      component={Link}
      to={`/${formatString(urls.InventoryControl_GetLines.url, { id: inventory?.id?.toString() as string })}`}
      disabled={
        inventory?.id == null
        || !hasAccess(urls.InventoryControl_AddLine)
        || !!inventory?.applyDate
      }
    />
  );
}

function ApplyButton() {
  const [open, setOpen] = React.useState(false);
  const record = useRecordContext<StockInventoryControlDetail>();
  const refresh = useRefresh();
  const notify = useNotify();

  const handleConfirm = async () => {
    setOpen(false);

    try {
      const response = await api.inventoryControlApply({ id: record?.id as number });

      if (response.code === 200) {
        notify('mai.stockMove.apply_success', { type: 'success' });
        refresh();
      } else {
        notify('mai.stockMove.apply_failed', { type: 'warning' });
      }
    } catch (e) {
      if (e instanceof HttpError) {
        notify(e.message, { type: 'error' });
      }
    }
  };

  return (
    <>
      <Button
        variant="contained"
        label="mai.stockMove.apply_btn"
        sx={{ float: 'right' }}
        onClick={() => setOpen(true)}
        disabled={!!record?.applyDate || !record?.finishedDate}
      />
      <Confirm
        isOpen={open}
        title="mai.stockMove.apply_confirm_title"
        content="mai.stockMove.apply_confirm_content"
        onConfirm={handleConfirm}
        onClose={() => setOpen(false)}
      />
    </>
  );
}

function InventoryControlActions() {
  return (
    <TopToolbar>
      <InventoryControlLineButton />
      <ApplyButton />
      <EditButton />
      <ListButton />
    </TopToolbar>
  );
}

function LinesShow() {
  const record = useRecordContext<StockInventoryControlDetail>();
  const { data } = useInventoryControlLines({ id: record?.id as number }, { enabled: !!record });

  if (!data) {
    return <Loading />;
  }

  return <InventoryControlTable lines={data} />;
}

interface StockMoveTabProps {
  label: string;
  source: string;
  backgroundColor: string;
}

function StockMoveTab(props: StockMoveTabProps) {
  const {
    label,
    source,
    backgroundColor,
  } = props;
  const sline = Source<StockInventoryControlMoveLineSchema>();

  return (
    <Grid
      item
      xs={10}
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div
        style={{
          backgroundColor,
          padding: '5px 20px',
          marginTop: '20px',
          borderRadius: '3px',
        }}
      >
        {label}
      </div>
      <Grid container spacing={2} rowSpacing={0.5}>
        <Grid item xs={1} />
        <Grid item xs={10}>
          <ArrayField source={source}>
            <Datagrid
              bulkActionButtons={false}
              sx={{ maxHeight: '200px', overflowY: 'auto', width: 'fit-content' }}
            >
              <TextField
                {...sline('articleNo')}
                label="resources.stock/inventoryControlLine.fields.articleNo"
              />
              <TextField
                {...sline('articleName')}
                label="resources.stock/inventoryControlLine.fields.articleName"
              />
              <TextField
                {...sline('lotNo')}
                label="resources.stock/inventoryControlLine.fields.lotNo"
              />
              <TextField
                {...sline('changeAmount')}
                label="resources.stock/inventoryControlLine.fields.changeAmount"
              />
            </Datagrid>
          </ArrayField>
        </Grid>
        <Grid item xs={1} />
      </Grid>
    </Grid>
  );
}

function ShowLayout() {
  const record = useRecordContext<StockInventoryControlDetail>();
  const s = Source<StockInventoryControlDetail>();

  return (
    <TabbedShowLayout>
      <TabbedShowLayout.Tab label="summary">
        <Grid container rowSpacing={1} spacing={1} sx={{ marginLeft: '3px', marginTop: '3px', marginBottom: '10px' }}>
          <Grid item xs={12} md={12}>
            <ShowTitle />
          </Grid>

          <Grid item xs={2} />
          <Grid item xs={4}>
            <ShowLabel label="resources.stock/inventoryControl.fields.plannedDate" />
            <ShowDate fontSize={18} {...s('plannedDate')} />
          </Grid>
          <Grid item xs={4}>
            <ShowLabel label="resources.stock/inventoryControl.fields.finishedDate" />
            <ShowDate fontSize={18} {...s('finishedDate')} showTime />
          </Grid>
          <Grid item xs={2} />

          <Grid item xs={2} />
          <Grid item xs={4}>
            <ShowLabel label="resources.stock/inventoryControl.fields.stockLocationName" />
            <TextField fontSize={18} {...s('stockLocationName')} />
          </Grid>
          <Grid item xs={4}>
            <ShowLabel label="resources.stock/inventoryControl.fields.comment" />
            <TextField fontSize={18} {...s('comment')} />
          </Grid>
        </Grid>
      </TabbedShowLayout.Tab>
      <TabbedShowLayout.Tab label="Lines">
        <LinesShow />
      </TabbedShowLayout.Tab>
      <TabbedShowLayout.Tab label="Incoming" disabled={!record || !record?.finishedDate}>
        <StockMoveTab
          label="Incoming"
          {...s('stockMoveIn')}
          backgroundColor="#5ab600"
        />
      </TabbedShowLayout.Tab>
      <TabbedShowLayout.Tab label="Leaving" disabled={!record || !record?.finishedDate}>
        <StockMoveTab
          label="Leaving"
          {...s('stockMoveOut')}
          backgroundColor="#ff5c5c"
        />
      </TabbedShowLayout.Tab>
    </TabbedShowLayout>
  );
}

export default function () {
  return (
    <Show actions={<InventoryControlActions />}>
      <ShowLayout />
    </Show>
  );
}
