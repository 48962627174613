import {
  Grid,
} from '@mui/material';
import { ShipmentExportLineSchema } from 'api';
import ProductionItemInput from 'components/input/reference/productionItem';
import React from 'react';
import {
  ArrayInput,
  SimpleFormIterator, TextInput,
} from 'react-admin';
import { Source } from 'utils/source';

function ExportShipmentLineForm() {
  const s = Source<ShipmentExportLineSchema>();

  return (
    <Grid container spacing={2} marginTop="10px" marginBottom="10px">
      <Grid item md={2} />
      <Grid item md={8}>
        <ProductionItemInput
          required
          {...s('itemId')}
        />
      </Grid>
      <Grid item md={2} />
      <Grid item md={2} />
      <Grid item md={8}>
        <TextInput
          {...s('amount')}
          fullWidth
          variant="outlined"
          helperText={false}
          type="number"
        />
      </Grid>
      <Grid item md={2} />
    </Grid>
  );
}

interface ExportShipmentLinesFormProps {
  source: string;
}

export default function ExportShipmentLinesForm(props: ExportShipmentLinesFormProps) {
  const {
    source,
  } = props;
  return (
    <ArrayInput source={source}>
      <SimpleFormIterator className="form" disableClear>
        <ExportShipmentLineForm />
      </SimpleFormIterator>
    </ArrayInput>
  );
}
