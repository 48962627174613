import { Grid } from '@mui/material';
import { ProductResourcePriceCreateEntity } from 'api';
import ResourceChooser from 'components/chooser/resourceChooser';
import CustomDatePicker from 'components/input/date/CustomDatePicker';
import PriceInput from 'components/input/priceInput';
import * as React from 'react';
import {
  FormDataConsumer,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
} from 'react-admin';
import { useCurrencies } from 'repositories/app';
import { Source } from 'utils/source';

export default function ResourcePriceForm() {
  const s = Source<ProductResourcePriceCreateEntity>();
  const { data: currencies } = useCurrencies();

  return (
    <SimpleForm>
      <Grid container spacing={2} marginTop="10px" marginBottom="10px">
        <Grid item xs={2} md={1} />
        <Grid item xs={4} md={5}>
          <ResourceChooser
            {...s('resourceId')}
            required
            className="resourcesSelect"
            label="resources.billOfResources.fields.resources"
          />
        </Grid>
        <Grid item xs={4} md={5}>
          <SelectInput
            {...s('currencyId')}
            choices={currencies ?? []}
            validate={[required()]}
            fullWidth
            margin="none"
            variant="outlined"
            helperText={false}
          />
        </Grid>
        <Grid item xs={2} md={1} />

        <Grid item xs={2} md={1} />
        <Grid item xs={4} md={5}>
          <FormDataConsumer sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
          >
            {({ formData }) => <PriceInput {...s('price')} currencyId={formData.currencyId} />}
          </FormDataConsumer>
        </Grid>
        <Grid item xs={4} md={5}>
          
        </Grid>
        <Grid item xs={2} md={1} />

        <Grid item xs={2} md={1} />
        <Grid item xs={4} md={5}>
          <CustomDatePicker
            {...s('startDate')}
            validate={[required()]}
            fullWidth
            variant="outlined"
            helperText={false}
          />
        </Grid>
        <Grid item xs={4} md={5}>
          <CustomDatePicker
            {...s('endDate')}
            fullWidth
            variant="outlined"
            helperText={false}
          />
        </Grid>
        <Grid item xs={2} md={1} />
      </Grid>
    </SimpleForm>
  );
}
