/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: smn@advance.ch
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ProductResourceBaseSchema } from './ProductResourceBaseSchema';
import {
    ProductResourceBaseSchemaFromJSON,
    ProductResourceBaseSchemaFromJSONTyped,
    ProductResourceBaseSchemaToJSON,
    ProductResourceBaseSchemaToJSONTyped,
} from './ProductResourceBaseSchema';

/**
 * 
 * @export
 * @interface ProductResourcePriceDetailEntity
 */
export interface ProductResourcePriceDetailEntity {
    /**
     * 
     * @type {number}
     * @memberof ProductResourcePriceDetailEntity
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof ProductResourcePriceDetailEntity
     */
    resourceId: number;
    /**
     * 
     * @type {Date}
     * @memberof ProductResourcePriceDetailEntity
     */
    startDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof ProductResourcePriceDetailEntity
     */
    endDate?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof ProductResourcePriceDetailEntity
     */
    price: number;
    /**
     * 
     * @type {string}
     * @memberof ProductResourcePriceDetailEntity
     */
    currencyId: string;
    /**
     * 
     * @type {ProductResourceBaseSchema}
     * @memberof ProductResourcePriceDetailEntity
     */
    resource?: ProductResourceBaseSchema;
}

/**
 * Check if a given object implements the ProductResourcePriceDetailEntity interface.
 */
export function instanceOfProductResourcePriceDetailEntity(value: object): value is ProductResourcePriceDetailEntity {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('resourceId' in value) || value['resourceId'] === undefined) return false;
    if (!('startDate' in value) || value['startDate'] === undefined) return false;
    if (!('price' in value) || value['price'] === undefined) return false;
    if (!('currencyId' in value) || value['currencyId'] === undefined) return false;
    return true;
}

export function ProductResourcePriceDetailEntityFromJSON(json: any): ProductResourcePriceDetailEntity {
    return ProductResourcePriceDetailEntityFromJSONTyped(json, false);
}

export function ProductResourcePriceDetailEntityFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductResourcePriceDetailEntity {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'resourceId': json['resourceId'],
        'startDate': (new Date(json['startDate'])),
        'endDate': json['endDate'] == null ? undefined : (new Date(json['endDate'])),
        'price': json['price'],
        'currencyId': json['currencyId'],
        'resource': json['resource'] == null ? undefined : ProductResourceBaseSchemaFromJSON(json['resource']),
    };
}

  export function ProductResourcePriceDetailEntityToJSON(json: any): ProductResourcePriceDetailEntity {
      return ProductResourcePriceDetailEntityToJSONTyped(json, false);
  }

  export function ProductResourcePriceDetailEntityToJSONTyped(value?: ProductResourcePriceDetailEntity | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'resourceId': value['resourceId'],
        'startDate': ((value['startDate']).toISOString()),
        'endDate': value['endDate'] == null ? undefined : ((value['endDate'] as any).toISOString()),
        'price': value['price'],
        'currencyId': value['currencyId'],
        'resource': ProductResourceBaseSchemaToJSON(value['resource']),
    };
}

