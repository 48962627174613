import { StockShelfSearchRequest } from 'api';
import api from 'repositories/api';
import { getApiQuery } from 'repositories/base';

export const useShelf = getApiQuery(api.stockShelfGet);
export const useStockShelfs = getApiQuery(
  async (data?: StockShelfSearchRequest) => {
    const result = await api.stockShelfSearch({ ...data, limit: 9999 });
    return result.list;
  }
);
export default useShelf;
