/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: smn@advance.ch
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SystemTupleInt64Decimal } from './SystemTupleInt64Decimal';
import {
    SystemTupleInt64DecimalFromJSON,
    SystemTupleInt64DecimalFromJSONTyped,
    SystemTupleInt64DecimalToJSON,
    SystemTupleInt64DecimalToJSONTyped,
} from './SystemTupleInt64Decimal';

/**
 * 
 * @export
 * @interface PurchasingCreateFromBalanceRequest
 */
export interface PurchasingCreateFromBalanceRequest {
    /**
     * 
     * @type {string}
     * @memberof PurchasingCreateFromBalanceRequest
     */
    prefix: string;
    /**
     * 
     * @type {Date}
     * @memberof PurchasingCreateFromBalanceRequest
     */
    plannedDate: Date;
    /**
     * 
     * @type {Array<SystemTupleInt64Decimal>}
     * @memberof PurchasingCreateFromBalanceRequest
     */
    balance: Array<SystemTupleInt64Decimal>;
}

/**
 * Check if a given object implements the PurchasingCreateFromBalanceRequest interface.
 */
export function instanceOfPurchasingCreateFromBalanceRequest(value: object): value is PurchasingCreateFromBalanceRequest {
    if (!('prefix' in value) || value['prefix'] === undefined) return false;
    if (!('plannedDate' in value) || value['plannedDate'] === undefined) return false;
    if (!('balance' in value) || value['balance'] === undefined) return false;
    return true;
}

export function PurchasingCreateFromBalanceRequestFromJSON(json: any): PurchasingCreateFromBalanceRequest {
    return PurchasingCreateFromBalanceRequestFromJSONTyped(json, false);
}

export function PurchasingCreateFromBalanceRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PurchasingCreateFromBalanceRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'prefix': json['prefix'],
        'plannedDate': (new Date(json['plannedDate'])),
        'balance': ((json['balance'] as Array<any>).map(SystemTupleInt64DecimalFromJSON)),
    };
}

  export function PurchasingCreateFromBalanceRequestToJSON(json: any): PurchasingCreateFromBalanceRequest {
      return PurchasingCreateFromBalanceRequestToJSONTyped(json, false);
  }

  export function PurchasingCreateFromBalanceRequestToJSONTyped(value?: PurchasingCreateFromBalanceRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'prefix': value['prefix'],
        'plannedDate': ((value['plannedDate']).toISOString()),
        'balance': ((value['balance'] as Array<any>).map(SystemTupleInt64DecimalToJSON)),
    };
}

