import React from 'react';
import { Create } from 'react-admin';
import ExportShipmentForm from './form/exportShipmentForm';

export default function CreateExportShipmentContainer() {
  return (
    <Create redirect="show">
      <ExportShipmentForm />
    </Create>
  );
}
