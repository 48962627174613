/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: smn@advance.ch
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProductResourceDetailSchema
 */
export interface ProductResourceDetailSchema {
    /**
     * 
     * @type {string}
     * @memberof ProductResourceDetailSchema
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ProductResourceDetailSchema
     */
    articleNo?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProductResourceDetailSchema
     */
    isActive: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductResourceDetailSchema
     */
    isUniqueLotNo: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductResourceDetailSchema
     */
    stockTracing: ProductResourceDetailSchemaStockTracingEnum;
    /**
     * 
     * @type {number}
     * @memberof ProductResourceDetailSchema
     */
    quantityTypeId: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof ProductResourceDetailSchema
     */
    categories: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof ProductResourceDetailSchema
     */
    image?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProductResourceDetailSchema
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ProductResourceDetailSchema
     */
    currencyId?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProductResourceDetailSchema
     */
    price?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProductResourceDetailSchema
     */
    description: string;
}


/**
 * @export
 */
export const ProductResourceDetailSchemaStockTracingEnum = {
    None: 'None',
    Lot: 'Lot',
    Serial: 'Serial'
} as const;
export type ProductResourceDetailSchemaStockTracingEnum = typeof ProductResourceDetailSchemaStockTracingEnum[keyof typeof ProductResourceDetailSchemaStockTracingEnum];


/**
 * Check if a given object implements the ProductResourceDetailSchema interface.
 */
export function instanceOfProductResourceDetailSchema(value: object): value is ProductResourceDetailSchema {
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('isActive' in value) || value['isActive'] === undefined) return false;
    if (!('isUniqueLotNo' in value) || value['isUniqueLotNo'] === undefined) return false;
    if (!('stockTracing' in value) || value['stockTracing'] === undefined) return false;
    if (!('quantityTypeId' in value) || value['quantityTypeId'] === undefined) return false;
    if (!('categories' in value) || value['categories'] === undefined) return false;
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('description' in value) || value['description'] === undefined) return false;
    return true;
}

export function ProductResourceDetailSchemaFromJSON(json: any): ProductResourceDetailSchema {
    return ProductResourceDetailSchemaFromJSONTyped(json, false);
}

export function ProductResourceDetailSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductResourceDetailSchema {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'articleNo': json['articleNo'] == null ? undefined : json['articleNo'],
        'isActive': json['isActive'],
        'isUniqueLotNo': json['isUniqueLotNo'],
        'stockTracing': json['stockTracing'],
        'quantityTypeId': json['quantityTypeId'],
        'categories': json['categories'],
        'image': json['image'] == null ? undefined : json['image'],
        'id': json['id'],
        'currencyId': json['currencyId'] == null ? undefined : json['currencyId'],
        'price': json['price'] == null ? undefined : json['price'],
        'description': json['description'],
    };
}

  export function ProductResourceDetailSchemaToJSON(json: any): ProductResourceDetailSchema {
      return ProductResourceDetailSchemaToJSONTyped(json, false);
  }

  export function ProductResourceDetailSchemaToJSONTyped(value?: ProductResourceDetailSchema | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'name': value['name'],
        'articleNo': value['articleNo'],
        'isActive': value['isActive'],
        'isUniqueLotNo': value['isUniqueLotNo'],
        'stockTracing': value['stockTracing'],
        'quantityTypeId': value['quantityTypeId'],
        'categories': value['categories'],
        'image': value['image'],
        'id': value['id'],
        'currencyId': value['currencyId'],
        'price': value['price'],
        'description': value['description'],
    };
}

