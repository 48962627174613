/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: smn@advance.ch
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StockInventoryControlList
 */
export interface StockInventoryControlList {
    /**
     * 
     * @type {number}
     * @memberof StockInventoryControlList
     */
    id: number;
    /**
     * 
     * @type {Date}
     * @memberof StockInventoryControlList
     */
    plannedDate?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof StockInventoryControlList
     */
    finishedDate?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof StockInventoryControlList
     */
    applyDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof StockInventoryControlList
     */
    comment?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StockInventoryControlList
     */
    stockMoveInFromName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StockInventoryControlList
     */
    stockMoveInToName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StockInventoryControlList
     */
    stockMoveOutFromName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StockInventoryControlList
     */
    stockMoveOutToName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StockInventoryControlList
     */
    stockLocationName?: string | null;
}

/**
 * Check if a given object implements the StockInventoryControlList interface.
 */
export function instanceOfStockInventoryControlList(value: object): value is StockInventoryControlList {
    if (!('id' in value) || value['id'] === undefined) return false;
    return true;
}

export function StockInventoryControlListFromJSON(json: any): StockInventoryControlList {
    return StockInventoryControlListFromJSONTyped(json, false);
}

export function StockInventoryControlListFromJSONTyped(json: any, ignoreDiscriminator: boolean): StockInventoryControlList {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'plannedDate': json['plannedDate'] == null ? undefined : (new Date(json['plannedDate'])),
        'finishedDate': json['finishedDate'] == null ? undefined : (new Date(json['finishedDate'])),
        'applyDate': json['applyDate'] == null ? undefined : (new Date(json['applyDate'])),
        'comment': json['comment'] == null ? undefined : json['comment'],
        'stockMoveInFromName': json['stockMoveInFromName'] == null ? undefined : json['stockMoveInFromName'],
        'stockMoveInToName': json['stockMoveInToName'] == null ? undefined : json['stockMoveInToName'],
        'stockMoveOutFromName': json['stockMoveOutFromName'] == null ? undefined : json['stockMoveOutFromName'],
        'stockMoveOutToName': json['stockMoveOutToName'] == null ? undefined : json['stockMoveOutToName'],
        'stockLocationName': json['stockLocationName'] == null ? undefined : json['stockLocationName'],
    };
}

  export function StockInventoryControlListToJSON(json: any): StockInventoryControlList {
      return StockInventoryControlListToJSONTyped(json, false);
  }

  export function StockInventoryControlListToJSONTyped(value?: StockInventoryControlList | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'plannedDate': value['plannedDate'] == null ? undefined : ((value['plannedDate'] as any).toISOString()),
        'finishedDate': value['finishedDate'] == null ? undefined : ((value['finishedDate'] as any).toISOString()),
        'applyDate': value['applyDate'] == null ? undefined : ((value['applyDate'] as any).toISOString()),
        'comment': value['comment'],
        'stockMoveInFromName': value['stockMoveInFromName'],
        'stockMoveInToName': value['stockMoveInToName'],
        'stockMoveOutFromName': value['stockMoveOutFromName'],
        'stockMoveOutToName': value['stockMoveOutToName'],
        'stockLocationName': value['stockLocationName'],
    };
}

