import { ProductsSearchRequest } from 'api';
import { CategoryInput } from 'components/chooser/categoryChoose';
import CustomSearchInput from 'components/commons/customSearchInput';
import EnumSelectField from 'components/input/enumSelectField';
import React from 'react';
import {
  BooleanInput,
  SelectInput,
} from 'react-admin';
import { useCurrencies, useEnumQuery } from 'repositories/app';
import { StockTracingChoices } from 'repositories/products/product';
import { Source } from 'utils/source';

const s = Source<ProductsSearchRequest>();
interface SourceProps {
  source: string;
  placeholder?: string;
}

function SelectCurrencies({ source }: SourceProps) {
  const { data } = useCurrencies();

  return (
    <SelectInput
      source={source}
      choices={data ?? []}
      variant="outlined"
    />
  );
}

function SelectQuantityTypes({ source }: SourceProps) {
  const { data } = useEnumQuery();

  if (!data) return null;

  return (
    <SelectInput
      source={source}
      choices={data.quantityTypes}
      variant="outlined"
    />
  );
}

function SelectStockTracing({ source }: SourceProps) {
  const { data } = useEnumQuery();
  if (!data) return null;

  return (
    <EnumSelectField
      source={source}
      choices={StockTracingChoices}
    />
  );
}

function SelectStates({ source }: SourceProps) {
  const { data } = useEnumQuery();
  if (!data) return null;

  return (
    <BooleanInput
      source={source}
      variant="outlined"
    />
  );
}

/* eslint-disable react/jsx-key */
export default [
  <CustomSearchInput {...s('query')} alwaysOn placeholder_i18n="mai.keyword" />,
  <SelectStates {...s('isActive')} placeholder="Is Active" />,
  <CategoryInput label="Category" {...s('categoryIds')} />,
  <SelectCurrencies {...s('currencyId')} placeholder="Currencies" />,
  <SelectQuantityTypes {...s('quantityTypeId')} placeholder="Quantity Types" />,
  <SelectStockTracing {...s('stockTracingCode')} placeholder="Stock Tracing" />,
  <SelectStates {...s('isReadyToSell')} placeholder="Is Ready To Sell" />,
];
