
import * as React from 'react';
import {
  AppBar,
  LoadingIndicator,
  TitlePortal,
} from 'react-admin';
import UserMenu from './userMenu';

export default function CustomAppBar() {
  return (
    <AppBar
      userMenu={<UserMenu />}
      toolbar={
        <LoadingIndicator />
      }
    >
      <TitlePortal />
    </AppBar>
  );
}
