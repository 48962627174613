import { ShipmentExportDetailSchema } from 'api';
import List from 'components/list/List';
import Datagrid from 'components/list/dataGrid';
import * as React from 'react';
import { DateField, TextField } from 'react-admin';
import { Source } from 'utils/source';
import filters from './filters';

export default function () {
  const s = Source<ShipmentExportDetailSchema>();
  return (
    <List
      filters={filters}
    >
      <Datagrid rowClick="show">
        <TextField {...s('name')} />
        <TextField {...s('transportType')} />
        <TextField {...s('trackingNo')} />
        <DateField {...s('actualDate')} />
        <DateField {...s('plannedDate')} />
        <TextField {...s('customer.name')} sortBy="customerName" />
        <TextField {...s('comment')} />
      </Datagrid>
    </List>
  );
}
