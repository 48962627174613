/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: smn@advance.ch
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PurchasingPurchaseOrderLineDetail } from './PurchasingPurchaseOrderLineDetail';
import {
    PurchasingPurchaseOrderLineDetailFromJSON,
    PurchasingPurchaseOrderLineDetailFromJSONTyped,
    PurchasingPurchaseOrderLineDetailToJSON,
    PurchasingPurchaseOrderLineDetailToJSONTyped,
} from './PurchasingPurchaseOrderLineDetail';

/**
 * 
 * @export
 * @interface PurchasingPurchaseOrderDetail
 */
export interface PurchasingPurchaseOrderDetail {
    /**
     * 
     * @type {number}
     * @memberof PurchasingPurchaseOrderDetail
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof PurchasingPurchaseOrderDetail
     */
    state: PurchasingPurchaseOrderDetailStateEnum;
    /**
     * 
     * @type {string}
     * @memberof PurchasingPurchaseOrderDetail
     */
    confirmationState: PurchasingPurchaseOrderDetailConfirmationStateEnum;
    /**
     * 
     * @type {number}
     * @memberof PurchasingPurchaseOrderDetail
     */
    doneRatio: number;
    /**
     * 
     * @type {string}
     * @memberof PurchasingPurchaseOrderDetail
     */
    deliveryAddress: PurchasingPurchaseOrderDetailDeliveryAddressEnum;
    /**
     * 
     * @type {string}
     * @memberof PurchasingPurchaseOrderDetail
     */
    orderSequence?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PurchasingPurchaseOrderDetail
     */
    businessPartner: string;
    /**
     * 
     * @type {number}
     * @memberof PurchasingPurchaseOrderDetail
     */
    businessPartnerId: number;
    /**
     * 
     * @type {Date}
     * @memberof PurchasingPurchaseOrderDetail
     */
    plannedDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof PurchasingPurchaseOrderDetail
     */
    confirmationDate?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof PurchasingPurchaseOrderDetail
     */
    createdAt: Date;
    /**
     * 
     * @type {string}
     * @memberof PurchasingPurchaseOrderDetail
     */
    comment?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PurchasingPurchaseOrderDetail
     */
    createdBy?: string | null;
    /**
     * 
     * @type {Array<PurchasingPurchaseOrderLineDetail>}
     * @memberof PurchasingPurchaseOrderDetail
     */
    lines: Array<PurchasingPurchaseOrderLineDetail>;
}


/**
 * @export
 */
export const PurchasingPurchaseOrderDetailStateEnum = {
    Waiting: 'Waiting',
    Partial: 'Partial',
    Done: 'Done'
} as const;
export type PurchasingPurchaseOrderDetailStateEnum = typeof PurchasingPurchaseOrderDetailStateEnum[keyof typeof PurchasingPurchaseOrderDetailStateEnum];

/**
 * @export
 */
export const PurchasingPurchaseOrderDetailConfirmationStateEnum = {
    Waiting: 'Waiting',
    Partial: 'Partial',
    Done: 'Done'
} as const;
export type PurchasingPurchaseOrderDetailConfirmationStateEnum = typeof PurchasingPurchaseOrderDetailConfirmationStateEnum[keyof typeof PurchasingPurchaseOrderDetailConfirmationStateEnum];

/**
 * @export
 */
export const PurchasingPurchaseOrderDetailDeliveryAddressEnum = {
    Andat: 'Andat',
    France: 'France',
    Swiss: 'Swiss'
} as const;
export type PurchasingPurchaseOrderDetailDeliveryAddressEnum = typeof PurchasingPurchaseOrderDetailDeliveryAddressEnum[keyof typeof PurchasingPurchaseOrderDetailDeliveryAddressEnum];


/**
 * Check if a given object implements the PurchasingPurchaseOrderDetail interface.
 */
export function instanceOfPurchasingPurchaseOrderDetail(value: object): value is PurchasingPurchaseOrderDetail {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('state' in value) || value['state'] === undefined) return false;
    if (!('confirmationState' in value) || value['confirmationState'] === undefined) return false;
    if (!('doneRatio' in value) || value['doneRatio'] === undefined) return false;
    if (!('deliveryAddress' in value) || value['deliveryAddress'] === undefined) return false;
    if (!('businessPartner' in value) || value['businessPartner'] === undefined) return false;
    if (!('businessPartnerId' in value) || value['businessPartnerId'] === undefined) return false;
    if (!('plannedDate' in value) || value['plannedDate'] === undefined) return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    if (!('lines' in value) || value['lines'] === undefined) return false;
    return true;
}

export function PurchasingPurchaseOrderDetailFromJSON(json: any): PurchasingPurchaseOrderDetail {
    return PurchasingPurchaseOrderDetailFromJSONTyped(json, false);
}

export function PurchasingPurchaseOrderDetailFromJSONTyped(json: any, ignoreDiscriminator: boolean): PurchasingPurchaseOrderDetail {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'state': json['state'],
        'confirmationState': json['confirmationState'],
        'doneRatio': json['doneRatio'],
        'deliveryAddress': json['deliveryAddress'],
        'orderSequence': json['orderSequence'] == null ? undefined : json['orderSequence'],
        'businessPartner': json['businessPartner'],
        'businessPartnerId': json['businessPartnerId'],
        'plannedDate': (new Date(json['plannedDate'])),
        'confirmationDate': json['confirmationDate'] == null ? undefined : (new Date(json['confirmationDate'])),
        'createdAt': (new Date(json['createdAt'])),
        'comment': json['comment'] == null ? undefined : json['comment'],
        'createdBy': json['createdBy'] == null ? undefined : json['createdBy'],
        'lines': ((json['lines'] as Array<any>).map(PurchasingPurchaseOrderLineDetailFromJSON)),
    };
}

  export function PurchasingPurchaseOrderDetailToJSON(json: any): PurchasingPurchaseOrderDetail {
      return PurchasingPurchaseOrderDetailToJSONTyped(json, false);
  }

  export function PurchasingPurchaseOrderDetailToJSONTyped(value?: PurchasingPurchaseOrderDetail | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'state': value['state'],
        'confirmationState': value['confirmationState'],
        'doneRatio': value['doneRatio'],
        'deliveryAddress': value['deliveryAddress'],
        'orderSequence': value['orderSequence'],
        'businessPartner': value['businessPartner'],
        'businessPartnerId': value['businessPartnerId'],
        'plannedDate': ((value['plannedDate']).toISOString()),
        'confirmationDate': value['confirmationDate'] == null ? undefined : ((value['confirmationDate'] as any).toISOString()),
        'createdAt': ((value['createdAt']).toISOString()),
        'comment': value['comment'],
        'createdBy': value['createdBy'],
        'lines': ((value['lines'] as Array<any>).map(PurchasingPurchaseOrderLineDetailToJSON)),
    };
}

