/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: smn@advance.ch
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AuthResetPasswordSchema
 */
export interface AuthResetPasswordSchema {
    /**
     * 
     * @type {string}
     * @memberof AuthResetPasswordSchema
     */
    newPassword: string;
}

/**
 * Check if a given object implements the AuthResetPasswordSchema interface.
 */
export function instanceOfAuthResetPasswordSchema(value: object): value is AuthResetPasswordSchema {
    if (!('newPassword' in value) || value['newPassword'] === undefined) return false;
    return true;
}

export function AuthResetPasswordSchemaFromJSON(json: any): AuthResetPasswordSchema {
    return AuthResetPasswordSchemaFromJSONTyped(json, false);
}

export function AuthResetPasswordSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuthResetPasswordSchema {
    if (json == null) {
        return json;
    }
    return {
        
        'newPassword': json['newPassword'],
    };
}

  export function AuthResetPasswordSchemaToJSON(json: any): AuthResetPasswordSchema {
      return AuthResetPasswordSchemaToJSONTyped(json, false);
  }

  export function AuthResetPasswordSchemaToJSONTyped(value?: AuthResetPasswordSchema | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'newPassword': value['newPassword'],
    };
}

