import { AppStateEntity } from 'api';
import { HttpError } from 'react-admin';
import repositories from '.';
import api from './api';
import { getApiQuery } from './base';

const resource = '/master/common';

interface AppStateResult {
  isAuthenticated: boolean;
  appState?: AppStateEntity;
}

export const useCurrencies = getApiQuery(api.commonGetCurrencies, { staleTime: undefined });
export const useAppState = getApiQuery(async () => {
  const result: AppStateResult = {
    isAuthenticated: true
  };
  try {
    const apiResult = await api.commonGetAppState();
    result.appState = apiResult;
  } catch (e) {
    if (e instanceof HttpError) {
      if (e.status === 401) {
        console.log(e);
        result.isAuthenticated = false;
      }
    }
  }
  return result;
}, { staleTime: undefined });
export const useCountries = getApiQuery(api.commonGetCountries, { staleTime: Infinity });
export const useEnumQuery = getApiQuery(api.commonGetEnums, { staleTime: Infinity });

export const useQuantityType = (id: number | undefined) => {
  const { data } = useEnumQuery();

  return data?.quantityTypes.find((x) => x.id === id);
};

export const useCurrency = (id: string | undefined) => {
  const { data } = useCurrencies();

  return data?.find((x) => x.id === id);
};

export default {
  getAppSetting: () => repositories.get(`${resource}/setting`),
};
