/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: smn@advance.ch
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Paging } from './Paging';
import {
    PagingFromJSON,
    PagingFromJSONTyped,
    PagingToJSON,
    PagingToJSONTyped,
} from './Paging';
import type { ProductProductionOrderDetail } from './ProductProductionOrderDetail';
import {
    ProductProductionOrderDetailFromJSON,
    ProductProductionOrderDetailFromJSONTyped,
    ProductProductionOrderDetailToJSON,
    ProductProductionOrderDetailToJSONTyped,
} from './ProductProductionOrderDetail';

/**
 * 
 * @export
 * @interface PaginatedListProductionOrderDetail
 */
export interface PaginatedListProductionOrderDetail {
    /**
     * 
     * @type {Array<ProductProductionOrderDetail>}
     * @memberof PaginatedListProductionOrderDetail
     */
    list: Array<ProductProductionOrderDetail>;
    /**
     * 
     * @type {Paging}
     * @memberof PaginatedListProductionOrderDetail
     */
    paging: Paging;
}

/**
 * Check if a given object implements the PaginatedListProductionOrderDetail interface.
 */
export function instanceOfPaginatedListProductionOrderDetail(value: object): value is PaginatedListProductionOrderDetail {
    if (!('list' in value) || value['list'] === undefined) return false;
    if (!('paging' in value) || value['paging'] === undefined) return false;
    return true;
}

export function PaginatedListProductionOrderDetailFromJSON(json: any): PaginatedListProductionOrderDetail {
    return PaginatedListProductionOrderDetailFromJSONTyped(json, false);
}

export function PaginatedListProductionOrderDetailFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaginatedListProductionOrderDetail {
    if (json == null) {
        return json;
    }
    return {
        
        'list': ((json['list'] as Array<any>).map(ProductProductionOrderDetailFromJSON)),
        'paging': PagingFromJSON(json['paging']),
    };
}

  export function PaginatedListProductionOrderDetailToJSON(json: any): PaginatedListProductionOrderDetail {
      return PaginatedListProductionOrderDetailToJSONTyped(json, false);
  }

  export function PaginatedListProductionOrderDetailToJSONTyped(value?: PaginatedListProductionOrderDetail | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'list': ((value['list'] as Array<any>).map(ProductProductionOrderDetailToJSON)),
        'paging': PagingToJSON(value['paging']),
    };
}

