import { Grid } from '@mui/material';
import { ProductResourceDetailSchemaStockTracingEnum, ProductUpdateSchema } from 'api';
import { CategoryInput } from 'components/chooser/categoryChoose';
import EnumSelectField from 'components/input/enumSelectField';
import { CustomImageInput } from 'components/input/image/customImageInput';
import * as React from 'react';
import {
  BooleanInput,
  Loading,
  required,
  SelectInput,
  TabbedForm,
  TextInput
} from 'react-admin';
import { useCurrencies, useEnumQuery } from 'repositories/app';
import { StockTracingChoices } from 'repositories/products/product';
import { Source } from 'utils/source';
import BillOfResourcesForm from '../billOfResourceForm';

interface ProductFormProps {
  create?: boolean
}

export default function ProductForm(props: ProductFormProps) {
  const {
    create,
  } = props;

  const { data: currencies } = useCurrencies();
  const { data: enums } = useEnumQuery();
  const s = Source<ProductUpdateSchema>();

  if (!currencies || !enums) {
    return <Loading />;
  }

  return (
    <TabbedForm>
      <TabbedForm.Tab label="base">
        <Grid container spacing={2} rowSpacing={0.5}>
          <Grid item xs={0} md={1} />
          <Grid item xs={12} md={3}>
            <TextInput
              {...s('articleNo')}
              fullWidth
              inputProps={{ maxLength: 100 }}
              variant="outlined"
              helperText={false}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextInput
              {...s('name')}
              validate={required()}
              fullWidth
              inputProps={{ maxLength: 100 }}
              variant="outlined"
              helperText={false}
            />
          </Grid>
          <Grid item xs={2} md={3}>
            <BooleanInput {...s('isActive')} />
          </Grid>
          <Grid item xs={0} md={1} />

          <Grid item xs={0} md={1} />
          <Grid item xs={12} md={3}>
            <TextInput
              {...s('size')}
              fullWidth
              variant="outlined"
              helperText={false}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextInput
              {...s('color')}
              fullWidth
              variant="outlined"
              helperText={false}
            />
          </Grid>
          <Grid item xs={2} md={3}>
            <BooleanInput {...s('isReadyToSell')} />
          </Grid>
          <Grid item xs={0} md={1} />

          <Grid item xs={0} md={1} />
          <Grid item xs={12} md={3}>
            <TextInput
              {...s('option')}
              fullWidth
              variant="outlined"
              helperText={false}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <CategoryInput
              {...s('categories')}
              fullWidth
              variant="outlined"
              validate={[required()]}
              required
            />
          </Grid>
          <Grid item xs={0} md={4} />

          <Grid item xs={0} md={1} />
          <Grid item xs={6} md={4}>
            <EnumSelectField
              {...s('stockTracing')}
              margin="none"
              choices={StockTracingChoices}
              validate={[required()]}
              fullWidth
              defaultValue={ProductResourceDetailSchemaStockTracingEnum.Serial}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <SelectInput
              {...s('quantityTypeId')}
              validate={[required()]}
              margin="none"
              choices={enums.quantityTypes ?? []}
              fullWidth
              variant="outlined"
              helperText={false}
            />
          </Grid>
          <Grid item xs={0} md={4} />

          {
            !create && (
              <>
                <Grid item xs={0} md={1} />
                <Grid item xs={12} md={4}>
                  <TextInput
                    {...s('hsCode')}
                    fullWidth
                    variant="outlined"
                    helperText={false}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextInput
                    {...s('weight')}
                    fullWidth
                    variant="outlined"
                    helperText={false}
                  />
                </Grid>
                <Grid item xs={0} md={3} />

                <Grid item xs={0} md={1} />
                <Grid item xs={12} md={4}>
                  <TextInput
                    {...s('productionNumberSuffix')}
                    fullWidth
                    variant="outlined"
                    helperText={false}
                  />
                </Grid>
                <Grid item xs={0} md={7} />
              </>
            )
          }

          <Grid item xs={0} md={1} />
          <Grid item xs={6} md={5}>
            <CustomImageInput
              label="resources.product/raw-materials.fields.image"
              source="image"
            />
          </Grid>
        </Grid>
      </TabbedForm.Tab>
      {!create && (
        <TabbedForm.Tab label="resources.billOfResources.name">
          <BillOfResourcesForm source="billOfResources" />
        </TabbedForm.Tab>
      )}
    </TabbedForm>
  );
}
