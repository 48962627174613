/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: smn@advance.ch
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ProductDetailSchema } from './ProductDetailSchema';
import {
    ProductDetailSchemaFromJSON,
    ProductDetailSchemaFromJSONTyped,
    ProductDetailSchemaToJSON,
    ProductDetailSchemaToJSONTyped,
} from './ProductDetailSchema';
import type { ProductProductionItemDetailSchema } from './ProductProductionItemDetailSchema';
import {
    ProductProductionItemDetailSchemaFromJSON,
    ProductProductionItemDetailSchemaFromJSONTyped,
    ProductProductionItemDetailSchemaToJSON,
    ProductProductionItemDetailSchemaToJSONTyped,
} from './ProductProductionItemDetailSchema';

/**
 * 
 * @export
 * @interface ProductProductionOrderLineDetail
 */
export interface ProductProductionOrderLineDetail {
    /**
     * 
     * @type {number}
     * @memberof ProductProductionOrderLineDetail
     */
    productId: number;
    /**
     * 
     * @type {number}
     * @memberof ProductProductionOrderLineDetail
     */
    productionOrderId: number;
    /**
     * 
     * @type {number}
     * @memberof ProductProductionOrderLineDetail
     */
    amount: number;
    /**
     * 
     * @type {Date}
     * @memberof ProductProductionOrderLineDetail
     */
    requestDate?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof ProductProductionOrderLineDetail
     */
    plannedDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof ProductProductionOrderLineDetail
     */
    comment?: string | null;
    /**
     * 
     * @type {Array<ProductProductionItemDetailSchema>}
     * @memberof ProductProductionOrderLineDetail
     */
    productionItems?: Array<ProductProductionItemDetailSchema> | null;
    /**
     * 
     * @type {number}
     * @memberof ProductProductionOrderLineDetail
     */
    id: number;
    /**
     * 
     * @type {ProductDetailSchema}
     * @memberof ProductProductionOrderLineDetail
     */
    product: ProductDetailSchema;
}

/**
 * Check if a given object implements the ProductProductionOrderLineDetail interface.
 */
export function instanceOfProductProductionOrderLineDetail(value: object): value is ProductProductionOrderLineDetail {
    if (!('productId' in value) || value['productId'] === undefined) return false;
    if (!('productionOrderId' in value) || value['productionOrderId'] === undefined) return false;
    if (!('amount' in value) || value['amount'] === undefined) return false;
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('product' in value) || value['product'] === undefined) return false;
    return true;
}

export function ProductProductionOrderLineDetailFromJSON(json: any): ProductProductionOrderLineDetail {
    return ProductProductionOrderLineDetailFromJSONTyped(json, false);
}

export function ProductProductionOrderLineDetailFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductProductionOrderLineDetail {
    if (json == null) {
        return json;
    }
    return {
        
        'productId': json['productId'],
        'productionOrderId': json['productionOrderId'],
        'amount': json['amount'],
        'requestDate': json['requestDate'] == null ? undefined : (new Date(json['requestDate'])),
        'plannedDate': json['plannedDate'] == null ? undefined : (new Date(json['plannedDate'])),
        'comment': json['comment'] == null ? undefined : json['comment'],
        'productionItems': json['productionItems'] == null ? undefined : ((json['productionItems'] as Array<any>).map(ProductProductionItemDetailSchemaFromJSON)),
        'id': json['id'],
        'product': ProductDetailSchemaFromJSON(json['product']),
    };
}

  export function ProductProductionOrderLineDetailToJSON(json: any): ProductProductionOrderLineDetail {
      return ProductProductionOrderLineDetailToJSONTyped(json, false);
  }

  export function ProductProductionOrderLineDetailToJSONTyped(value?: ProductProductionOrderLineDetail | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'productId': value['productId'],
        'productionOrderId': value['productionOrderId'],
        'amount': value['amount'],
        'requestDate': value['requestDate'] == null ? undefined : ((value['requestDate'] as any).toISOString().substring(0,10)),
        'plannedDate': value['plannedDate'] == null ? undefined : ((value['plannedDate'] as any).toISOString().substring(0,10)),
        'comment': value['comment'],
        'productionItems': value['productionItems'] == null ? undefined : ((value['productionItems'] as Array<any>).map(ProductProductionItemDetailSchemaToJSON)),
        'id': value['id'],
        'product': ProductDetailSchemaToJSON(value['product']),
    };
}

