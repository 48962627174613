import {
  Grid,
} from '@mui/material';
import { ProductProductionOrderLineSchema } from 'api/models';
import ProductChooser from 'components/chooser/productChoose';
import CustomDatePicker from 'components/input/date/CustomDatePicker';
import React from 'react';
import {
  ArrayInput,
  SimpleFormIterator, TextInput,
} from 'react-admin';
import { Source } from 'utils/source';

function ProductionOrderLineForm() {
  const s = Source<ProductProductionOrderLineSchema>();

  return (
    <Grid container spacing={2} marginTop="10px" marginBottom="10px">
      <Grid item md={2} />
      <Grid item md={4}>
        <ProductChooser
          {...s('productId')}
          isFullWidth
          required
        />
      </Grid>
      <Grid item md={4}>
        <TextInput
          {...s('amount')}
          fullWidth
          variant="outlined"
          helperText={false}
          type="number"
        />
      </Grid>
      <Grid item md={2} />
      <Grid item md={2} />
      <Grid item md={4}>
        <CustomDatePicker
          {...s('requestDate')}
          dateOnly
          fullWidth
          variant="outlined"
          helperText={false}
        />
      </Grid>
      <Grid item md={4}>
        <CustomDatePicker
          {...s('plannedDate')}
          dateOnly
          fullWidth
          variant="outlined"
          helperText={false}
        />
      </Grid>
      <Grid item md={2} />
      <Grid item md={2} />
      <Grid item md={8}>
        <TextInput
          {...s('comment')}
          fullWidth
          variant="outlined"
          helperText={false}
        />
      </Grid>
      <Grid item md={2} />
    </Grid>
  );
}

interface ProductionOrderLinesFormProps {
  source: string;
}

export default function ProductionOrderLinesForm(props: ProductionOrderLinesFormProps) {
  const {
    source,
  } = props;
  return (
    <ArrayInput source={source}>
      <SimpleFormIterator className="form" disableClear>
        <ProductionOrderLineForm />
      </SimpleFormIterator>
    </ArrayInput>
  );
}
