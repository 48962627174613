import { Grid, InputAdornment } from '@mui/material';
import { ProductRawMaterialCreateEntity } from 'api';
import { CategoryInput } from 'components/chooser/categoryChoose';
import CustomToolBar from 'components/commons/editToolBar';
import EnumSelectField from 'components/input/enumSelectField';
import { CustomImageInput } from 'components/input/image/customImageInput';
import QuantityInput from 'components/input/quantity/quantityInput';
import BusinessPartnerInput from 'components/input/reference/businessPartner';
import React from 'react';
import {
  BooleanInput,
  FormDataConsumer,
  Loading, NumberInput,
  SelectInput,
  TabbedForm,
  TextInput, number,
  required, useTranslate,
} from 'react-admin';
import { useCurrencies, useEnumQuery } from 'repositories/app';
import { useCategories } from 'repositories/products/categories';
import { StockTracingChoices } from 'repositories/products/product';
import { incoTerms } from 'resources/stock/incomingShipment/enum';
import { Source } from 'utils/source';

interface RawMaterialFormProps {
  edit?: boolean
}

export default function RawMaterialForm(props: RawMaterialFormProps) {
  const {
    edit,
  } = props;
  const s = Source<ProductRawMaterialCreateEntity>();

  const { data: enums } = useEnumQuery();
  const { data: currencies } = useCurrencies();
  const { data: categories } = useCategories();
  const translate = useTranslate();

  if (!categories || !enums || !currencies) {
    return <Loading />;
  }

  return (
    <TabbedForm toolbar={<CustomToolBar />} defaultValues={{ categories: [] }}>
      <TabbedForm.Tab label="basic">
        <Grid container spacing={2} marginBottom={`${edit ? '10px' : '0px'}`}>
          <Grid item xs={4} md={1} />
          <Grid item xs={8} md={5}>
            {edit && <BooleanInput {...s('isActive')} />}
          </Grid>
        </Grid>
        <Grid container spacing={2} marginBottom="20px">
          <Grid item xs={4} md={1} />
          <Grid item xs={8} md={5}>
            <TextInput
              {...s('articleNo')}
              validate={edit ? required() : undefined}
              fullWidth
              inputProps={{ maxLength: 100 }}
              variant="outlined"
              helperText={false}
            />
          </Grid>
          <Grid item xs={8} md={5}>
            <TextInput
              {...s('name')}
              validate={required()}
              fullWidth
              inputProps={{ maxLength: 100 }}
              variant="outlined"
              helperText={false}
            />

          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={4} md={1} />
          <Grid item xs={8} md={5}>
            <CategoryInput
              {...s('categories')}
              required
              fullWidth
              label="category"
            />
          </Grid>

          <Grid item xs={8} md={5}>
            <BusinessPartnerInput {...s('supplierId')} required />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={4} md={1} />
          <Grid item xs={8} md={5}>
            <TextInput fullWidth {...s('supplierArticleNo')} />
          </Grid>
          <Grid item xs={8} md={5}>
            <TextInput fullWidth {...s('supplierArticleName')} />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={4} md={1} />
          <Grid item xs={8} md={5}>
            <CustomImageInput
              label="resources.product/raw-materials.fields.image"
              source="image"
            />
          </Grid>
        </Grid>
      </TabbedForm.Tab>
      <TabbedForm.Tab label="stock">
        <Grid container spacing={2}>
          <Grid item xs={4} md={1} />
          <Grid item xs={8} md={4}>
            <EnumSelectField
              {...s('stockTracing')}
              margin="none"
              choices={StockTracingChoices}
              validate={[required()]}
              fullWidth
            />
          </Grid>
          <Grid item xs={8} md={4}>
            <BooleanInput {...s('isUniqueLotNo')} />
          </Grid>
          <Grid item xs={4} md={1} />
        </Grid>

        <FormDataConsumer>
          {({ formData }) => (
            <>
              <Grid container spacing={2} marginBottom="20px">
                <Grid item xs={4} md={1} />
                <Grid item xs={8} md={4}>
                  <SelectInput
                    {...s('quantityTypeId')}
                    validate={[required()]}
                    margin="none"
                    choices={enums.quantityTypes ?? []}
                    fullWidth
                    variant="outlined"
                    helperText={false}
                  />
                </Grid>
                <Grid item xs={8} md={4}>
                  <QuantityInput
                    {...s('reserveQuantity')}
                    quantityType={formData.quantityTypeId}
                  />
                </Grid>
                <Grid item xs={4} md={1} />
              </Grid>

              <Grid container spacing={2} rowSpacing={2.5} marginBottom="20px">
                <Grid item xs={4} md={1} />
                <Grid item xs={8} md={4}>
                  <QuantityInput {...s('stockConditioning')} quantityType={formData.quantityTypeId} />
                </Grid>
                <Grid item xs={8} md={4}>
                  <QuantityInput {...s('supplierConditioning')} quantityType={formData.quantityTypeId} />
                </Grid>
                <Grid item xs={4} md={1} />
              </Grid>

            </>
          )}
        </FormDataConsumer>

      </TabbedForm.Tab>
      <TabbedForm.Tab label="purchasing">

        <FormDataConsumer>
          {({ formData }) => (
            <>
              <Grid container spacing={2}>
                <Grid item xs={4} md={1} />
                <Grid item xs={8} md={4} marginBottom="20px">
                  <NumberInput
                    {...s('leadTime')}
                    validate={[number()]}
                    step={1}
                    min={0}
                    max={1000}
                    fullWidth
                    variant="outlined"
                    helperText={false}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">{translate('mai.day')}</InputAdornment>,
                    }}
                  />
                </Grid>
                <Grid item xs={8} md={4}>
                  <QuantityInput
                    {...s('moq')}
                    quantityType={formData.supplierQuantityTypeId || formData.quantityTypeId}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={4} md={1} />
                <Grid item xs={8} md={4}>
                  <SelectInput
                    {...s('supplierQuantityTypeId')}
                    margin="none"
                    choices={enums.quantityTypes ?? []}
                    fullWidth
                    variant="outlined"
                    helperText={false}
                  />
                </Grid>
                <Grid item xs={8} md={4}>
                  <NumberInput {...s('supplierQuantityFactor')} fullWidth />
                </Grid>
                <Grid item xs={4} md={1} />
              </Grid>

              <Grid container>
                <Grid item xs={4} md={1} />
                <Grid item>
                  <EnumSelectField {...s('incoterm')} choices={incoTerms} />
                </Grid>
                <Grid item xs={4} md={1} />
              </Grid>
            </>
          )}
        </FormDataConsumer>
      </TabbedForm.Tab>
      <TabbedForm.Tab label="accounting">
        <Grid container spacing={2}>
          <Grid item xs={4} md={1} />
          <Grid item xs={8} md={4}>
            <TextInput {...s('hsCode')} fullWidth />
          </Grid>
        </Grid>
      </TabbedForm.Tab>
    </TabbedForm>
  );
}
