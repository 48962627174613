import {
  Table, TableBody, TableCell, TableHead, TableRow,
} from '@mui/material';
import { PurchasingPurchaseOrderDetail } from 'api';
import urls from 'app/urls';
import ShowQuantity from 'components/commons/showQuantity';
import FileView from 'components/fields/file/FileView';
import React from 'react';
import {
  DateField, Show, TabbedShowLayout, TextField, useRecordContext,
} from 'react-admin';
import localeDate from 'utils/format/date';
import formatString from 'utils/formatString';
import { Source } from 'utils/source';
import useTranslate from 'utils/translate';

function PurchaseOrderLines() {
  const translate = useTranslate();
  const record = useRecordContext<PurchasingPurchaseOrderDetail>();

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>#</TableCell>
          <TableCell>{translate('mai.incomingShipment.receive.ArticleNo')}</TableCell>
          <TableCell>{translate('mai.incomingShipment.receive.Resource')}</TableCell>
          <TableCell>{translate('resources.purchasing.purchaseOrder.Amount')}</TableCell>
          <TableCell>{translate('resources.purchasing.purchaseOrder.DoneAmount')}</TableCell>
          <TableCell>{translate('resources.purchasing.purchaseOrder.OpenAmount')}</TableCell>
          <TableCell>{translate('resources.purchasing.purchaseOrder.ConfirmedDeliveryDate')}</TableCell>
          <TableCell>{translate('mai.comment')}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {record?.lines?.map((row, index: number) => (
          <TableRow key={row.rawMaterialId}>
            <TableCell>{index + 1}</TableCell>
            <TableCell>{row.articleNo}</TableCell>
            <TableCell>{row.rawMaterial}</TableCell>
            <TableCell>
              <ShowQuantity value={row.amount} quantityTypeId={row.quantityTypeId} />
            </TableCell>
            <TableCell>
              <ShowQuantity value={row.amount - row.openAmount} quantityTypeId={row.quantityTypeId} />
            </TableCell>
            <TableCell>
              <ShowQuantity value={row.openAmount} quantityTypeId={row.quantityTypeId} />
            </TableCell>
            <TableCell>{localeDate(row.confirmedDeliveryDate)}</TableCell>
            <TableCell>{row?.comment}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>

  );
}

function PurchaseOrderShowLayout() {
  const record = useRecordContext<PurchasingPurchaseOrderDetail>();
  const s = Source<PurchasingPurchaseOrderDetail>();
  return (
    <TabbedShowLayout>
      <TabbedShowLayout.Tab label="Data">
        <TextField {...s('orderSequence')} />
        <TextField {...s('businessPartner')} />
        <DateField {...s('plannedDate')} />
        <DateField {...s('confirmationDate')} />
        <DateField {...s('createdAt')} />
        <TextField {...s('comment')} />
        <PurchaseOrderLines />

      </TabbedShowLayout.Tab>
      <TabbedShowLayout.Tab label="Files">
        <FileView
          endpoint={formatString(urls.PurchaseOrderFile_GetFiles.url, { id: record?.id?.toString() as string })}
          getUrl={urls.PurchaseOrderFile_GetFiles}
          uploadUrl={urls.PurchaseOrderFile_UploadFile}
        />
      </TabbedShowLayout.Tab>
    </TabbedShowLayout>
  );
}

export default function PurchaseOrderShow() {
  return (
    <Show>
      <PurchaseOrderShowLayout />
    </Show>
  );
}
