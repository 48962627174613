/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: smn@advance.ch
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProductsResourcePriceBulkCreate
 */
export interface ProductsResourcePriceBulkCreate {
    /**
     * 
     * @type {number}
     * @memberof ProductsResourcePriceBulkCreate
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof ProductsResourcePriceBulkCreate
     */
    resourceId: number;
    /**
     * 
     * @type {Date}
     * @memberof ProductsResourcePriceBulkCreate
     */
    startDate?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof ProductsResourcePriceBulkCreate
     */
    endDate?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof ProductsResourcePriceBulkCreate
     */
    price: number;
    /**
     * 
     * @type {string}
     * @memberof ProductsResourcePriceBulkCreate
     */
    currencyId: string;
}

/**
 * Check if a given object implements the ProductsResourcePriceBulkCreate interface.
 */
export function instanceOfProductsResourcePriceBulkCreate(value: object): value is ProductsResourcePriceBulkCreate {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('resourceId' in value) || value['resourceId'] === undefined) return false;
    if (!('price' in value) || value['price'] === undefined) return false;
    if (!('currencyId' in value) || value['currencyId'] === undefined) return false;
    return true;
}

export function ProductsResourcePriceBulkCreateFromJSON(json: any): ProductsResourcePriceBulkCreate {
    return ProductsResourcePriceBulkCreateFromJSONTyped(json, false);
}

export function ProductsResourcePriceBulkCreateFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductsResourcePriceBulkCreate {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'resourceId': json['resourceId'],
        'startDate': json['startDate'] == null ? undefined : (new Date(json['startDate'])),
        'endDate': json['endDate'] == null ? undefined : (new Date(json['endDate'])),
        'price': json['price'],
        'currencyId': json['currencyId'],
    };
}

  export function ProductsResourcePriceBulkCreateToJSON(json: any): ProductsResourcePriceBulkCreate {
      return ProductsResourcePriceBulkCreateToJSONTyped(json, false);
  }

  export function ProductsResourcePriceBulkCreateToJSONTyped(value?: ProductsResourcePriceBulkCreate | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'resourceId': value['resourceId'],
        'startDate': value['startDate'] == null ? undefined : ((value['startDate'] as any).toISOString()),
        'endDate': value['endDate'] == null ? undefined : ((value['endDate'] as any).toISOString()),
        'price': value['price'],
        'currencyId': value['currencyId'],
    };
}

