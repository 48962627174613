import { Grid } from '@mui/material';
import { ProductProductionItemSchema } from 'api';
import ProductChooser from 'components/chooser/productChoose';
import CustomDatePicker from 'components/input/date/CustomDatePicker';
import { ResourceQuantityInput } from 'components/input/quantity/resourceFields';
import * as React from 'react';
import {
  Loading,
  required,
  SimpleForm, TextInput, TextInputProps,
} from 'react-admin';
import {
  useFormContext,
  useWatch,
} from 'react-hook-form';
import { useProductDetail, useResourcesQuery } from 'repositories/products/product';
import constants from 'utils/constants';
import { Source } from 'utils/source';

interface AmountInputProps extends TextInputProps {
  className: string | undefined,
  label: string | undefined,
  source: string,
}

function AmountInput(props: AmountInputProps) {
  const {
    className, label, source,
  } = props;

  const { setValue } = useFormContext();
  const productId = useWatch({ name: 'productId' });
  const productDetail = useProductDetail({ id: productId as number });
  const isSerial = productDetail?.data?.stockTracing === constants.StockTraceType.SERIAL.VALUE;

  if (isSerial) {
    setValue('amount', 1);
  }

  return (
    <ResourceQuantityInput
      className={className}
      label={label}
      source={source}
      disabled={isSerial}
      resourceId={Number(productId)}
    />
  );
}

export default function ProductionItemForm() {
  const { data: resources } = useResourcesQuery();
  const s = Source<ProductProductionItemSchema>();

  if (!resources) return <Loading />;

  return (
    <SimpleForm>
      <Grid container spacing={2} rowSpacing={2}>
        <Grid item xs={2} md={2} />
        <Grid item xs={4} md={4}>
          <TextInput {...s('serialNo')} fullWidth variant="outlined" helperText={false} validate={[required()]} />
        </Grid>
        <Grid item xs={4} md={4}>
          <TextInput {...s('productionNo')} fullWidth variant="outlined" helperText={false} />
        </Grid>
        <Grid item xs={2} md={2} />

        <Grid item xs={2} md={2} />
        <Grid item xs={4} md={4}>
          <ProductChooser {...s('productId')} isReadyToSell defaultActive />
        </Grid>
        <Grid item xs={4} md={4}>
          <AmountInput
            className=""
            label="mai.incomingShipment.receive.Amount"
            source="amount"
          />
        </Grid>
        <Grid item xs={2} md={2} />

        <Grid item xs={2} md={2} />
        <Grid item xs={4} md={4}>
          <CustomDatePicker
            {...s('plannedEndDate')}
            fullWidth
            variant="outlined"
            helperText={false}
          />
        </Grid>
        <Grid item xs={4} md={4}>
          <CustomDatePicker
            {...s('plannedExportDate')}
            fullWidth
            variant="outlined"
            helperText={false}
          />
        </Grid>
        <Grid item xs={2} md={2} />

        <Grid item xs={2} md={2} />
        <Grid item xs={8} md={8}>
          <TextInput {...s('comment')} fullWidth variant="outlined" helperText={false} />
        </Grid>
        <Grid item xs={2} md={2} />
      </Grid>

    </SimpleForm>
  );
}
