import { ProductResourcePriceDetailEntity } from 'api';
import List from 'components/list/List';
import Datagrid from 'components/list/dataGrid';
import * as React from 'react';
import {
  DateField, TextField,
} from 'react-admin';
import { Source } from 'utils/source';
import filters from './filters';
import PriceField from 'components/fields/priceField';

export default function ResourcePriceList () {
  const s = Source<ProductResourcePriceDetailEntity>();
  return (
    <List
      filters={filters}
    >
      <Datagrid
        rowClick="show"
      // bulkActionButtons={<PopupCreateResourcePrice/>}
      >
        <TextField {...s('resource.name')} sortBy="resourceId" />
        <DateField {...s('startDate')} />
        <DateField {...s('endDate')} />
        <PriceField {...s('price')} currencySource={s('currencyId').source} />
      </Datagrid>
    </List>
  );
}
