import {
  Grid,
} from '@mui/material';
import { ShipmentExportBoxSchema } from 'api';
import React from 'react';
import {
  ArrayInput,
  SimpleFormIterator, TextInput,
} from 'react-admin';
import { Source } from 'utils/source';
import ExportShipmentLinesForm from './exportShipmentLineForm';

function ExportShipmentBoxForm() {
  const s = Source<ShipmentExportBoxSchema>();

  return (
    <Grid container spacing={2} marginTop="10px" marginBottom="10px">
      <Grid item md={2} />
      <Grid item md={8}>
        <TextInput
          {...s('length')}
          fullWidth
          variant="outlined"
          helperText={false}
          type="number"
        />
      </Grid>
      <Grid item md={2} />
      <Grid item md={2} />
      <Grid item md={4}>
        <TextInput
          {...s('width')}
          fullWidth
          variant="outlined"
          helperText={false}
          type="number"
        />
      </Grid>
      <Grid item md={4}>
        <TextInput
          {...s('height')}
          fullWidth
          variant="outlined"
          helperText={false}
          type="number"
        />
      </Grid>
      <Grid item md={2} />
      <Grid item md={2} />
      <Grid item md={4}>
        <TextInput
          {...s('volume')}
          fullWidth
          variant="outlined"
          helperText={false}
          type="number"
        />
      </Grid>
      <Grid item md={4}>
        <TextInput
          {...s('weight')}
          fullWidth
          variant="outlined"
          helperText={false}
          type="number"
        />
      </Grid>
      <Grid item md={2} />
      <Grid item md={2} />
      <Grid item md={8}>
        <TextInput
          {...s('comment')}
          fullWidth
          variant="outlined"
          helperText={false}
        />
      </Grid>
      <Grid item md={2} />
      <Grid item md={2} />
      <Grid item md={8}>
        <ExportShipmentLinesForm {...s('lines')} />
      </Grid>
      <Grid item md={2} />
    </Grid>
  );
}

interface ExportShipmentBoxesFormProps {
  source: string;
}

export default function ExportShipmentBoxesForm(props: ExportShipmentBoxesFormProps) {
  const {
    source,
  } = props;
  return (
    <ArrayInput source={source}>
      <SimpleFormIterator className="form" disableClear>
        <ExportShipmentBoxForm />
      </SimpleFormIterator>
    </ArrayInput>
  );
}
