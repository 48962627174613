/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: smn@advance.ch
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Paging } from './Paging';
import {
    PagingFromJSON,
    PagingFromJSONTyped,
    PagingToJSON,
    PagingToJSONTyped,
} from './Paging';
import type { StockLinesLineDetail } from './StockLinesLineDetail';
import {
    StockLinesLineDetailFromJSON,
    StockLinesLineDetailFromJSONTyped,
    StockLinesLineDetailToJSON,
    StockLinesLineDetailToJSONTyped,
} from './StockLinesLineDetail';

/**
 * 
 * @export
 * @interface PaginatedListStockLineDetail
 */
export interface PaginatedListStockLineDetail {
    /**
     * 
     * @type {Array<StockLinesLineDetail>}
     * @memberof PaginatedListStockLineDetail
     */
    list: Array<StockLinesLineDetail>;
    /**
     * 
     * @type {Paging}
     * @memberof PaginatedListStockLineDetail
     */
    paging: Paging;
}

/**
 * Check if a given object implements the PaginatedListStockLineDetail interface.
 */
export function instanceOfPaginatedListStockLineDetail(value: object): value is PaginatedListStockLineDetail {
    if (!('list' in value) || value['list'] === undefined) return false;
    if (!('paging' in value) || value['paging'] === undefined) return false;
    return true;
}

export function PaginatedListStockLineDetailFromJSON(json: any): PaginatedListStockLineDetail {
    return PaginatedListStockLineDetailFromJSONTyped(json, false);
}

export function PaginatedListStockLineDetailFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaginatedListStockLineDetail {
    if (json == null) {
        return json;
    }
    return {
        
        'list': ((json['list'] as Array<any>).map(StockLinesLineDetailFromJSON)),
        'paging': PagingFromJSON(json['paging']),
    };
}

  export function PaginatedListStockLineDetailToJSON(json: any): PaginatedListStockLineDetail {
      return PaginatedListStockLineDetailToJSONTyped(json, false);
  }

  export function PaginatedListStockLineDetailToJSONTyped(value?: PaginatedListStockLineDetail | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'list': ((value['list'] as Array<any>).map(StockLinesLineDetailToJSON)),
        'paging': PagingToJSON(value['paging']),
    };
}

