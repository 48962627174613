/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: smn@advance.ch
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StockLinesShelf
 */
export interface StockLinesShelf {
    /**
     * 
     * @type {string}
     * @memberof StockLinesShelf
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof StockLinesShelf
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StockLinesShelf
     */
    image?: string | null;
    /**
     * 
     * @type {number}
     * @memberof StockLinesShelf
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof StockLinesShelf
     */
    amount: number;
    /**
     * 
     * @type {number}
     * @memberof StockLinesShelf
     */
    shelfId: number;
    /**
     * 
     * @type {number}
     * @memberof StockLinesShelf
     */
    stockLocationId: number;
}

/**
 * Check if a given object implements the StockLinesShelf interface.
 */
export function instanceOfStockLinesShelf(value: object): value is StockLinesShelf {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('count' in value) || value['count'] === undefined) return false;
    if (!('amount' in value) || value['amount'] === undefined) return false;
    if (!('shelfId' in value) || value['shelfId'] === undefined) return false;
    if (!('stockLocationId' in value) || value['stockLocationId'] === undefined) return false;
    return true;
}

export function StockLinesShelfFromJSON(json: any): StockLinesShelf {
    return StockLinesShelfFromJSONTyped(json, false);
}

export function StockLinesShelfFromJSONTyped(json: any, ignoreDiscriminator: boolean): StockLinesShelf {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'] == null ? undefined : json['name'],
        'image': json['image'] == null ? undefined : json['image'],
        'count': json['count'],
        'amount': json['amount'],
        'shelfId': json['shelfId'],
        'stockLocationId': json['stockLocationId'],
    };
}

  export function StockLinesShelfToJSON(json: any): StockLinesShelf {
      return StockLinesShelfToJSONTyped(json, false);
  }

  export function StockLinesShelfToJSONTyped(value?: StockLinesShelf | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'name': value['name'],
        'image': value['image'],
        'count': value['count'],
        'amount': value['amount'],
        'shelfId': value['shelfId'],
        'stockLocationId': value['stockLocationId'],
    };
}

