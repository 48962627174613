import { Grid } from '@mui/material';
import { ShipmentExportSchema } from 'api';
import CustomDatePicker from 'components/input/date/CustomDatePicker';
import EnumSelectField from 'components/input/enumSelectField';
import BusinessPartnerInput from 'components/input/reference/businessPartner';
import * as React from 'react';
import {
  Loading, required, SimpleForm, TextInput,
} from 'react-admin';
import { useResourcesQuery } from 'repositories/products/product';
import { incomingShipmentTransportType } from 'resources/stock/incomingShipment/enum';
import { Source } from 'utils/source';
import ExportShipmentBoxesForm from './exportShipmentBoxForm';

export default function ExportShipmentForm() {
  const { data: resources } = useResourcesQuery();
  const s = Source<ShipmentExportSchema>();

  if (!resources) return <Loading />;

  return (
    <SimpleForm>
      <Grid container spacing={2} rowSpacing={2}>
        <Grid item xs={2} md={2} />
        <Grid item xs={4} md={4}>
          <TextInput {...s('name')} fullWidth variant="outlined" helperText={false} validate={[required()]} />
        </Grid>
        <Grid item xs={4} md={4}>
          <TextInput {...s('trackingNo')} fullWidth variant="outlined" helperText={false} />
        </Grid>
        <Grid item xs={2} md={2} />

        <Grid item xs={2} md={2} />
        <Grid item xs={4} md={4}>
          <BusinessPartnerInput {...s('customerId')} required />
        </Grid>
        <Grid item xs={4} md={4}>
          <EnumSelectField
            {...s('transportType')}
            fullWidth
            choices={incomingShipmentTransportType}
            variant="outlined"
            helperText={false}
            style={{
              marginTop: 0,
            }}
          />
        </Grid>
        <Grid item xs={2} md={2} />

        <Grid item xs={2} md={2} />
        <Grid item xs={4} md={4}>
          <CustomDatePicker
            {...s('plannedDate')}
            dateOnly
            fullWidth
            variant="outlined"
            helperText={false}
          />
        </Grid>
        <Grid item xs={4} md={4}>
          <CustomDatePicker
            {...s('actualDate')}
            fullWidth
            variant="outlined"
            helperText={false}
          />
        </Grid>
        <Grid item xs={2} md={2} />
        <Grid item xs={2} md={2} />
        <Grid item xs={8} md={8}>
          <TextInput {...s('comment')} fullWidth variant="outlined" helperText={false} />
        </Grid>
        <Grid item xs={2} md={2} />
        <Grid item xs={2} md={2} />
        <Grid item xs={8} md={8}>
          <ExportShipmentBoxesForm {...s('boxes')} />
        </Grid>
      </Grid>
    </SimpleForm>
  );
}
