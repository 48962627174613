// import { BusinessPartnersSearchRequest } from 'api';
import { useQuery } from '@tanstack/react-query';
import api from 'repositories/api';
// import api from '../api';

export const useBusinessPartners = () => useQuery({
  queryKey: ['BusinessPartnerBusinessPartners'],

  queryFn: async () => {
    const bp = await api.businessPartnersSearch({
      limit: 9000,
    });
    return bp.list;
  },
});
