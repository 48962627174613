import { MicrosoftAspNetCoreMvcProblemDetails, ResponseInfo } from 'api';
import axios from 'axios';
import messages from 'i18n/en';
import { HttpError } from 'react-admin';
import { v4 as uuidv4 } from 'uuid';

const baseUrl = import.meta.env.VITE_BASE_URL;
const apiKey = `${import.meta.env.VITE_API_KEY}`;
const timeout = parseInt(import.meta.env.VITE_API_TIMEOUT ?? '600', 10) * 1000;

const repository = axios.create({
  baseURL: baseUrl,
  timeout,
  headers: {
    'x-apikey': apiKey,
  },
  paramsSerializer: {
    indexes: null, // format arrays with no brackets
  },
});

const getCookieValue = (name: string) => (
  document.cookie.match(`(^|;)\\s*${name}\\s*=\\s*([^;]+)`)?.pop() || ''
);

repository.interceptors.request.use(
  (config: any) => {
    config.headers['x-requestid'] = uuidv4();
    const xsrf = getCookieValue('X-CSRF-TOKEN');
    config.headers['X-CSRF-TOKEN'] = xsrf;
    const locale = localStorage.getItem('locale');
    if (locale) {
      config.headers['x-lang'] = locale;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

function processError(errorData: ResponseInfo) {
  const convert = (pwd: keyof (typeof messages.mai.msg)) => `mai.msg.${String(pwd)}`;

  // const content: Path<typeof messages> = `mai.msg.${errorData.msgNo || 'server_error'}`;
  let content = errorData.msgNo ? convert(errorData.msgNo) : 'mai.msg.SERVER_ERROR';

  const mvcError = errorData as MicrosoftAspNetCoreMvcProblemDetails;
  if (mvcError.errors) {
    content = 'Validation Error:\n';
    Object.entries(mvcError.errors).forEach((name) => {
      content += '\n';
      content += `${name[0]}: ${name[1]}`;
    });
  }

  document.dispatchEvent(new CustomEvent('ApiError', {
    detail: {
      content,
      type: 'error',
    },
  }));

  /*
  let description = null;
  if (errorData.data) {
    description = Object.entries(errorData.data)
      .map((entry) => `${entry[0]}: ${entry[1]}`)
      .join('\n');
  }
  */

  throw new HttpError(
    content,
    errorData.code,
  );

  // console.log(errorData, content);
  return Promise.reject(new Error(content));
}

repository.interceptors.response.use(
  (response) => {
    const { data } = response;
    if (data.code && data.code !== 200 && data.code !== 201) {
      return processError(data);
    }
    return response;
  },
  (error) => {
    const { response } = error;
    if (response.status === 401) {
      // unauthorized => (re-)login
      localStorage.removeItem('token');
      localStorage.removeItem('timeout');
      localStorage.removeItem('refresh_token');
      window.location.href = '/login';
      return Promise.resolve();
    }
    /*
    if (response.status === 403) {
      window.location.href = '/no-access';
      return Promise.resolve();
    }
    */
    if (response.status === 400) {
      return processError(response.data);
    }
    return processError(response.data);
    /*
    return Promise.reject(new HttpError(
      `mai.msg.${error.response.data.msgNo}`),
      response.status,
      response.data

    ); */
  },
);

export default repository;
