import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import { StockInventoryControlDetail, StockInventoryControlLineCreate } from 'api';
import StockLineChooser from 'components/chooser/stock/stockLine';
import QuantityInput from 'components/input/quantity/quantityInput';
import React from 'react';
import {
  ReferenceInput,
  SelectInput,
  TextInput,
} from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { useStockData } from 'resources/stock/stockMove/lines/popup/popupAddMoveLine';
import { Source } from 'utils/source';
import useTranslate from 'utils/translate';
import FormState from '../form/state';
import styles from './popup.module.scss';

interface InventoryControlPopupProps {
  inventoryControl: StockInventoryControlDetail,
  onSubmit: (data: StockInventoryControlLineCreate) => Promise<void>,
}

export default function InventoryControlPopup(props: InventoryControlPopupProps) {
  const { inventoryControl, onSubmit } = props;
  const translate = useTranslate();
  const sline = Source<StockInventoryControlLineCreate>();
  const form = useFormContext<FormState>();
  const stockLine = useStockData(form, onSubmit);

  const onClose = () => {
    form.reset();
  };

  return (
    <>
      {
        !inventoryControl.finishedDate && (
          <StockLineChooser
            stockLocation={inventoryControl.stockLocationId}
            isFullWidth
            onChange={(value: number) => form.setValue('stockLineId', value)}
          />
        )
      }
      <Dialog
        disablePortal
        disableRestoreFocus
        open={stockLine !== null}
        onClose={onClose}
        fullWidth
        className={styles.popup}
      >
        <DialogTitle className="header">
          <Typography typography="h5">
            {`${!stockLine?.articleNo
              ? '' : `${stockLine?.articleNo}${' - '}`}${stockLine?.resourceName ?? ''}`}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Grid container spacing={2} rowSpacing={0.5}>
          <Grid item xs={1} />
          <Grid item xs={4}>
            <QuantityInput
              {...sline('amount')}
              required
              quantityType={stockLine?.quantityTypeId}
              autoFocus
            />
          </Grid>
          <Grid item xs={2} />
          <Grid item xs={4}>
            <div>
              {`${translate('mai.inventoryControl.quantityInput')}: ${stockLine?.amount ?? ''}`}
            </div>
          </Grid>
          <Grid item xs={1} />

          <Grid item xs={1} />
          <Grid item xs={4} md={4}>
            <ReferenceInput
              label="Shelf"
              {...sline('shelfId')}
              reference="stock/stocklocations/shelf"
            >
              <SelectInput
                optionText="name"
                fullWidth
                sx={{ marginTop: '0px' }}
              />
            </ReferenceInput>
          </Grid>
          <Grid item xs={5}>
            <TextInput
              {...sline('comment')}
              fullWidth
              variant="outlined"
              helperText={false}
            />
          </Grid>
          <Grid item xs={1} />
        </Grid>
        <DialogContent>
          <div style={{
            display: 'flex', justifyContent: 'flex-end', padding: '0px 30px', gap: '20px', width: '100%',
          }}
          >
            <Button
              size="small"
              type="submit"
            >
              {translate('ra.action.save')}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}
