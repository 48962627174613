import clsx from 'clsx';
import React from 'react';
import styles from './colorBadge.module.scss';

interface BadgesProps {
  children: React.ReactNode;
  inline?: boolean;
  className?: string;
}

interface StatusBadgeProps {
  green: boolean;
  orange?: boolean;
  hidden?: boolean;
  fillratio?: number;
  name?: string;
  children?: React.ReactNode;
}

export function Badges(props: BadgesProps): JSX.Element {
  const {
    children,
    inline,
    className,
  } = props;

  return (
    <div className={clsx(styles.badges, inline && 'inline', className)}>
      <div>
        {children}
      </div>
    </div>
  );
}

export function StatusBadge(props: StatusBadgeProps): JSX.Element {
  const {
    green,
    orange,
    hidden,
    children,
    name,
    fillratio,
  } = props;

  let bgClass = styles.badge;
  if (green) {
    bgClass = ` ${styles.green}`;
  } else if (orange) {
    bgClass = ` ${styles.orange}`;
  } else {
    bgClass = ` ${styles.red}`;
  }

  const style = fillratio === undefined ? undefined : {
    width: `${Math.max(0, Math.min(fillratio, 100))}%`,
  };

  return (
    <div className={clsx(styles.badge, hidden && styles.hidden)} aria-label={name || ''}>
      <div className={clsx('bg', bgClass)} style={style} />
      <div className="content">{children}</div>
    </div>
  );
}
