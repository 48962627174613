import { useQuery } from '@tanstack/react-query';
import api from 'repositories/api';
import { getApiQuery, getInfiniteApiQuery } from 'repositories/base';

// api.commonGetUserOptions()
export const useGroupsQuery = () => useQuery({
  queryKey: ['Groups'],
  queryFn: () => ({ groups: [], genders: [] }),
  staleTime: 5 * 60 * 1000,
});
export const useInfiniteUsersQuery = getInfiniteApiQuery(api.usersSearch);
export const useUser = getApiQuery(api.usersGetDetail);
