import * as React from 'react';
import {
  TextInput,
  required,
  minLength,
  number,
  regex,
  useI18nProvider,
  AutocompleteInput,
} from 'react-admin';
import { Grid } from '@mui/material';
import { Source } from 'utils/source';
import { BusinessPartnerSchema } from 'api/models/BusinessPartnerSchema';
import EnumSelectField from 'components/input/enumSelectField';
import { deliveryAddresses } from 'resources/purchasing/purchaseOrder/enums';
import { useCountries } from 'repositories/app';

export default function () {
  const i18n = useI18nProvider();
  const { data: countries } = useCountries();

  if (!countries) return null;
  const s = Source<BusinessPartnerSchema>();

  return (
    (<Grid container spacing={2} rowSpacing={2}>
      <Grid item xs={2} md={1} />
      <Grid item xs={4} md={5}>
        <TextInput
          {...s('name')}
          validate={[required()]}
          fullWidth
          variant="outlined"
          helperText={false}
        />
      </Grid>
      <Grid item xs={4} md={5}>
        <TextInput
          {...s('phone')}
          validate={[
            required(),
            regex(
              /^([+]?)([0-9\s]{8,18})$/,
              i18n.translate('mai.validation.phone'),
            ),
          ]}
          fullWidth
          variant="outlined"
          helperText={false}
        />
      </Grid>
      <Grid item xs={2} md={1} />
      <Grid item xs={2} md={1} />
      <Grid item xs={4} md={5}>
        <TextInput
          {...s('taxId')}
          fullWidth
          inputProps={{ maxLength: 50 }}
          variant="outlined"
          helperText={false}
        />
      </Grid>
      <Grid item xs={4} md={5}>
        <EnumSelectField
          {...s('defaultDeliveryAddress')}
          choices={deliveryAddresses}
          fullWidth
          helperText={false}
        />
      </Grid>
      <Grid item xs={2} md={1} />
      <Grid item xs={2} md={1} />
      <Grid item xs={4} md={5}>
        <TextInput
          {...s('address.name')}
          validate={[required()]}
          fullWidth
          inputProps={{ maxLength: 100 }}
          variant="outlined"
          helperText={false}
        />
      </Grid>
      <Grid item xs={4} md={5}>
        <TextInput
          {...s('address.address1')}
          validate={[required()]}
          fullWidth
          inputProps={{ maxLength: 100 }}
          variant="outlined"
          helperText={false}
        />
      </Grid>
      <Grid item xs={2} md={1} />
      <Grid item xs={2} md={1} />
      <Grid item xs={4} md={5}>
        <TextInput
          {...s('address.address2')}
          fullWidth
          inputProps={{ maxLength: 100 }}
          variant="outlined"
          helperText={false}
        />
      </Grid>
      <Grid item xs={2} md={2}>
        <TextInput
          {...s('address.zipcode')}
          validate={[required(), number(), minLength(4)]}
          fullWidth
          inputProps={{ maxLength: 15 }}
          variant="outlined"
          helperText={false}
        />
      </Grid>
      <Grid item xs={2} md={3}>
        <TextInput
          {...s('address.city')}
          fullWidth
          inputProps={{ maxLength: 50 }}
          variant="outlined"
          required
          helperText={false}
        />
      </Grid>
      <Grid item xs={0} md={1} />
      <Grid item xs={0} md={1} />
      <Grid item xs={2} md={2}>
        <TextInput
          {...s('address.state')}
          fullWidth
          inputProps={{ maxLength: 100 }}
          variant="outlined"
          helperText={false}
        />
      </Grid>
      <Grid item xs={4} md={4}>
        <AutocompleteInput
          {...s('address.countryId')}
          choices={countries ?? []}
          emptyValue="0"
          size="small"
          validate={[required()]}
          fullWidth
          variant="outlined"
          helperText={false}
        />
      </Grid>
      <Grid item xs={2} md={1} />
    </Grid>)
  );
}
