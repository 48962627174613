import EditActions from 'components/layout/actions/edit';
import * as React from 'react';
import {
  Edit,
} from 'react-admin';
import ProductForm from './form';

export default function ProductEdit() {
  return (
    <Edit
      actions={<EditActions />}
      mutationMode="pessimistic"
      className="mai-form"
    >
      <ProductForm />
    </Edit>
  );
}
